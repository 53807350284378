import {
  ApartmentOutlined,
  ArrowRightOutlined,
  CalculatorOutlined,
  ContainerOutlined,
  DownOutlined,
  EditOutlined,
  HomeOutlined,
  LaptopOutlined,
  LogoutOutlined,
  MessageOutlined,
  NumberOutlined,
  TableOutlined,
  TeamOutlined,
  UpCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, Space, Tag, Typography } from "antd";
import { getAuth, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { LuLeaf as FaLeaf, LuFactory } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { catcher } from "../../firebase/util";
import { designations, firstCompany, getCurrentUserDesignation, getDesignation } from "../../helper/attribute";
import { selectUserDoc } from "../../redux/features/userSlice";
import { routePath } from "../../static/routes.static";
import UserAvatar from "../User/avatar";

import { BarChartOutlined } from "@ant-design/icons";
import { T1 } from "../../appconfig/texts";
import { getCurrentPlan, isSustraxAnnual, isSustraxLite, planValue } from "../../helper/plans";
import useSize from "../../hooks/useSize";
import { inputAllowed, inputsDoneSentence } from "../../pages/form/helper/getter";
import { getAnalysisRouteLabels } from "../../static/analysis.routes.static";
import { getForms } from "../../static/formRoutes";
import store from "../../redux/store";
import { TbBuildingFactory2, TbMasksTheater } from "react-icons/tb";
import { isOfflineUser } from "../../offline";
import { FaTasks } from "react-icons/fa";
export const logout = () => {
  catcher(
    async () => {
      await signOut(getAuth());
      window.location.reload();
    },
    {
      setLoading: null,
      success_msg: "Logged out",
    }
  );
};

function getDisplayPropertyValue(isMobile, visible) {
  if (isMobile) {
    return visible ? "block" : "none";
  } else {
    return "block";
  }
}

const { Sider } = Layout;
const Index = ({ visible, colorBgContainer, setVisible }) => {
  const { isMobile } = useSize();
  const sidebarStyles = {
    background: colorBgContainer,
    overflow: "auto",
    overflowY: "scroll",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    display: getDisplayPropertyValue(isMobile, visible),
    fontWeight: 500,
    zIndex: 999,
  };
  const dataForms = getForms();
  const [selectedKey, setSelectedKey] = useState(routePath.Dashboard);
  const bugs = useSelector((state) => state.superAdminData.reportedBugs?.filter((bug) => bug.status === "new" || !bug.status));
  let location = useLocation();
  useEffect(() => {
    let key = location.pathname + location.search;
    key = key.replaceAll("%20", " ");
    // if (key.includes(routePath.Form(""))) key = routePath.Form("cars");
    setSelectedKey(key);
  }, [location]);
  const userDoc = useSelector(selectUserDoc);
  console.log(getCurrentUserDesignation(), designations.affiliate);
  const analysisTags = {
    key: `/analysis`,
    className: "analysis-sidebar-option",
    planFilter: "canViewAnalysis",
    icon: <BarChartOutlined />,
    label: <T1 pos={"analysis.title"} />,
    children: getAnalysisRouteLabels().map((tag) => {
      const path = routePath.Analysis(tag);
      return {
        onClick: () => {
          nav(path);
        },
        key: path,
        label: `${tag}`,
      };
    }),
  };
  //plan filters
  if (getCurrentPlan() === "free") {
    analysisTags.onClick = () => {
      nav(routePath.Analysis("Years"));
    };
    delete analysisTags.children;
    analysisTags.key = routePath.Analysis("Years");
  } else if (getCurrentPlan() === "offline") {
    analysisTags.onClick = () => {
      nav(routePath.Analysis("Years"));
    };
    delete analysisTags.children;
    analysisTags.key = routePath.Analysis("Years");
    analysisTags.label = "Results";
  }
  const items2 = [
    {
      key: `/results`,
      planFilter: "canViewResults",
      icon: <NumberOutlined />,
      label: <T1 pos={"results.title"} />,
      children: dataForms.map((_) => {
        const path = routePath.Result(_.name);
        return {
          onClick: () => {
            nav(path);
          },
          key: path,
          label: `${_.title}`,
          icon: <img src={_.bg} style={{ width: 25 }}></img>,
        };
      }),
    },
    {
      key: routePath.Form(""),
      planFilter: "sideBarDetailedInputs",
      icon: <EditOutlined />,
      label: "Input",
      className: "input-sidebar-option",
      children: dataForms.map((_) => {
        const path = routePath.Form(_.name);
        return {
          onClick: () => {
            nav(path);
          },
          key: path,
          label: ["free", "basic", "premium", "demo", "Supplier", "offline"].includes(getCurrentPlan()) ? (
            <div>
              <div style={{ height: "14px" }}>{_.title}</div>
              <span style={{ fontSize: "9px" }}>{inputsDoneSentence(_)}</span>
            </div>
          ) : (
            _.title
          ),
          icon: <ArrowRightOutlined />,
          icon: <img src={_.bg} style={{ width: 25 }}></img>,
          className: inputAllowed(_) ? "" : "!disabled-div",
        };
      }),
    },
    analysisTags,
    {
      key: routePath.combined_data_table,
      planFilter: "canViewCombinedResults",
      icon: <TableOutlined />,
      label: planValue("onlyDisplayInputsInCombinedResults") ? "Data" : "Combined Results",
      className: "combined-sidebar-option",
      onClick: () => nav(routePath.combined_data_table),
    },
    {
      key: routePath.FAQ,
      icon: <MessageOutlined />,
      label: <T1 pos={"faq.title"} />,
      className: "faq-sidebar-option",
      onClick: () => nav(routePath.FAQ),
    },
    {
      key: routePath.Offset,
      icon: <FaLeaf />,
      planFilter: "canViewOffsetPage",
      label: "Offset",
      display: !store.getState().app.affiliate,
      onClick: () => nav(routePath.Offset),
    },
    {
      key: "business_calculator_outside_link",
      icon: <CalculatorOutlined />,
      label: "Business Calculator",
      display: isOfflineUser(),
      onClick: () => window.open("https://www.carbonfootprint.com/small_business_calculator.html", "_blank"),
    },
    {
      key: routePath.Upgrade,
      icon: <UpCircleOutlined />,
      planFilter: "canViewUpgradePage",
      label: <T1 pos={"plans.upgradePageTitle"} />,
      className: "upgrade-sidebar-option",
      onClick: () => nav(routePath.Upgrade),
    },
  ];

  const userdoc = useSelector(selectUserDoc);
  const navigate = useNavigate();
  const isSuperAdminNotification = bugs?.length;
  let plainItems = [
    {
      key: routePath.SuperAdminSection,
      icon: <LaptopOutlined />,
      label: <div style={{ display: "flex", alignItems: "center", gap: 3 }}>Super Admin {isSuperAdminNotification ? <Tag color={"red"}>{bugs?.length}</Tag> : null}</div>,
      onClick: () => nav(routePath.SuperAdminSection),
      filter: [designations.super_admin],
    },
    {
      key: routePath.adminConn(),
      icon: <LaptopOutlined />,
      label: <div style={{ display: "flex", alignItems: "center", gap: 3 }}>Admin Section </div>,
      onClick: () => nav(routePath.adminConn()),
      filter: [designations.affiliate],
    },
    {
      key: routePath.Dashboard,
      icon: <HomeOutlined />,
      label: <T1 pos={"dashboard.title"} />,
      onClick: () => nav(routePath.Dashboard),
    },
    {
      key: routePath.Companies,
      planFilter: "canViewCompanies",
      icon: <TbBuildingFactory2 />,
      label: <T1 pos={"companies.title"} />,
      onClick: () => nav(routePath.Companies),
      filter: [
        designations.super_admin,
        designations.company_admin,
        designations.group_admin,
        designations.affiliate,
        // designations.consultant,
      ],
    },
    {
      key: routePath.Users,
      icon: <TeamOutlined />,
      planFilter: "canViewUsers",
      label: <T1 pos={"users.title"} />,
      onClick: () => nav(routePath.Users),
      filter: [designations.super_admin, designations.company_admin, designations.site_admin, designations.group_admin, designations.consultant, designations.affiliate],
    },
    {
      key: routePath.Tasks,
      icon: <FaTasks />,
      label: "Tasks",
      filter: [],
      onClick: () => nav(routePath.Tasks),
    },
    {
      key: routePath.Sites,
      icon: <ApartmentOutlined />,
      label: <T1 pos={"sites.title"} />,
      onClick: () => nav(routePath.Sites),
      planFilter: "canViewSites",
      filter: [
        designations.super_admin,
        designations.company_admin,
        designations.site_admin,
        designations.group_admin,
        designations.affiliate,
        // designations.consultant,
      ],
    },
    {
      key: routePath.Form(""),
      display: !planValue("sideBarDetailedInputs"), // if detailed shown dont show this
      icon: <EditOutlined />,
      label: <T1 pos={"form.title"} />,
      onClick: () => nav(routePath.Form("")),
    },
  ];
  const designation = getDesignation(userDoc);
  plainItems = plainItems.filter((item) => {
    if (!item.filter) return true;
    return item.filter.includes(designation);
  });
  const profileItems = [
    {
      key: "/profile",
      label: (
        <Space>
          <UserOutlined />
          <T1 pos={"profile.title"} />
        </Space>
      ),
      onClick: () => nav("/profile"),
    },
    {
      key: "logout",
      label: (
        <Space>
          <LogoutOutlined />
          <T1 pos={"profile.logout"} />
        </Space>
      ),
      onClick: () => logout(),
    },
  ];

  //  defaultOpenKeys={["sub1"]}
  let routes = [...plainItems, ...items2].filter((route) => {
    if (route.hasOwnProperty("display") && route.hasOwnProperty("planFilter")) {
      return route.display && planValue(route.planFilter);
    }
    if (route.hasOwnProperty("display")) {
      return route.display;
    }
    if (route.planFilter) {
      return planValue(route.planFilter);
    }
    return true;
  });

  console.log(routes);

  const nav = (path) => {
    console.log("navigating to ", path);
    setVisible(false);
    navigate(path);
  };

  return (
    <Sider style={sidebarStyles} width={isMobile ? "90vw" : 250}>
      <div>
        {getCurrentPlan() === "offline" ? (
          <div style={{ padding: "0px 20px" }}>
            <Typography.Title level={4}>🌿 Sustrax Vita</Typography.Title>
            <Typography.Text type="secondary">Calculate your emissions with Sustrax Vita.</Typography.Text>
          </div>
        ) : (
          <Dropdown menu={{ items: profileItems, selectable: true }} trigger={["click"]}>
            <Typography.Link>
              <div className="profile-siderbar-option">
                <div className="sidebar-profilecontent">
                  <UserAvatar _={userdoc} />
                  <div>
                    <p className="sidebar-profiletext title notranslate" title={userdoc?.firstName + " " + userdoc?.lastName}>
                      <Typography.Text style={{ maxWidth: "150px" }} ellipsis>
                        {userdoc?.firstName + " " + userdoc?.lastName}
                      </Typography.Text>
                    </p>
                    <p className="sidebar-profiletext designation notranslate">
                      {isSustraxLite() ? <Typography.Text>{firstCompany()?.title}</Typography.Text> : <Typography.Text>{getDesignation(userdoc)}</Typography.Text>}
                    </p>
                  </div>
                </div>
                <Typography.Text>
                  <DownOutlined />
                </Typography.Text>
              </div>
            </Typography.Link>
          </Dropdown>
        )}
      </div>
      <Menu
        mode="inline"
        defaultOpenKeys={["/results", "/analysis", routePath.Form("")]}
        // style={{ height: "100%" }}
        selectedKeys={[selectedKey]}
        items={routes}
      />
    </Sider>
  );
};

export default Index;
