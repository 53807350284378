import { collection, getDocs, query, where } from "firebase/firestore";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";
import { firstCompany } from "../../helper/attribute";


export const fetchTasks = async () => {
  const companies = firstCompany();
  const collectionRef = collection(db, firestoreStatic.tasks);
  console.log("companies", companies)
  let tasks = [];

  const q = query(
    collectionRef,
    where(
      "companyId",
      "==",
      companies.id
    )
  );

  const docs = await getDocs(q);
  const batchTasks = docsWithIds(docs);
  tasks.push(...batchTasks);
  console.log(tasks)
  const sortedTasks = tasks.sort((a, b) => {
    return a.checked?.at === b.checked?.at ? 0 : a.checked?.at ? 1 : -1;
  }); 
  return sortedTasks;
}