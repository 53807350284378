const upto = (number, what) => {
  if (!Number(number)) return number;
  if (number === 0) return 0;
  return "Up to " + number + " " + what;
};
export const plansConfig = {
  plans: [
    { key: "free", price: "FREE" },
    { key: "basic", price: "£295 (ex VAT)" },
    { key: "premium", price: "£995 (ex VAT)" },
    { key: "pro", price: "On Request" },
  ],
  items: [
    {
      title: "Results total",
      status: [{ free: 1 }, { basic: 1 }, { premium: 1 }, { pro: 1 }],
    },
    {
      title: "PDF Report",
      status: [
        { free: 1 },
        { basic: 1 },
        { premium: 1 },
        { pro: "Consultant-led Verification" },
      ],
    },
    {
      title: "Tracking type",
      status: [
        { free: "Annual" },
        { basic: "Annual" },
        { premium: "Annual" },
        { pro: "Monthly" },
      ],
    },
    {
      title: "Number of sites",
      status: [
        { free: "1" },
        { basic: "1" },
        { premium: "5" },
        { pro: "No limit" },
      ],
      statusFunc: (key, val) => (key === "premium" ? val : val),
    },
    {
      title: "Flights",
      status: [
        { free: "5" },
        { basic: "50" },
        { premium: "100" },
        { pro: "No limit - See inputs below" },
      ],
      statusFunc: (key, val) => upto(val, "routes"),
    },
    {
      title: "Vehicles",
      status: [
        { free: "5" },
        { basic: "25" },
        { premium: 50 },
        { pro: "No limit - See inputs below" },
      ],
      statusFunc: (key, val) => upto(val, "vehicles"),
    },
    {
      title: "Fuels",
      status: [{ free: 6 }, { basic: 20 }, { premium: 50 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Public Transport",
      status: [{ free: 4 }, { basic: 10 }, { premium: 50 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Home Workers",
      status: [{ free: 2 }, { basic: 2 }, { premium: 10 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    
    {
      title: "Freight",
      status: [{ free: 0 }, { basic: 0 }, { premium: 10 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Water",
      status: [{ free: 0 }, { basic: 0 }, { premium: 10 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Waste",
      status: [{ free: 0 }, { basic: 0 }, { premium: 10 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Commuting",
      status: [{ free: 0 }, { basic: 0 }, { premium: 40 }, { pro: "No limit - See inputs below" }],
      statusFunc: (key, val) => upto(val, "records"),
    },
    {
      title: "Custom Data Tags",
      status: [{ free: 0 }, { basic: 0 }, { premium: 1 }, { pro: 1 }],
    },
    {
      title: "MS Excel Data Export",
      status: [{ free: 0 }, { basic: 1 }, { premium: 1 }, { pro: 1 }],
    },
    {
      title: "AI Recommendations",
      status: [
        { free: 5 },
        { basic: 50 },
        { premium: 100 },
        { pro: "Unlimited" },
      ],
      statusFunc: (key, val) => (typeof val === "number" ? val + " uses" : val),
    },
    {
      title: "Site level reporting",
      status: [{ free: 0 }, { basic: 0 }, { premium: 0 }, { pro: 1 }],
    },
    {
      title: "Multiple Users",
      status: [{ free: 0 }, { basic: 0 }, { premium: 0 }, { pro: 1 }],
    },
    {
      title: "Bulk data uploads",
      status: [{ free: 0 }, { basic: 0 }, { premium: 0 }, { pro: 1 }],
    },
    {
      title: "Consultant Support",
      status: [
        { free: 0 },
        { basic: "Up to 1 hour" },
        { premium: 3 },
        { pro: "Up to 1 day" },
      ],
      statusFunc: (key, val) => upto(val, "hours"),
    },
  ],
};