import Fuse from "fuse.js";
import { capitalizeFirstLetter } from "../../helper/wodash";
import { findForm } from "../../static/formRoutes";

export async function returnExcelOptions(form, configData) {
  let formInputs = [];
  const data = { ...form, ...configData } || {};

  try {
    const module = await import("../form/dataforms/" + form.name);
    formInputs = module.INPUTS(data, () => {}) || [];
  } catch (err) {
    try {
      console.log("error test 123", err);
      const module = await import("../form/dataforms/" + capitalizeFirstLetter(form.name));
      formInputs = module.INPUTS(data, () => {}) || [];
    } catch (err) {
      console.error(err);
    }
  }
  return formInputs.map((input) => ({ ...input, excel_options: input.excel_options || input.options }));
}

export function getToDisplayValue(formname, fieldname, fieldvalue, data = {}) {
  if (formname === "electricity") {
    const form = findForm("electricity");
    if (fieldname === "q2") {
      const fieldOptions = form.fieldValues?.[fieldname] || [];
      const matchedOption = fieldOptions.find(opt => opt.value === fieldvalue);
      if (!matchedOption) {
        const defaultOption = fieldOptions.find(opt => opt.default === true);
        return defaultOption?.key || fieldvalue;
      }
      return matchedOption.key;
    }
  }
  return fieldvalue;
}

export function getFuzzyMatchedSiteId(searchTitle, openSites, firstSite) {
  if (!searchTitle) return firstSite().id;
  const sites = openSites().map((site) => ({
    ...site,
    titleNormalized: site.title.replace(/\s+/g, "").toLowerCase(),
  }));

  const fuse = new Fuse(sites, {
    keys: ["titleNormalized"],
    threshold: 0.1, // Approximately 90% match threshold
  });

  const normalizedSearchTitle = searchTitle.replace(/\s+/g, "").toLowerCase();
  const result = fuse.search(normalizedSearchTitle);

  return result.length ? result[0].item.id : firstSite().id;
}
