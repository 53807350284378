import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { addDoc, collection, getDoc, setDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { doc } from "firebase/firestore";
import { getCurrentTime } from "../../helper/time";
import { updateTask } from "./update";
import { fetchTasks } from "./get";

export const createComment = async (taskId, comment, task, shouldFetch = true) => {
  try {
    const commentDocRef = doc(collection(db, firestoreStatic.info), `taskcomments_${taskId}`);
    const commentDoc = await getDoc(commentDocRef);
    comment.at = getCurrentTime();

    if (commentDoc.exists()) {
      await updateDoc(commentDocRef, {
        comments: arrayUnion(comment)
      });
    } else {
      await setDoc(commentDocRef, {
        comments: [comment]
      });
    }

    await updateTask(taskId, { lastcomment: comment, "comment": (task.comment || 0) + 1 });

    if (shouldFetch) {
      const res = await fetchTasks();
      return res;
    }
  } catch (error) {
    throw new Error(`Failed to create comment: ${error.message}`);
  }
}

export const getComments = async (taskId) => {
  try {
    const commentDocRef = doc(collection(db, firestoreStatic.info), `taskcomments_${taskId}`);
    const commentDoc = await getDoc(commentDocRef);

    if (commentDoc.exists()) {
      const comments = commentDoc.data().comments;
      
      const sorted = comments.sort((a, b) => {
        const timeA = new Date(a.at).getTime();
        const timeB = new Date(b.at).getTime();
        return timeB - timeA;
      });
      return sorted;
    }
    return [];
  } catch (error) {
    throw new Error(`Failed to get comments: ${error.message}`);
  }
}

export const deleteCommentDoc = async (taskId) => {
  try {
    const commentDocRef = doc(collection(db, firestoreStatic.info), `taskcomments_${taskId}`);
    const commentDoc = await getDoc(commentDocRef);

    if (commentDoc.exists()) {
      await deleteDoc(commentDocRef);
    } else {
      console.log(`Document for task ID ${taskId} does not exist.`);
    }
  } catch (error) {
    throw new Error(`Failed to delete comment document: ${error.message}`);
  }
}





