import { getCurrentDate, getCurrentTime } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";
import { addDoc, collection } from "firebase/firestore";
import { fetchTasks } from "./get";
import { CreateNotification } from "../notifications/create";
import { findUserById } from "../../helper/attribute";
import { notificationMapping,redirects } from "../../static/notification.static";


export const beforeTaskCreate = (data) => {
  const dontHv = objDontHave(data, ["description"]);

  if (dontHv) throw "Please add " + dontHv;
  data.created.at = getCurrentTime();
  return data;
};

export const CreateTask = async (data,shouldFetch = true) => {
  data = beforeTaskCreate(data)


  await addDoc(collection(db, firestoreStatic.tasks), data)
  const creator = findUserById(data.created.by)
   await CreateNotification(
    `You have been assigned a new task by ${creator.firstName} ${creator.lastName}`,
    data.assignee,
    notificationMapping.task
  )
  if (shouldFetch){
    const res = await fetchTasks()
    return res
  }
};
