import React from 'react'
import CarbonSearch from './CarbonSearch'

const AI = () => {
  return (
    <div>
        <CarbonSearch/>
    </div>
  )
}

export default AI