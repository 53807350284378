import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Input, Button, Spin, AutoComplete } from "antd";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectConfigData } from "../../redux/features/configSlice";
import { useAtom } from "jotai";
import { devSidebarAtom } from "../../DevSidebar";
import { findFormByTitle } from "../../static/formRoutes";
import { parse } from "papaparse";

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
`;

const GreenSpan = styled.span`
  color: #228b22;
`;

const Subtitle = styled.h2`
  font-size: 1.25rem;
  color: ${(props) => (props.theme.mode === "dark" ? "#9CA3AF" : "#4B5563")};
  margin-bottom: 3rem;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const ChatContainer = styled.div`
  margin-top: 2.5rem;
  padding: 2rem;
  background: ${(props) => (props.theme.mode === "dark" ? "#1F2937" : "white")};
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  text-align: left;
  transition: all 0.3s ease;
`;

const MessageWrapper = styled(motion.div)`
  display: flex;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: 1.5rem;
  gap: 1rem;
`;

const Message = styled(motion.div)`
  max-width: 85%;
  padding: 1rem 1.5rem;
  border-radius: ${(props) => (props.isUser ? "1.2rem 1.2rem 0.3rem 1.2rem" : "1.2rem 1.2rem 1.2rem 0.3rem")};
  background: ${(props) => (props.isUser ? "#228B22" : props.theme.mode === "dark" ? "#374151" : "#F3F4F6")};
  color: ${(props) => (props.isUser ? "white" : props.theme.mode === "dark" ? "#F9FAFB" : "#1F2937")};
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
`;

const FullScreenChat = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => (props.theme.mode === "dark" ? "#111827" : "#FFFFFF")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatHeader = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  margin-top: 4rem;
  margin-bottom: 5rem;
  width: 100%;
  max-width: 800px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.mode === 'dark' ? '#1F2937' : '#F3F4F6'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.mode === 'dark' ? '#4B5563' : '#9CA3AF'};
    border-radius: 10px;
    
    &:hover {
      background: ${props => props.theme.mode === 'dark' ? '#6B7280' : '#6B7280'};
    }
  }
`;

const ChatInput = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: ${(props) => (props.theme.mode === "dark" ? "#1F2937" : "#FFFFFF")};
  border-top: 1px solid ${(props) => (props.theme.mode === "dark" ? "#374151" : "#E5E7EB")};
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    max-width: 800px;
  }

  .ant-input-search .ant-input {
    height: 50px;
    font-size: 1rem;
    border-radius: 12px;
    padding: 0 1.5rem;
    border: 2px solid #e5e7eb;

    &:focus {
      border-color: #228b22;
      box-shadow: 0 0 0 2px rgba(34, 139, 34, 0.1);
    }
  }

  .ant-btn {
    height: 50px;
    border-radius: 12px;
    padding: 0 1.5rem;
    font-weight: 500;
  }
`;

const ErrorMessage = styled.div`
  color: #dc2626;
  background: #fee2e2;
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  text-align: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const BackButton = styled(Button)`
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 10;
  height: 44px;
  padding: 0 1.25rem;
  border-radius: 10px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid #e5e7eb;

  &:hover {
    background: #f9fafb;
    border-color: #d1d5db;
  }
`;

export default function CarbonSearch() {
  let [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isSecondWorkflow, setIsSecondWorkflow] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const formsConfig = useSelector(selectConfigData)?.forms;
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState(null);

  const [, setDevData] = useAtom(devSidebarAtom);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    setDevData({
      formsConfig,
    });
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const messageAnimationVariants = {
    initial: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    animate: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  const parseResponse = (content) => {
    try {
      const jsonMatch = content.match(/\{.*\}/s);
      if (jsonMatch) {
        const parsed = JSON.parse(jsonMatch[0]);
        if (parsed.category) {
          return {
            isCategory: true,
            category: parsed.category,
            message: `I think you are interested in calculating ${parsed.category} emissions. Please click this button to proceed.`,
          };
        }
      }
      return { isCategory: false, message: content };
    } catch (error) {
      console.error("Parse error:", error);
      return { isCategory: false, message: content };
    }
  };

  const parseSecondWorkflowResponse = (content) => {
    try {
      // Look for field_name pattern [field_name=something]
      const fieldMatch = content.match(/\[field_name=(\w+)\]/);

      if (fieldMatch) {
        const fieldName = fieldMatch[1];

        // Find the form based on active category
        const form = findFormByTitle(activeCategory);
        if (!form || !form.inputs) {
          return { isInput: false, message: content };
        }

        // Get the input field configuration
        const inputField = form.inputs({}, () => {}).find((i) => i.key === fieldName);
        if (!inputField) {
          return { isInput: false, message: content };
        }

        // Remove the [field_name=xyz] from the message
        const messageText = content.replace(/\[field_name=\w+\]/, "").trim();
        setOptions(inputField.options);
        return {
          isInput: true,
          message: messageText,
          fieldName: fieldName,
          fieldConfig: inputField,
          options: inputField.options,
        };
      }
      return { isInput: false, message: content };
    } catch (error) {
      console.error("Parse error:", error);
      return { isInput: false, message: content };
    }
  };

  const handleCategorySelection = async () => {
    setLoading(true);
    try {
      setMessages([]);
      setIsSecondWorkflow(true);
      const lowercaseCategory = selectedCategory;
      setActiveCategory(lowercaseCategory);

      const categoryConfig = Object.values(formsConfig).find((form) => form.title === lowercaseCategory)?.texts || [];

      const response = await fetch(process.env.REACT_APP_BE + "/api/sustrAIx", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: "Let's begin",
          history: [],
          prompt: "second_prompt",
          categoryConfig,
        }),
      });

      const data = parseSecondWorkflowResponse((await response.json()).result);
      setMessages([{ role: "assistant", content: data.message }]);
      setSelectedCategory(null);
    } catch (error) {
      console.error("Error:", error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e, use_this_query) => {
    e.preventDefault();
    setDevData("_____SUBMITTED_____");
    
    if (typeof use_this_query === "string") {
      query = use_this_query;
    }
    if (!query.trim()) return;
    
    setLoading(true);
    setError(null);

    try {
      const messageHistory = messages.map((msg) => ({
        role: msg.role,
        content: msg.content,
      }));

      const response = await fetch(process.env.REACT_APP_BE + "/api/sustrAIx", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query,
          history: messageHistory,
          prompt: isSecondWorkflow ? "second_prompt" : "first_prompt",
          categoryConfig: isSecondWorkflow ? Object.values(formsConfig).find((form) => form.title === activeCategory)?.texts || [] : [],
        }),
      });

      const data = await response.json();

      if (!isSecondWorkflow) {
        const parsedResponse = parseResponse(data.result);
        if (parsedResponse.isCategory) {
          setSelectedCategory(parsedResponse.category);
        }
        setMessages((prev) => [...prev, { role: "user", content: query }, { role: "assistant", content: parsedResponse.message }]);
      } else {
        const parsedResponse = parseSecondWorkflowResponse(data.result);
        setDevData(parsedResponse);
        setMessages((prev) => [
          ...prev,
          { role: "user", content: query },
          {
            role: "assistant",
            content: data.result,
            inputField: parsedResponse.isInput
              ? {
                  name: parsedResponse.fieldName,
                  config: parsedResponse.fieldConfig,
                }
              : null,
          },
        ]);
      }

      setQuery("");
    } catch (error) {
      console.error("Error:", error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setIsSecondWorkflow(false);
    setMessages([]);
    setSelectedCategory(null);
  };

  const renderMessage = (message) => {
    if (message.inputField) {
      const { config, name } = message.inputField;
      return <>
        <div>{message.content.replace(/\[field_name=\w+\]/, "")}</div>
        {/* {config.options ? (
          <div>
            {config.options.map((option) => (
              <div key={option}>{option}    </div>
            ))}
          </div>
        ) : (
          ""
        )} */}
      </>;
    }
    return message.content;
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    // Optional: You can automatically submit after input if needed
    // handleSubmit(new Event('submit'));
  };

  if (isSecondWorkflow) {
    return (
      <FullScreenChat
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4, ease: "easeOut" }}
      >
        {loading && (
          <LoadingOverlay>
            <Spin size="large" />
          </LoadingOverlay>
        )}

        <BackButton icon={<ArrowLeftOutlined />} onClick={handleBack}>
          Back
        </BackButton>

        <ChatHeader>
          <Title>
            sustr<GreenSpan style={{ color: "forestgreen" }}>AI</GreenSpan>x
          </Title>
        </ChatHeader>

        <ChatMessages>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {messages.map((message, index) => (
            <MessageWrapper 
              key={index} 
              isUser={message.role === "user"}
              variants={messageAnimationVariants}
              initial="initial"
              animate="animate"
              layout
            >
              <Message 
                isUser={message.role === "user"}
                layout
              >
                {renderMessage(message)}
              </Message>
            </MessageWrapper>
          ))}
          <div ref={messagesEndRef} />
        </ChatMessages>

        <ChatInput>
          <form onSubmit={handleSubmit}>
            {options && Array.isArray(options) ? (
              <AutoComplete
                options={options
                  .filter(option => 
                    option.toLowerCase().includes(query.toLowerCase())
                  )
                  .map(option => ({ value: option }))
                }
                value={query}
                onChange={(value) => setQuery(value)}
                onSelect={(value) => {
                  // Simulate form submission with the selected value
                  const event = new Event('submit', { cancelable: true });
                  handleSubmit(event, value);
                }}
                style={{ width: '100%' }}
              >
                <Input.Search
                  id="second-input"
                  size="large"
                  placeholder="Type your message..."
                  enterButton={
                    <Button
                      type="primary"
                      style={{
                        background: "#228B22",
                        transition: "all 0.3s ease",
                      }}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                  }
                  disabled={loading}
                />
              </AutoComplete>
            ) : (
              <Input.Search
                id="second-input"
                size="large"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Type your message..."
                enterButton={
                  <Button
                    type="primary"
                    style={{
                      background: "#228B22",
                      transition: "all 0.3s ease",
                    }}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                }
                disabled={loading}
              />
            )}
          </form>
        </ChatInput>
      </FullScreenChat>
    );
  }

  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Title>
        sustr<GreenSpan style={{ color: "forestgreen" }}>AI</GreenSpan>x
      </Title>
      <Subtitle>Calculate accurate carbon footprint emissions</Subtitle>

      <form onSubmit={handleSubmit}>
        <Input.Search
          size="large"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Write the activity you want to calculate the carbon footprint for"
          enterButton={
            <Button type="primary" style={{ background: "#228B22" }}>
              {loading ? "Processing..." : "Calculate"}
            </Button>
          }
          loading={loading}
        />
      </form>

      {messages.length > 0 && (
        <ChatContainer
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {messages.map((message, index) => (
            <MessageWrapper 
              key={index} 
              isUser={message.role === "user"}
              variants={messageAnimationVariants}
              initial="initial"
              animate="animate"
              layout
            >
              <Message 
                isUser={message.role === "user"}
                layout
              >
                {renderMessage(message)}
              </Message>
            </MessageWrapper>
          ))}
          <div ref={messagesEndRef} />
          {selectedCategory && (
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button
                type="primary"
                size="large"
                style={{
                  background: "#228B22",
                  height: "48px",
                  padding: "0 2rem",
                  fontSize: "1rem",
                  borderRadius: "12px",
                }}
                onClick={handleCategorySelection}
                loading={loading}
              >
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <span>
                    Continue with {selectedCategory} <RightOutlined />
                  </span>
                )}
              </Button>
            </div>
          )}
        </ChatContainer>
      )}
    </Container>
  );
}
