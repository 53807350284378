import { CheckSquareOutlined, CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';

export const notificationMapping = {
   task: "task",
   comment: "comment"
}

export const notificationRedirects = {
   task: "/tasks",
   comment: "/tasks"
}

export const notificationTitles = {
   task: "New Task",
   comment: "New Comment"
}

export const notificationIcons = {
   task: <CheckSquareOutlined />,    // Ant Design icon component for tasks
   comment: <CommentOutlined />,     // Ant Design icon component for comments
   default: <QuestionCircleOutlined /> // Default icon for unmapped notification types
}