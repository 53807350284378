import { message } from "antd";
import axios from "axios";
import validator from "validator";
import { removePropsFromObject } from "../../helper/obj";
import { getCurrentDate } from "../../helper/time";
import { objDontHave } from "../../helper/wodash";
import { setUsers } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { adminApi } from "../../static/adminApi";
import { createCompany } from "../company/create";
import { deleteCompany } from "../company/delete";
import { createSite } from "../site/create";
import { deleteSite } from "../site/delete";
import { fetchUsers } from "./get";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";
import { getAuth } from "firebase/auth";
//validation

export const beforeUserCreate = (data) => {
  const dontHv = objDontHave(data, ["firstName", "lastName", "email", "password", "companyId"]);

  if (dontHv) throw "Please add " + dontHv;
  if (!validator.isEmail(data.email)) throw "Not a valid Email";
  // if (data.phone && !validator.isMobilePhone(data.phone)) {
  //   throw "Not a valid phone number";
  // }
  if (!validator.isLength(data.password, { min: 6 })) throw "Password min length is 6";
  data.createdAt = getCurrentDate();
  data.admin_of_sites = [];
  data.admin_of_companies = [];
  return data;
};

export const createUser = async (data, admin_of_companies, shouldFetch = true, ONLY_CREATE_USER_DOC_IN_FRONTEND = false) => {
  removePropsFromObject(data);
  data = beforeUserCreate(data);

  if (Array.isArray(admin_of_companies)) data.admin_of_companies = admin_of_companies;

  if (ONLY_CREATE_USER_DOC_IN_FRONTEND === "YES") {
    delete data.password;
    await setDoc(doc(db, firestoreStatic.users, getAuth().currentUser.uid), data);
    return;
  }

  const { id } = (await axios.post(adminApi.createUser, data)).data;
  if (shouldFetch) store.dispatch(setUsers([{ ...data, id }, ...store.getState().app.users]));
  if (shouldFetch) fetchUsers(true); // quick fix because new user was uneditable
  return { ...data, id };
};

export const signInNewUser = async (userData, companyName, country, af_id, isSSO = false) => {
  // signs up a new user for trial mode
  let companyId, siteId, userId;
  const data = { title: companyName, plan: "free" };
  if (af_id) {
    data.af_id = af_id;
  }
  try {
    companyId = await createCompany(data, false);
    siteId = await createSite(
      {
        title: "Default Site",
        companyId,
        country: country,
      },
      false
    );
    if (!isSSO) {
      userId = await createUser({ ...userData, companyId }, [companyId], false);
    } else {
      userId = await createUser({ ...userData, companyId }, [companyId], false, "YES");
    }
    return userId;
  } catch (err) {
    // delete the created entities
    try {
      companyId && (await deleteCompany(companyId, true));
      siteId && (await deleteSite(siteId, true));
    } catch (err) {
      message.error(err?.message);
    }
    throw err;
  }
};
