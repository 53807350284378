import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";
import { fetchTasks } from "./get";
import { deleteCommentDoc } from "./comment";

export const deleteTask = async (id, shouldFetch = true) => {
  try {
    await deleteCommentDoc(id);
    await deleteDoc(doc(db, firestoreStatic.tasks, id));  
    if (shouldFetch) {
      const res = await fetchTasks();
      console.log(res)
      return res;
    }
  } catch (error) {
    console.error("Failed to delete task:", error);
    throw error;
  }
};
