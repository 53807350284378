import React from "react";
import { Tabs, Table, Dropdown, Modal } from "antd";
import { FunctionOutlined, SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectResults } from "../../../redux/features/appSlice";
import { compareDates } from "../../../helper/time";
import { fixDecimals } from "../../../helper/wodash";
import { deleteResult, bulkDeleteResults } from "../../../firebase/data/delete";

const Results = () => {
  const results = useSelector(selectResults);

  const handleDeleteResult = (record) => {
    Modal.confirm({
      title: 'Delete Result',
      content: `Are you sure you want to delete this result?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteResult(record.id);
        } catch (error) {
          Modal.error({
            title: 'Error',
            content: 'Failed to delete result. Please try again.'
          });
        }
      }
    });
  };

  const handleDeleteBulkResults = (bulkId) => {
    Modal.confirm({
      title: 'Delete Bulk Results',
      content: `Are you sure you want to delete all results with Bulk ID: ${bulkId}? Please confirm the file with the same id first in user bulk uploads.`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          // Get all result IDs with matching bulk_id
          const idsToDelete = results
            .filter(result => result.bulk_id === bulkId)
            .map(result => result.id);
          
          await bulkDeleteResults(idsToDelete);
        } catch (error) {
          Modal.error({
            title: 'Error',
            content: 'Failed to delete bulk results. Please try again.'
          });
        }
      }
    });
  };

  // Define columns for the table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => compareDates(a.createdAt, b.createdAt),
      defaultSortOrder: "descend",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name || "-",
      filters: results
        ?.map(item => item.name)
        .filter((name, index, self) => name && self.indexOf(name) === index)
        .map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.name === value,
    },
    {
      title: "Bulk Upload",
      dataIndex: "bulk_uploaded",
      key: "bulk_uploaded",
      render: (bulk_uploaded) => (bulk_uploaded ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.bulk_uploaded === value,
    },
    {
      title: "Bulk ID",
      dataIndex: "bulk_id",
      key: "bulk_id",
      render: (bulk_id) => bulk_id || "-",
      filters: results
        ?.map(item => item.bulk_id)
        .filter((id, index, self) => id && self.indexOf(id) === index)
        .map(id => ({ text: id, value: id })),
      onFilter: (value, record) => record.bulk_id === value,
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (result) => {
        return fixDecimals(result);
      },
      sorter: (a, b) => a.result - b.result,
    },
    {
      title: "Settings",
      key: "settings",
      width: 80,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: 'Delete Result',
                onClick: () => handleDeleteResult(record)
              },
              ...(record.bulk_id ? [{
                key: '2',
                label: 'Delete All Bulk Results',
                onClick: () => handleDeleteBulkResults(record.bulk_id)
              }] : [])
            ]
          }}
        >
          <SettingOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    }
  ];

  return (
    <div>
      <Table
        dataSource={results}
        columns={columns}
        rowKey={(record) => record.id || Math.random()}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </div>
  );
};

const Functions = () => {
  return (
    <div>
      <h2>
        <FunctionOutlined /> Functions
      </h2>
      <p>Here is a list of user functions.</p>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Results",
            children: <Results />,
          },
        ]}
      />
    </div>
  );
};

export default Functions;
