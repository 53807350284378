import { message, Tooltip, Divider } from "antd";
import { getAuth, OAuthProvider, signInWithPopup, fetchSignInMethodsForEmail, EmailAuthProvider, linkWithCredential, signInWithCredential, GoogleAuthProvider } from "firebase/auth";
import { FaMicrosoft, FaGoogle } from "react-icons/fa";
import styled from "styled-components";

const auth = getAuth();

const SSOButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    font-size: 32px;
    &.microsoft {
      color: #00a4ef;
    }
    &.google {
      color: #4285f4;
    }
  }
`;

const SSOWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const SSOButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const HeaderText = styled.span`
  font-size: 16px;
  color: #666;
`;

export const SSO = () => {
  return (
    <SSOWrapper>
      <SSOButtonsContainer>
        <Tooltip title="Sign in with Microsoft">
          <SSOButton onClick={signInWithMicrosoft}>
            <FaMicrosoft className="microsoft" size={32} />
          </SSOButton>
        </Tooltip>
        <Tooltip title="Sign in with Google">
          <SSOButton onClick={signInWithGoogle}>
            <FaGoogle className="google" size={32} />
          </SSOButton>
        </Tooltip>
      </SSOButtonsContainer>
    </SSOWrapper>
  );
};

export const signInWithMicrosoft = async () => {
  try {
    const microsoftProvider = new OAuthProvider("microsoft.com");

    // Sign in with Microsoft popup
    const result = await signInWithPopup(auth, microsoftProvider);
    const user = result.user;

    console.log("Signed-in user:", user);
  } catch (error) {
    if (error.code === "auth/account-exists-with-different-credential") {
      const email = error.customData.email;
      const pendingCred = OAuthProvider.credentialFromError(error);

      try {
        // Fetch sign-in methods associated with the email
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        if (signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
          // Prompt the user to sign in with their email/password account
          const password = prompt("Your account is not linked with SSO. Please enter your password here to link accounts:");
          const credential = EmailAuthProvider.credential(email, password);

          // Sign in with email/password and link the Microsoft account
          const userCredential = await signInWithCredential(auth, credential);
          await linkWithCredential(userCredential.user, pendingCred);
          message.info("Accounts linked successfully");
          console.log("Accounts linked successfully!");
        } else {
          // If the user has other sign-in methods, handle accordingly
          console.log("Please sign in with your existing account.");
        }
      } catch (linkError) {
        console.error("Error linking accounts:", linkError);
      }
    } else {
      console.error("Error during Microsoft sign-in:", error);
    }
  }
};

export const signInWithGoogle = async () => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log("Signed-in user:", user);
  } catch (error) {
    if (error.code === "auth/account-exists-with-different-credential") {
      // Handle account linking similar to Microsoft sign-in
      const email = error.customData.email;
      const pendingCred = GoogleAuthProvider.credentialFromError(error);
      // ... rest of the account linking logic similar to Microsoft
    } else {
      console.error("Error during Google sign-in:", error);
    }
  }
};
