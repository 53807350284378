import React from "react";
import AccountLinking from "../../auth/unlinkMSSO";

const Unlink = () => {
  return (
    <div>
      <AccountLinking />
    </div>
  );
};

export default Unlink;
