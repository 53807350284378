//Label, Info: LabelInfo
import { ContextMenuItem, useContextMenu } from "use-context-menu";
import "use-context-menu/styles.css";
import "./contextmenu.css";
import { designations, getCurrentUserDesignation } from "../helper/attribute";
import { message, Modal } from "antd";
import { useState } from "react";
import { updateConfig } from "../firebase/appconfig/update";
import { catcher } from "../firebase/util";
import store from "../redux/store";
import ErrorBoundary from "../ErrorBoundary";
import LoadingSpin from "react-loading-spin";
export const texts = {
  companies: {
    title: "Companies",
    addButton: "+ Add",
    table: {
      name: "Name",
      sites: "Sites",
      createdAt: "Created At",
      startingMonth: "Starting Month",
    },
  },
  sites: {
    title: "Your Sites",
    addButton: "+ Add",
    add: {
      title: "Add New Site",
      desc: "Please Add in the following details to add in a new site.",
    },
    noSite: { title: "No Sites Yet!", action: "Add your first site" },
    table: {
      name: "Name",
      company: "Company",
      country: "Country",
      admins: "Admins",
      yourRole: "Your Role",
    },
  },
  users: {
    title: "Your Users",
    addButton: "+ Add",
    table: {
      name: "Name",
      company: "Company",
      status: "Status",
      contact: "Contact",
      twofa: "2FA",
    },
  },
  company: {
    profile: {
      text: "Profile",
      madeOn: "Made On",
      name: "Name",
      about: "About",
      aboutPlaceHolder: "About the company",
      startingFrom: "Starting From",
      startingFromInfo: "Reporting year represents the company starting month",
    },
    add: {
      title: "Add New Company",
      desc: "Please Add in the following details to add in a new company.",
      form: { title: "Title" },
    },
    settings: "Settings",
    companyAdmins: {
      title: "Company Admins",
      table: { name: "Name", contact: "Contact" },
    },
    intensityMetrics: {
      text: "Intensity Metrics",
      employeesFte: "Employees (FTE)",
      currency: "Currency",
      turnover: "Turnover (Millions)",
      additionalMetricNo: "Additional Metric Number for",
      editMetricName: "Edit Metric Name",
    },
  },
  site: {
    profile: {
      text: "Profile",
      madeOn: "Made On",
      name: "Name",
      country: "Country",
      tags: "Site Tags",
      tagsPlaceHolder: "Enter tags separated by commas",
      factors: "Supplier Factors",
      factorsInfo: "These factors will be used in Electricity data",
    },
    siteAdmins: {
      text: "Site Admins",
      table: { name: "Name", contact: "Contact" },
      add: "Add New Admin",
    },
    files: {
      text: "Files",
      add: "Add New File",
      uploadInfo:
        "Please select a file, ensuring that the name of the new file is distinct from any previously uploaded files to prevent overwriting.",
      table: { desc: "Description", name: "Name", options: "Options" },
    },
    settings: {
      text: "Settings",
      closeSite: "Close Site",
      siteClosed: "Site Closed",
      siteClosedMessage:
        "The site was closed on {lastClosed}. Hence, no data can be entered in this site until it is reopened.",
      closeSiteConfirm: "You will need to reopen this site to enter any data!",
      reOpenSite: "Re-open Site?",
      reOpenSiteConfirm: "The site will be available for data entry",
      deleteSite: "Delete Site",
      deleteSiteConfirm: "Do you confirm to delete this site?",
    },
  },
  analysis: {
    title: "Analysis",
    graph: {
      title: "Graph",
      scopetype: "Type",
      scopebar: { title: "Scope Chart" },
      piescope: { title: "Pie Scope Chart" },
      pie: { title: "Pie Chart" },
      bar: { title: "Bar Chart" },
    },
    options: {
      title: "Options",
      sites: "Sites",
      reporting: "Reporting",
      wtt: "WTT",
      type: "Type",
      moreSettings: "More Settings",
      monthNames: "Month Names",
      timeline: "Timeline",
      year: "Year",
      cancel: "Cancel",
      apply: "Apply",
      startDate: "Start Date",
      endDate: "End Date",
      reportType: "Report Type",
    },
    result: { title: "Results Breakdown" },
    yearly: { title: "Yearly Analysis", export: "Yearly Export" },
    monthly: { title: "Monthly Analysis", export: "Monthly Export" },
    sites: { title: "Site-by-Site Analysis", export: "Sites Export" },
    tags: { title: "Yearly Analysis", export: "Yearly Export" },
  },
  combined: {
    title: "Combined Result",
    export: "Export",
    table: {
      no: "No.",
      name: "Name",
      site: "Site",
      date: "Date",
      details1: "Details1",
      details2: "Details2",
      amount: "Amount",
      uom: "UOM",
      amount2: "Amount 2",
      uom2: "UOM 2",
      ghgCategory: "GHG Category",
      wttCategory: "WTT Category",
      comment: "Comment",
      emissions: "Emissions (tCO₂e)",
      wttEmissions: "WTT Emissions (tCO₂e)",
      secrKwh: "SECR kWh",
    },
    emissionsTotal: {
      combinedEmissions: "Combined Emissions",
      emissions: "Emissions",
      wttEmissions: "WTT Emissions",
      combinedMarketEmissions: "Combined Market-Based",
      combinedLocationEmissions: "Combined Location-Based",
      generationTnd: "Generation & T&D =",
      wttGenerationTnd: "WTT of Generation & WTT of T&D =",
    },
  },
  faq: { title: "FAQs", faqText: "Frequently Asked Questions" },
  dashboard: {
    title: "Dashboard",
    monthlyAnalysis: "Monthly Analysis",
    yearlyAnalysis: "Yearly Analysis",
    view: "View",
    resultInsightsYear: "Results Insights for this year",
    quickAccess: "Quick Access",
    inputDataForms: "Input Forms",
    greetings: {
      morning: "Good morning",
      morningIcon: "🌤️",
      afternoon: "Good afternoon",
      afternoonIcon: "☀️",
      evening: "Good evening",
      eveningIcon: "🌙",
    },
  },
  app: {
    loading: "Loading data",
    update: "Update",
    cancel: "Cancel",
    export: "Export ",
    back: "Back",
  },
  profile: {
    title: "Profile",
    logout: "Logout",
    firstName: "First Name",
    firstNamePh: "eg. John",
    lastNamePh: "eg. Smith",
    lastName: "Last Name",
    phone: "Phone",
    phonePh: "eg. 1234 123 1222",
  },
  form: {
    title: "Input",
    q: "Select Input Form",
    ins: "Instructions",
    more: "Additional Data",
    link: "Link",
    comments: "Comments",
    bulkButton: "Bulk Upload",
    steps: { select: "Select Form", inp: "Input Data", review: "Review" },
    review: {
      title: "Review Data",
      config: {
        title: "Config",
        desc: "Tip: You can create your own custom settings here to replace the system's default values.",
        ghg: "GHG Category",
      },
    },
  },
  results: { title: "Results" },
  bulk: { downErrRowsBtn: "Download Error Rows" },
  plans: {
    upgradePageTitle: "Upgrade",
    free: {
      title: "Lite",
      upgradeTitle: "Micro Business Version",
      bottomLine: "FREE",
      upgradePoints:
        "Single Building $ Enter up to 5 Flight Routes $ Enter up to 5 Vehicles (Car & Vans) $ Vehicle Fuel $ Public Transport $ Results Total $ Download PDF Report $ Quick and Easy $ Data saved for future use $ FREE $ Create an account now",
    },
    basic: {
      title: "Lite X",
      upgradeTitle: "Small Business Version",
      bottomLine: "£225/ Year",
      upgradePoints:
        "Single Building $ Enter up to 50 Flight Routes $ Enter up to 25 Vehicles $ Vehicle Fuel $ Public Transport $ Results Total $ Save up to 4 footprint sets (e.g. for multi years) $ Download PDF Report $ Quick and Easy $ Data saved for future use $ Up to 1 hour of Consultant Support",
    },
    premium: {
      title: "Lite XL",
      upgradeTitle: "Small to Medium Size Business Version",
      bottomLine: "£925/ Year",
      upgradePoints:
        "Multiple Buildings & Sites $ Enter up to 100 Flight Routes $ Enter up to 50 Vehicles $ Vehicle Fuel $ Public Transport $ Results Total $ Water $ Waste $ Save up to 8 footprint data sets $ Download PDF Report $ Easy to Use $ Data saved for future use $ Up to 3 hours of Consultant Support",
    },
    pro: {
      title: "MX",
    },
    DC: {
      title: "DC",
    },
    free: {
      title: "Lite",
    },
    basic: {
      title: "Lite X",
    },
    premium: {
      title: "Lite XL",
    },
    ma: {
      title: "AX",
    },
    demo: {
      title: "Demo",
    },
    Supplier: {
      title: "Supplier",
    },
  },
};

export function getProperty(obj, propertyString) {
  return propertyString.split(".").reduce((acc, prop) => acc && acc[prop], obj);
}

export function updateProperty(obj, propertyString, newValue) {
  const properties = propertyString.split(".");
  const lastProperty = properties.pop();

  let currentObj = obj;

  for (const prop of properties) {
    if (!currentObj[prop] || typeof currentObj[prop] !== "object") {
      currentObj[prop] = {};
    }
    currentObj = currentObj[prop];
  }

  currentObj[lastProperty] = newValue;
}

function replacePlaceholders(inputString, replacementObject) {
  //
  if (
    typeof inputString !== "string" ||
    typeof replacementObject !== "object"
  ) {
    return inputString; // Return the original input if the types are invalid
  }

  const placeholderRegex = /{([^}]+)}/g;

  const replacedString = inputString.replace(placeholderRegex, (match, key) => {
    if (replacementObject.hasOwnProperty(key)) {
      return replacementObject[key];
    } else {
      // If the key doesn't exist, return the original placeholder
      return match;
    }
  });

  return replacedString;
}
export const InnerT1 = ({ pos, replace }) => {
  // const texts = JSON.parse(JSON.stringify(store.getState().config.texts));
  const { contextMenu, onContextMenu, onKeyDown } = useContextMenu(
    <>
      <ContextMenuItem onSelect={showEditInput}>Edit✒️</ContextMenuItem>
    </>
  );

  const [isEditing, setIsEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [editedText, setEditedText] = useState("");
  const [originalText, setOriginalText] = useState(
    replacePlaceholders(getProperty(texts, pos), replace)
  );

  function showEditInput() {
    setIsEditing(true);
    setEditedText(originalText); // Initialize the input field with the current text
  }

  async function handleEditSave(e) {
    catcher(
      async () => {
        //CHECKS
        if (editedText.trim() === "") {
          return message.error("Cannot have empty text!");
        }
        updateProperty(texts, pos, editedText);
        await updateConfig(texts, "texts");
        setOriginalText(editedText);
        setIsEditing(false);
      },
      { setLoading: setUpdating }
    );
  }

  function handleEditCancel(e) {
    setIsEditing(false);
  }

  if (getCurrentUserDesignation() !== designations.super_admin) {
    return originalText;
  }

  // Add a keydown event listener to the span element
  const handleKeyDown = (e) => {
    if (e.target.tagName !== "INPUT" && e.keyCode === 32) {
      // Prevent the default behavior (context menu) when the space key is pressed on the span
      // e.preventDefault();
    }
  };
  if (updating) return "🔃 saving text";

  return (
    <>
      <span
        className={contextMenu && "contextopen"}
        onContextMenu={onContextMenu}
        onKeyDown={handleKeyDown} // Attach the event listener here
        tabIndex={0}
      >
        {isEditing ? (
          <div style={{ display: "flex", height: "30px" }}>
            <textarea
              value={editedText}
              onClick={(e) => e.stopPropagation()}
              onInput={(e) => {
                setEditedText(e.target.value);
              }}
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleEditSave();
              }}
            >
              ✓
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleEditCancel();
              }}
            >
              ✗
            </button>
          </div>
        ) : (
          originalText
        )}
      </span>
      {contextMenu}
    </>
  );
};

export const T1 = ({ pos, textOnly, replace }) => {
  if (textOnly) {
    return getProperty(texts, pos);
  }
  return (
    <ErrorBoundary component={<LoadingSpin />}>
      <InnerT1 pos={pos} replace={replace} />
    </ErrorBoundary>
  );
};

export default texts;
