import { notification } from "antd";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { setGroups } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";
import { fetchCompanies } from "../company/get";
import { fetchUsers } from "../user/get";
import { fetchCompaniesByIds } from "../company/get";
import { fetchSites } from "../site/get";

export async function fetchGroups(fetchCompaniesAndUsers = false) {
  try {
    store.dispatch(setGroups(undefined));
    const user = store.getState().user.doc;
    let groups = [];

    if (user.super_admin) {
      const groupsCollectionRef = collection(db, firestoreStatic.groups);
      groups = docsWithIds(await getDocs(groupsCollectionRef));
      store.dispatch(setGroups(groups));
    } else if (user.groupAdmin) {
      const groupsCollectionRef = doc(db, firestoreStatic.groups, user.groupAdmin);
      const docSnap = await getDoc(groupsCollectionRef);
      if (!docSnap.exists()) throw "Your group is deleted! Contact Super Admins";
      groups = [{ ...docSnap.data(), id: docSnap.id }];
      store.dispatch(setGroups(groups));
    }

    if (fetchCompaniesAndUsers) {
      // Merge all company IDs from all groups into a single array
      const allCompanyIds = groups.reduce((acc, group) => {
        if (group.companies && Array.isArray(group.companies)) {
          return [...acc, ...group.companies];
        }
        return acc;
      }, []);

      // Remove duplicates
      const uniqueCompanyIds = [...new Set(allCompanyIds)];

      if (uniqueCompanyIds.length > 0) {
        await fetchCompaniesByIds(uniqueCompanyIds);
        await fetchSites();
        await fetchUsers();
      }
    }
  } catch (error) {
    // Handle any errors that might occur during the fetch operation.
    console.error("Error fetching groups collection:", error);
    throw error;
  }
}
