import { KeyOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Input, Typography } from "antd";
import React, { useState } from "react";
import { handleLoginAsUserEmail } from "../../admin/users";
import { encodeAuthString } from "../../../helper/encode";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../../redux/features/userSlice";
import { getUserToken } from "../../../helper/attribute";

const ConsultantDashboard = () => {
  const [email, setEmail] = useState("");
  const user = useSelector(selectUserDoc);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <Typography.Title level={4}>Consultant's dashboard</Typography.Title>

      <Divider />
      <Typography.Title level={2}>
        Welcome {user.firstName} {user.lastName}
      </Typography.Title>

      <Card title="Login as user">
        <Input size="large" placeholder="Client's email" style={{ width: "500px" }} value={email} onChange={handleInputChange} />
        <Button
          icon={<KeyOutlined />}
          onClick={async () => {
            const token = await getUserToken();
            localStorage.setItem("consultant_token", token);
            await handleLoginAsUserEmail(email, "/consultant_user_verification?auth_key=" + store.getState().user.doc.id);
          }}
          size="large"
          type="primary"
          style={{ marginTop: "10px" }}
        >
          Login
        </Button>
      </Card>
    </div>
  );
};

export default ConsultantDashboard;
