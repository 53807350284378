import { AccountBookTwoTone, CalculatorOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import texts, { T1 } from "../../appconfig/texts";
import TextInput from "../../components/input/TextInput";
import CountrySelect from "../../components/select/country";
import FormContext from "../../contexts/FormContext";
import { updateSite } from "../../firebase/site/update";
import { updateUser } from "../../firebase/user/update";
import { catcher, except } from "../../firebase/util";
import { firstSite } from "../../helper/attribute";
import { getCurrentPlan, isSustraxLite } from "../../helper/plans";
import { selectUserDoc, setUserDoc } from "../../redux/features/userSlice";
import store from "../../redux/store";
import IntensityMetrics from "../admin/companies/comp/metrics";
import { genPut } from "../form/helper/create";
import ChangePassword from "./changepassword";
import "./styles.css";
import useSize from "../../hooks/useSize";
import { addOrReplaceQueryParam, getAllParamsFromLocation } from "../../helper/loc";
import AccountLinkingComponent from "./link";

const UserProfile = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const userDoc = useSelector(selectUserDoc);
  const [data, setData] = useState({ ...userDoc });
  const [keyComp, setKeyComp] = useState(123);
  const handleUpdate = () => {
    except(async () => {
      await updateUser(userDoc.id, data);
      store.dispatch(setUserDoc(data));
      handleCancel();
    });
  };
  useEffect(() => {
    handleCancel();
  }, [userDoc]);
  const handleCancel = () => {
    setData({ ...userDoc });
  };
  const INPUTS = [
    {
      key: "firstName",
      title: <T1 pos={"profile.firstName"} />,
      elem: TextInput,
      description: texts.profile.firstNamePh,
    },
    {
      key: "lastName",
      title: <T1 pos={"profile.lastName"} />,
      elem: TextInput,
      description: texts.profile.lastNamePh,
    },
    {
      key: "phone",
      title: <T1 pos={"profile.phone"} />,
      elem: TextInput,
      description: texts.profile.phonePh,
    },
  ];
  const isUpdated = JSON.stringify(data) !== JSON.stringify(userDoc);
  return (
    <Card>
      <Typography.Title
        style={{
          fontSize: isExtraSmall ? "20px " : "30px",
          margin: isExtraSmall ? "0" : "",
        }}
      >
        <T1 pos={"profile.title"} />
      </Typography.Title>

      <Divider />
      {INPUTS.map((input) => genPut(input, "", data, setData))}
      <Button onClick={handleUpdate} type="primary" disabled={!isUpdated}>
        <T1 pos={"app.update"} />
      </Button>
      {isUpdated && (
        <Button onClick={handleCancel}>
          <T1 pos={"app.cancel"} />
        </Button>
      )}
      <br></br>
      <br></br>
      {isSustraxLite() && (
        <div key="">
          <Typography.Title style={{ fontSize: isExtraSmall ? "22px" : "30px" }}>Company</Typography.Title>
          <Divider />
          {genPut(
            {
              key: "country",
              title: "Country",
              elem: CountrySelect,
              setValue: async (country) => {
                catcher(async () => {
                  await updateSite(firstSite()?.id, { country });
                  setKeyComp(keyComp + 1);
                }, {});
              },
            },
            "",
            firstSite()
          )}
        </div>
      )}
    </Card>
  );
};

const returnItems = () => {
  return [
    {
      key: "user",
      label: (
        <>
          <UserOutlined /> User
        </>
      ),
      children: (
        <>
          <UserProfile />
        </>
      ),
    },
    {
      key: "metrics",
      label: (
        <>
          <CalculatorOutlined /> Metrics
        </>
      ),
      children: <IntensityMetrics allowChangeYear={false} />,
      forPlans: ["free", "basic"],
    },
    {
      key: "password",
      label: (
        <>
          <KeyOutlined /> Password
        </>
      ),
      children: <ChangePassword />,
    },
    {
      key: "link",
      label: (
        <>
          <AccountBookTwoTone /> Link Account
        </>
      ),
      children: <AccountLinkingComponent />,
    },
  ].filter((tab) => {
    if (tab.hasOwnProperty("forPlans")) {
      return tab?.forPlans.includes(getCurrentPlan());
    }
    if (tab.key === "link") return;// for accident unlinking of main password account.
    return true;
  });
};
const Profile = () => {
  const [tab, setTab] = useState(getAllParamsFromLocation()?.tab ?? "user");
  const { isExtraSmall } = useSize();
  const onChange = (key) => {
    setTab(key);
    addOrReplaceQueryParam("tab", key);
  };

  return (
    <>
      <FormContext.Provider
        value={{
          fixedWidth: isExtraSmall ? "90%" : "500px",
          bgless: true,
          borderless: true,
          minWidthElem: isExtraSmall ? "78%" : "500px",
          maxWidthElem: isExtraSmall ? "78%" : "500px",
          minWidthLabel: isExtraSmall ? "80px" : "150px",
        }}
      >
        <Typography.Title style={{ fontSize: isExtraSmall ? "26px" : "38px" }}>Profile Settings</Typography.Title>
        <Tabs defaultActiveKey={tab} type="card" items={returnItems()} onChange={onChange} />
      </FormContext.Provider>
    </>
  );
};

export default Profile;
