import { useState, useEffect } from "react";
import { Table, Button, Popover, Input, Card, Typography, Select, Row, Col, Switch, Modal, Divider, Empty, Slider, Space, Popconfirm, Dropdown, List, Tooltip as AntdTooltip } from "antd";
import {
  ReloadOutlined,
  DeleteOutlined,
  CommentOutlined,
  SettingOutlined,
  PlusOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  EditOutlined,
  MoreOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ResponsiveContainer, ComposedChart, LineChart, BarChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { graph_colors } from "../../../extras/testing/charts/config";
import { useSelector } from "react-redux";
import { selectResults } from "../../../../redux/features/appSlice";
import { getAnalysis } from "../../../extras/testing/workers/year";
import { extractTotals } from "../../../extras/testing/workers/year";
import { useAtom } from "jotai";
import { devSidebarAtom } from "../../../../DevSidebar";
import { firstCompany } from "../../../../helper/attribute";
import { updateCompany } from "../../../../firebase/company/update";
import { message } from "antd";
import { TextAreaWithSaveCancel } from "antd-elements";
import isMobile from "is-mobile";
import { fixDecimals } from "../../../../helper/wodash";
import Spreadsheet from "react-spreadsheet";
import { LuRedo2, LuUndo2 } from "react-icons/lu";
import "./targets.css";

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

// Import the fillArray function
const fillArray = (arr, mode = "forwardFill", replaceArray) => {
  const a = JSON.parse(JSON.stringify(arr));
  if (!Array.isArray(a)) {
    throw new Error("Input must be an array.");
  }
  if (!Array.isArray(replaceArray)) {
    throw new Error("replaceArray must be an array.");
  }

  const shouldReplace = (value) => replaceArray.includes(value);

  if (mode === "forwardFill") {
    // Fill empty slots (replaceable values) with the last seen value.
    return a.map((value, index) => {
      if (shouldReplace(value)) {
        let i = index - 1;
        while (i >= 0 && shouldReplace(a[i])) i--; // Look for the last non-replaceable value
        return i >= 0 ? a[i] : value; // Use it if found, otherwise stay as is
      }
      return value;
    });
  }

  if (mode === "backwardFill") {
    // Fill empty slots (replaceable values) with the next non-replaceable value.
    const result = [...a];
    for (let i = a.length - 1; i >= 0; i--) {
      if (shouldReplace(result[i])) {
        let j = i + 1;
        while (j < a.length && shouldReplace(result[j])) j++; // Look ahead for the next value
        result[i] = j < a.length ? result[j] : result[i]; // Use it if found, otherwise stay as is
      }
    }
    return result;
  }

  if (mode === "smooth") {
    // Gradually fill replaceable slots with evenly spaced values.
    const result = [...a];
    let start = null,
      end = null;

    for (let i = 0; i < a.length; i++) {
      if (shouldReplace(a[i])) {
        if (start === null) start = i - 1; // Mark the start of a replaceable sequence
      } else if (start !== null) {
        end = i; // Found the end of the sequence
        const step = (a[end] - a[start]) / (end - start); // Calculate the step
        for (let j = start + 1; j < end; j++) {
          result[j] = parseFloat((result[start] + step * (j - start)).toFixed(2)); // Fill values
        }
        start = end = null; // Reset markers
      }
    }
    return result;
  }

  return a; // Default case, return the original array
};

// Add this state manager outside the component
const StateManager = {
  states: [],
  currentIndex: -1,
  maxStates: 5,

  pushState(state) {
    // Remove any future states if we're not at the end
    this.states = this.states.slice(0, this.currentIndex + 1);

    // Add new state
    this.states.push(JSON.parse(JSON.stringify(state)));

    // Remove oldest state if we exceed maxStates
    if (this.states.length > this.maxStates) {
      this.states.shift();
    }

    this.currentIndex = this.states.length - 1;
  },

  undo() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      return JSON.parse(JSON.stringify(this.states[this.currentIndex]));
    }
    return null;
  },

  redo() {
    if (this.currentIndex < this.states.length - 1) {
      this.currentIndex++;
      return JSON.parse(JSON.stringify(this.states[this.currentIndex]));
    }
    return null;
  },
  clearStates() {
    this.states = [];
    this.currentIndex = -1;
  },
};

const Targets = () => {
  let [baselineYear, setBaselineYear] = useState();
  const [tableData, setTableData] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [currentCell, setCurrentCell] = useState(null);
  const [cellValue, setCellValue] = useState("");
  const [cellComment, setCellComment] = useState("");
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [valueType, setValueType] = useState("Market");
  const [valueScope, setScope] = useState("Total");
  const [displayMode, setDisplayMode] = useState("value");
  const [percentValue, setPercentValue] = useState("");
  const [chartType, setChartType] = useState("both");
  const results = useSelector(selectResults);
  const [devData, setDevData] = useAtom(devSidebarAtom);
  const [totals, setTotals] = useState();
  const [dataYears, setDataYears] = useState([]);
  const [barOptions, setBarOptions] = useState({
    barWidth: 5,
    barOpacity: 1,
  });
  const [lineOptions, setLineOptions] = useState({
    strokeWidth: 2,
    type: "linear",
    activeDotRadius: 3,
  });

  const [chartSettings, setChartSettings] = useState({
    chartType: "both",
    lineOptions: {
      strokeWidth: 2,
      type: "linear",
      activeDotRadius: 3,
    },
    barOptions: {
      barWidth: 5,
      barOpacity: 1,
    },
  });

  const [isChartSettingsModalVisible, setIsChartSettingsModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [notes, setNotes] = useState(firstCompany().target_notes);

  // Add this state after other useState declarations
  const [availableSnapshots, setAvailableSnapshots] = useState([]);

  // Add this useEffect to load snapshots from company data
  useEffect(() => {
    // Load snapshots from company data
    const companySnapshots = firstCompany()?.target_snapshots || [];
    setAvailableSnapshots(companySnapshots);
  }, []);

  // Years for dropdown selection
  const yearOptions = dataYears.map((i) => ({ label: i, value: i }));

  // Scope options
  const scopeOptions = [
    { label: "All Scopes", value: "Total" },
    { label: "Scope 1", value: "Scope 1" },
    { label: "Scope 2", value: "Scope 2" },
    { label: "Scope 3", value: "Scope 3" },
  ];

  // Value type options
  const valueTypeOptions = [
    { label: "Market", value: "Market" },
    { label: "Location", value: "Location" },
  ];

  // Generate key from settings
  const generateKeyFromCurrentSettings = (type = valueType, selectedScope = valueScope) => {
    return `${type}-${selectedScope}`;
  };

  // Generate all possible keys for cell data
  const generateAllPossibleKeys = () => {
    const keys = {};
    valueTypeOptions.forEach((typeOption) => {
      scopeOptions.forEach((scopeOption) => {
        const key = generateKeyFromCurrentSettings(typeOption.value, scopeOption.value);
        // Each key now has both value and comment
        keys[key] = { value: null, comment: "" };
      });
    });
    return keys;
  };

  // Get current key based on selected settings
  const getCurrentValueKey = () => generateKeyFromCurrentSettings();

  // Load data from localStorage on component mount
  useEffect(() => {
    async function loadData() {
      const savedData = firstCompany().targetsTableData;
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        updateTableData(parsedData);
        // Initialize StateManager with the loaded data
        StateManager.pushState(parsedData);
        setCanUndo(false);
        setCanRedo(false);
      } else {
        initializeTable();
      }
    }
    loadData();
  }, []);

  const updateTableData = (newTableData, shouldSaveState = "nosave") => {
    const newTableDataCopy = JSON.parse(JSON.stringify(newTableData));
    if (Array.isArray(newTableDataCopy)) {
      try {
        newTableDataCopy.forEach((row) => {
          dataYears.forEach((year) => {
            if (row.title !== "Actual Data") {
              if (baselineYear != year) return;
            }

            const toUpdate = row[`year_${year}`];

            row[`year_${year}`] = {
              ...toUpdate,
              [getCurrentValueKey()]: { value: Number(totals[year] ?? 0) },
            };
          });
        });
        setTableData(newTableDataCopy);

        // Save state to history
        if (shouldSaveState === "save") {
          StateManager.pushState(newTableDataCopy);
          // Update undo/redo buttons state
          setCanUndo(StateManager.currentIndex > 0);
          setCanRedo(StateManager.currentIndex < StateManager.states.length - 1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // Save to localStorage whenever tableData changes
  useEffect(() => {
    async function saveData() {
      if (tableData.length > 0) {
        await updateCompany(firstCompany().id, { targetsTableData: JSON.stringify(tableData) });
        // localStorage.setItem("targetsTableData", JSON.stringify(tableData));
      }
    }
    saveData();
  }, [tableData]);

  const init = (recalculateWithSave = false) => {
    let includeYearsOnly;
    const companyId = firstCompany().id;
    const analysisOptions = { wtt: "Included", type: valueType, type2: "Total", reporting: "All", sites: [companyId] };
    if (baselineYear) {
      includeYearsOnly = dataYears.filter((y) => y >= baselineYear);
    }
    const analysisData = getAnalysis(results, analysisOptions, includeYearsOnly);
    let totals = extractTotals(analysisData);

    dataYears.forEach((year) => {
      try {
        totals[year] = totals[year][valueScope];
      } catch (err) {
        totals[year] = totals[year];
        console.log("xoerr", err, totals, year, valueScope);
      }
    });

    if (!baselineYear) {
      const years = analysisData.moreInfo.cols.map(Number);
      baselineYear = years[0]; //imp, used in the future
      setBaselineYear(baselineYear);
      setDataYears(years);
    }
    // setDevData({ totals, tableData });
    setTotals(totals);
  };

  useEffect(() => {
    if (tableData && totals) updateTableData(tableData);
  }, [totals]);

  useEffect(() => {
    if (Array.isArray(results)) init();
  }, [baselineYear, results, valueScope, valueType]);

  useEffect(() => {
    const savedNotes = firstCompany().target_notes;
    if (savedNotes) {
      setNotes(savedNotes);
    }
  }, []);

  // Create empty cell data for a range of years
  const createEmptyCellsForYears = (startYear, endYear) => {
    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).reduce((acc, year) => {
      acc[`year_${year}`] = generateAllPossibleKeys();
      return acc;
    }, {});
  };

  // Initialize the table with default rows
  const initializeTable = () => {
    const endYear = 2050;
    const emptyCells = createEmptyCellsForYears(baselineYear, endYear);

    // Create rows with empty cells
    const newData = [
      {
        key: "1",
        title: "Passive Target",
        ...emptyCells,
      },
      {
        key: "2",
        title: "Active Target",
        ...emptyCells,
      },
      {
        key: "3",
        title: "Actual Data",
        ...emptyCells,
      },
    ];

    updateTableData(newData);
    // Initialize StateManager with the new table data
    StateManager.pushState(newData);
    setCanUndo(false);
    setCanRedo(false);
  };

  // Handle baseline year change
  const handleBaselineChange = (value) => {
    Modal.confirm({
      title: "Change Baseline Year",
      icon: <WarningOutlined style={{ color: "#faad14" }} />,
      content: "Changing the baseline year will affect all percentage calculations. Are you sure you want to proceed?",
      okText: "Yes, Change",
      okType: "primary",
      cancelText: "No",
      onOk() {
        setBaselineYear(value);
        message.success(`Baseline year updated to ${value}`);
      },
    });
  };

  // Check if a row is the "Actual Data" row
  const isActualDataRow = (record) => {
    return record.title === "Actual Data";
  };

  // Get baseline value for a row
  const getBaselineValue = (record) => {
    const baselineKey = `year_${baselineYear}`;
    const valueKey = getCurrentValueKey();

    if (record && record[baselineKey] && record[baselineKey][valueKey]) {
      return record[baselineKey][valueKey].value;
    }
    return null;
  };

  // Calculate percent difference from baseline (modified for decrease)
  const calculatePercentDifference = (currentValue, baselineValue) => {
    if (baselineValue === null || baselineValue === 0) {
      return currentValue === null ? 0 : -100; // If baseline is 0, any value is -100% decrease
    }

    if (currentValue === null) {
      return 0;
    }

    // Reverse the sign to show decrease
    return -((currentValue - baselineValue) / Math.abs(baselineValue)) * 100;
  };

  // Convert percent back to absolute value (modified for decrease)
  const percentToAbsoluteValue = (percent, baselineValue) => {
    if (baselineValue === null || baselineValue === 0) {
      return 0; // Can't calculate if baseline is 0
    }

    // Reverse the sign since percent now represents decrease
    return baselineValue * (1 - percent / 100);
  };

  // Handle cell click to open popover
  const handleCellClick = (record, yearKey) => {
    // Don't allow editing for the "Actual Data" row
    if (isActualDataRow(record)) {
      return;
    }

    if (yearKey === "title") {
      // Handle title cell click
      setCurrentCell({ rowKey: record.key, yearKey: "title" });
      setCellValue(record.title);
      setIsTitleEdit(true);
      setIsPopoverVisible(true);
    } else {
      // Handle regular cell click
      const defaultCellData = generateAllPossibleKeys();
      const cellData = record[yearKey] || defaultCellData;
      setCurrentCell({ rowKey: record.key, yearKey });

      // Set the value and comment based on the current valueType and scope
      const valueKey = getCurrentValueKey();
      const actualValue = cellData[valueKey]?.value ?? "";
      setCellValue(actualValue);
      setCellComment(cellData[valueKey]?.comment ?? "");

      // Calculate percent value if in percent mode
      if (displayMode === "percent" && yearKey !== `year_${baselineYear}`) {
        const baselineValue = getBaselineValue(record);
        if (baselineValue !== null && actualValue !== "") {
          const percentDiff = calculatePercentDifference(actualValue, baselineValue);
          setPercentValue(percentDiff.toFixed(2));
        } else {
          setPercentValue("");
        }
      }

      setIsTitleEdit(false);
      setIsPopoverVisible(true);
    }
  };

  // Save cell data when popover is confirmed
  const handlePopoverOk = (shouldClear=false) => {
    if (currentCell) {
      const { rowKey, yearKey } = currentCell;
      const newData = [...tableData];
      const rowIndex = newData.findIndex((item) => item.key === rowKey);

      if (rowIndex > -1) {
        if (yearKey === "title") {
          // Update row title
          if(shouldClear==="clear"){
            newData[rowIndex].title = "";
          }else{
            newData[rowIndex].title = cellValue;
          }
        } else {
          // Get existing cell data or create new object
          const defaultCellData = generateAllPossibleKeys();
          const existingData = newData[rowIndex][yearKey] || defaultCellData;

          // Update only the selected value type and scope while preserving others
          const valueKey = getCurrentValueKey();

          let newValue = cellValue;

          // If in percent mode and not editing baseline year, convert percent to absolute value
          if (displayMode === "percent" && yearKey !== `year_${baselineYear}`) {
            const baselineValue = getBaselineValue(newData[rowIndex]);
            if (baselineValue !== null && percentValue !== "") {
              newValue = percentToAbsoluteValue(parseFloat(percentValue), baselineValue);
            }
          }

          newData[rowIndex][yearKey] = {
            ...existingData,
            [valueKey]: {
              value: newValue !== "" ? shouldClear==="clear" ? null : parseFloat(newValue) : null,
              comment: shouldClear==="clear" ? "" : cellComment,
            },
          };
        }
        updateTableData(newData, "save");
      }
    }
    setIsPopoverVisible(false);
  };

  // Close popover without saving
  const handlePopoverCancel = () => {
    setIsPopoverVisible(false);
  };

  // Add a new row above the "Actual Data" row
  const handleAddRow = () => {
    const endYear = 2050;
    const emptyCells = createEmptyCellsForYears(baselineYear, endYear);

    // Find the index of the "Actual Data" row
    const actualDataIndex = tableData.findIndex((row) => row.title === "Actual Data");

    const newRow = {
      key: `${Date.now()}`, // Use timestamp for unique key
      title: `Row ${tableData.length}`,
      ...emptyCells,
    };

    // Insert the new row before the "Actual Data" row
    const newData = [...tableData];
    if (actualDataIndex !== -1) {
      newData.splice(actualDataIndex, 0, newRow);
    } else {
      newData.push(newRow);
    }

    updateTableData(newData, "save");
  };

  // Handle row deletion
  const handleDeleteRow = (key) => {
    Modal.confirm({
      title: "Delete Target Row",
      icon: <ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />,
      content: "Are you sure you want to delete this target row? This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const newData = tableData.filter((item) => item.key !== key);
        updateTableData(newData, "save");
        message.success("Target row deleted successfully");
      },
    });
  };

  // Check if a comment is non-empty (not just spaces)
  const hasNonEmptyComment = (comment) => {
    return comment && comment.trim() !== "";
  };

  // Toggle display mode between value and percent
  const handleDisplayModeChange = (checked) => {
    setDisplayMode(checked ? "percent" : "value");
  };

  // Render individual cell with proper styling
  const renderCell = (cell, record, yearKey) => {
    const defaultCellData = generateAllPossibleKeys();
    const cellData = cell || defaultCellData;

    // Display the value based on the current valueType and scope
    const valueKey = getCurrentValueKey();
    const displayValue = cellData[valueKey]?.value;
    const comment = cellData[valueKey]?.comment;
    const hasComment = hasNonEmptyComment(comment);
    let isActual = isActualDataRow(record);

    // For percent display mode, calculate percent difference from baseline
    let displayText = displayValue !== null ? displayValue : "";

    if (displayMode === "percent" && yearKey !== `year_${baselineYear}`) {
      const baselineValue = getBaselineValue(record);
      if (displayValue !== null && baselineValue !== null) {
        const percentDiff = calculatePercentDifference(displayValue, baselineValue);
        displayText = `${percentDiff.toFixed(0)}%`;
      } else {
        displayText = "";
      }
    } else if (displayValue !== null) {
      // Format the number to 2 decimal places if it's a value
      displayText = Number(displayValue).toFixed(2);
    }

    if (yearKey === `year_${baselineYear}`) {
      isActual = true;
    }

    return (
      <div
        className={`editable-cell ${isActual ? "actual-data-cell" : ""} cell`}
        onClick={() => handleCellClick(record, yearKey)}
        style={{
          backgroundColor: hasComment ? "#03790338" : "inherit",
          cursor: isActual ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: isActual ? "darkgrey" : "inherit",
          fontWeight: isActual ? "bold" : "normal",
        }}
      >
        <span>{displayText}</span>
        {hasComment && <CommentOutlined style={{ color: "darkgreen", marginLeft: 4 }} />}
      </div>
    );
  };

  // Render the edit cell popover content
  const renderPopoverContent = () => {
    try {
      // Get the display name for the current scope
      const scopeDisplay = scopeOptions.find((option) => option.value === valueScope)?.label || "All Scopes";
      const valueTypeDisplay = valueTypeOptions.find((option) => option.value === valueType)?.label || "Market";

      // Check if we're editing a baseline year cell
      const isBaselineYearCell = currentCell && currentCell.yearKey === `year_${baselineYear}`;

      return (
        <div style={{ width: 300 }}>
          {isTitleEdit ? (
            <div style={{ marginBottom: 16 }}>
              <Text strong>Title:</Text>
              <Input value={cellValue} onChange={(e) => setCellValue(e.target.value)} style={{ width: "100%", marginTop: 8 }} />
            </div>
          ) : (
            <>
              {displayMode === "value" && (
                <div style={{ marginBottom: 16 }}>
                  <Text strong>{`Emissions (tCO₂e)  (${currentCell.yearKey.replace("year_", "")}):`}</Text>
                  <Input type="number" value={cellValue} onChange={(e) => setCellValue(e.target.value)} style={{ width: "100%", marginTop: 8 }} />
                </div>
              )}

              {displayMode === "percent" && !isBaselineYearCell && (
                <div style={{ marginBottom: 16 }}>
                  <Text strong>{`Percent Change from ${baselineYear}:`}</Text>
                  <Input type="number" value={percentValue} onChange={(e) => setPercentValue(e.target.value)} addonAfter="%" style={{ width: "100%", marginTop: 8 }} />
                </div>
              )}

              <div style={{ marginBottom: 16 }}>
                <Text strong>{`Comment (${currentCell.yearKey.replace("year_", "")})`}</Text>
                <TextArea rows={3} value={cellComment} onChange={(e) => setCellComment(e.target.value)} style={{ width: "100%", marginTop: 8 }} />
              </div>
            </>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8, marginTop: 16 }}>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                // Clear value and comment
                handlePopoverOk("clear");
              }}
              danger
            >
              Clear
            </Button>
            <Button onClick={handlePopoverCancel}>Cancel</Button>
            <Button type="primary" onClick={handlePopoverOk}>
              OK
            </Button>
          </div>
        </div>
      );
    } catch (err) {}
  };

  // Generate columns for the table
  const generateColumns = () => {
    return [
      {
        title: "Target",
        dataIndex: "title",
        key: "title",
        width: 150,
        fixed: "left",
        render: (text, record) => {
          const isActual = isActualDataRow(record);

          return isActual ? (
            <div
              className="actual-data-title"
              style={{
                borderRadius: "2px",
                color: "darkgrey",
                fontWeight: "bold",
              }}
            >
              {text}
            </div>
          ) : (
            <Popover
              content={renderPopoverContent()}
              title="Edit Row Title"
              trigger="click"
              open={isPopoverVisible && currentCell?.rowKey === record.key && currentCell?.yearKey === "title"}
              onOpenChange={(visible) => {
                if (!visible) setIsPopoverVisible(false);
              }}
            >
              <div
                className="editable-cell"
                onClick={() => handleCellClick(record, "title")}
                style={{
                  cursor: "pointer",
                  borderRadius: "2px",
                  fontWeight: "bold",
                }}
              >
                {text}
              </div>
            </Popover>
          );
        },
      },
      ...Array.from({ length: 2050 - baselineYear + 1 }, (_, i) => {
        const year = baselineYear + i;
        return {
          title: (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span>{year}</span>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "clear",
                      label: (
                        <Popconfirm
                          title="Clear Column"
                          description={`Are you sure you want to clear all values for ${year}?`}
                          onConfirm={() => handleClearColumn(`year_${year}`)}
                          okText="Yes"
                          cancelText="No"
                          icon={<ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />}
                        >
                          <span>
                            Clear Column <DeleteOutlined />
                          </span>
                        </Popconfirm>
                      ),
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button type="text" size="small" icon={<MoreOutlined />} style={{ marginLeft: 8 }} />
              </Dropdown>
            </div>
          ),
          dataIndex: `year_${year}`,
          key: `year_${year}`,
          width: 100,
          render: (cell, record) => {
            const isActual = isActualDataRow(record);

            return isActual ? (
              renderCell(cell, record, `year_${year}`)
            ) : (
              <Popover
                content={renderPopoverContent()}
                title="Edit Cell"
                trigger="click"
                open={isPopoverVisible && currentCell?.rowKey === record.key && currentCell?.yearKey === `year_${year}`}
                onOpenChange={(visible) => {
                  if (!visible) setIsPopoverVisible(false);
                }}
              >
                {renderCell(cell, record, `year_${year}`)}
              </Popover>
            );
          },
        };
      }),
      {
        title: "Actions",
        key: "actions",
        width: 120,
        fixed: "right",
        render: (_, record, index) => {
          // Check if this is the "Actual Data" row
          const isActual = isActualDataRow(record);

          if (isActual) {
            return <Button icon={<DeleteOutlined />} disabled={true} style={{ opacity: 0.5 }} />;
          }

          // Find the index of the last non-"Actual Data" row
          const lastSortableIndex = tableData.findIndex((row) => isActualDataRow(row)) - 1;

          return (
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <div style={{ display: "flex", border: "1px solid #d9d9d9", borderRadius: 2 }}>
                <Button
                  type="text"
                  icon={<CaretUpOutlined />}
                  size="small"
                  disabled={index === 0}
                  onClick={() => handleMoveRow(index, "up")}
                  style={{
                    padding: "4px 8px",
                    margin: 0,
                    borderRight: "1px solid #d9d9d9",
                  }}
                />
                <Button
                  type="text"
                  icon={<CaretDownOutlined />}
                  size="small"
                  disabled={index === lastSortableIndex}
                  onClick={() => handleMoveRow(index, "down")}
                  style={{
                    padding: "4px 8px",
                    margin: 0,
                  }}
                />
              </div>
              <Button size="small" icon={<DeleteOutlined />} onClick={() => handleDeleteRow(record.key)} danger />
            </div>
          );
        },
      },
    ].map((item) => {
      if (isMobile()) {
        delete item.fixed;
      }
      return item;
    });
  };

  // Generate the columns based on the current baseline year
  const columns = generateColumns();

  // Get color for chart elements
  const getColor = (index) => graph_colors[index % graph_colors.length];

  // Prepare data for charts
  const prepareChartData = () => {
    if (!tableData || tableData.length === 0) return [];

    // Get all years from baseline to 2050
    const years = Array.from({ length: 2050 - baselineYear + 1 }, (_, i) => baselineYear + i);

    // First, collect all values by row title
    const rowValues = {};

    tableData.forEach((row) => {
      const values = [];
      years.forEach((year) => {
        const yearKey = `year_${year}`;
        const valueKey = getCurrentValueKey();

        // Make sure we're getting a numeric value
        let value = null;
        if (!row) return;
        if (row[yearKey] && row[yearKey][valueKey]) {
          value = row[yearKey][valueKey].value;
          // Convert to number if it's not null
          if (value !== null) {
            value = Number(value);
            // If NaN, set to null
            if (isNaN(value)) value = null;
          }
        }
        values.push(value);
      });

      // Apply forward fill to handle null/undefined values
      rowValues[row.title] = fillArray(values, "smooth", [null, undefined]);
    });

    // Create chart data with filled values
    return years.map((year, index) => {
      const chartRow = { year: year.toString() };

      // Add filled data from each row
      Object.keys(rowValues).forEach((title) => {
        chartRow[title] = rowValues[title][index];
      });

      return chartRow;
    });
  };

  // Open chart settings modal
  const openChartSettings = () => {
    setIsChartSettingsModalVisible(true);
  };
  const openSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  // Handle chart settings changes
  const handleChartSettingsChange = (key, value) => {
    if (key.includes(".")) {
      const [parent, child] = key.split(".");
      setChartSettings({
        ...chartSettings,
        [parent]: {
          ...chartSettings[parent],
          [child]: value,
        },
      });
    } else {
      setChartSettings({
        ...chartSettings,
        [key]: value,
      });
    }
  };

  // Save chart settings and close modal
  const saveChartSettings = () => {
    setChartType(chartSettings.chartType);
    setLineOptions(chartSettings.lineOptions);
    setBarOptions(chartSettings.barOptions);
    setIsChartSettingsModalVisible(false);
  };

  // Cancel chart settings changes
  const cancelChartSettings = () => {
    // Reset to current values
    setChartSettings({
      chartType,
      lineOptions,
      barOptions,
    });
    setIsChartSettingsModalVisible(false);
  };

  // Render chart settings modal
  const renderChartSettingsModal = () => {
    return (
      <Modal title="Chart Settings" open={isChartSettingsModalVisible} onOk={saveChartSettings} onCancel={cancelChartSettings} width={500}>
        <div>
          <Divider>Chart Type</Divider>
          <Select value={chartSettings.chartType} onChange={(value) => handleChartSettingsChange("chartType", value)} style={{ width: "100%", marginBottom: 16 }}>
            <Option value="both">Comparison (Line & Bar)</Option>
            <Option value="line">Line Chart</Option>
            <Option value="bar">Bar Chart</Option>
          </Select>

          <Divider>Line Options</Divider>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Line Width:</Text>
            <Slider min={1} max={5} value={chartSettings.lineOptions.strokeWidth} onChange={(value) => handleChartSettingsChange("lineOptions.strokeWidth", value)} />
          </div>
          <div style={{ marginBottom: 16, display: "none" }}>
            <Text strong>Line Type:</Text>
            <Select value={chartSettings.lineOptions.type} onChange={(value) => handleChartSettingsChange("lineOptions.type", value)} style={{ width: "100%" }}>
              <Option value="linear">Linear</Option>
              <Option value="monotone">Smooth</Option>
              <Option value="step">Step</Option>
              <Option value="basis">Basis</Option>
            </Select>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Dot Size:</Text>
            <Slider min={1} max={8} value={chartSettings.lineOptions.activeDotRadius} onChange={(value) => handleChartSettingsChange("lineOptions.activeDotRadius", value)} />
          </div>

          <Divider>Bar Options</Divider>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Bar Width:</Text>
            <Slider min={1} max={20} value={chartSettings.barOptions.barWidth} onChange={(value) => handleChartSettingsChange("barOptions.barWidth", value)} />
          </div>
          <div style={{ marginBottom: 16 }}>
            <Text strong>Bar Opacity:</Text>
            <Slider min={0.1} max={1} step={0.1} value={chartSettings.barOptions.barOpacity} onChange={(value) => handleChartSettingsChange("barOptions.barOpacity", value)} />
          </div>
        </div>
      </Modal>
    );
  };

  // Add this function after the handleDeleteRow function
  const handleMoveRow = (index, direction) => {
    const newData = [...tableData];
    const actualDataIndex = newData.findIndex((row) => isActualDataRow(row));

    // Don't allow moving past the "Actual Data" row
    if (direction === "down" && index >= actualDataIndex - 1) {
      return;
    }

    // Calculate new index
    const newIndex = direction === "up" ? index - 1 : index + 1;

    // Don't allow moving out of bounds
    if (newIndex < 0 || newIndex >= actualDataIndex) {
      return;
    }

    // Swap rows
    [newData[index], newData[newIndex]] = [newData[newIndex], newData[index]];

    updateTableData(newData, "save");
  };

  const handleNotesChange = (value) => {
    setNotes(value);
    updateCompany(firstCompany().id, { target_notes: value });
  };

  // Render settings modal
  const renderSettingsModal = () => {
    return (
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SettingOutlined />
            <span>Advanced Settings</span>
          </div>
        }
        open={isSettingsModalVisible}
        onOk={() => {
          setIsSettingsModalVisible(false);
        }}
        onCancel={() => {
          setIsSettingsModalVisible(false);
        }}
        cancelButtonProps={{ display: "none" }}
        width={600}
        style={{ top: 20 }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Card bordered={false}>
            <div style={{ marginBottom: 24 }}>
              <Title level={5} style={{ marginBottom: 16, display: "flex", alignItems: "center", gap: "8px" }}>
                <CalendarOutlined />
                Baseline Year
              </Title>
              <Select value={baselineYear} onChange={handleBaselineChange} style={{ width: "100%" }} options={yearOptions} size="large" />
            </div>

            <div>
              <Title level={5} style={{ marginBottom: 16, color: "#cf1322", display: "flex", alignItems: "center", gap: "8px" }}>
                <WarningOutlined />
                Data Management
              </Title>
              <Button
                type="primary"
                danger
                icon={<ReloadOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: "Reset All Data",
                    icon: <ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />,
                    content: (
                      <div>
                        <p style={{ color: "#ff4d4f", fontWeight: "bold" }}>WARNING</p>
                        <p>This will reset the table to initial state.</p>
                        <p>You may save a snapshot of the table to restore it later.</p>
                        <p>Are you absolutely sure you want to proceed?</p>
                      </div>
                    ),
                    okText: "Yes, Reset Everything",
                    okType: "danger",
                    cancelText: "No, Keep My Data",
                    onOk() {
                      initializeTable();
                      setIsSettingsModalVisible(false);
                      message.success("All data has been reset to initial state");
                    },
                  });
                }}
                style={{ width: "100%" }}
                size="large"
              >
                Reset All Data
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
    );
  };

  // Improved notes section
  const renderNotesSection = () => <TextAreaWithSaveCancel key={notes} onUpdate={handleNotesChange} initialValue={notes} />;

  // Render charts based on table data

  const chartConfig = {
    height: 500,
    margin: { top: 20, right: 30, left: 80, bottom: 40 },
  };

  // Reusable Tooltip Component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            padding: "10px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
          }}
        >
          <p className="label">{`Year: ${label}`}</p>
          {payload.map((entry, index) => {
            const rowData = tableData.find((row) => row.title === entry.name);
            const yearKey = `year_${label}`;
            const valueKey = getCurrentValueKey();
            const comment = rowData?.[yearKey]?.[valueKey]?.comment || "";

            return (
              <div key={`tooltip-${index}`} style={{ color: entry.color }}>
                <p>{`${entry.name}: ${fixDecimals(Number(entry.value))}`}</p>
                {comment && (
                  <p style={{ fontStyle: "italic" }}>
                    <strong>Comment:</strong> {comment}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  // Reusable Dot Component for Comments
  const CustomizedDot = ({ cx, cy, payload, dataKey, stroke }) => {
    // Don't render anything if cx or cy is undefined/null
    if (!cx || !cy || !payload[dataKey]) return null;

    const rowData = tableData.find((row) => row.title === dataKey);
    if (!rowData) return null;

    const yearKey = `year_${payload.year}`;
    const valueKey = getCurrentValueKey();
    const comment = rowData?.[yearKey]?.[valueKey]?.comment || "";
    const hasComment = hasNonEmptyComment(comment);

    // Only render if there's a value and optionally a comment
    return (
      <g>
        <circle cx={cx} cy={cy} r={chartSettings.lineOptions.activeDotRadius} stroke={stroke} fill={stroke} />
        {hasComment && (
          <svg x={cx - 8} y={cy - 20} width="16" height="16" viewBox="0 0 1024 1024">
            <path
              d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6z"
              stroke={stroke}
              fill="none"
              strokeWidth="40"
            />
          </svg>
        )}
      </g>
    );
  };

  // Function to render charts dynamically
  const renderChartComponent = (ChartComponent, chartType, key, chartData) => (
    <ResponsiveContainer width="100%" height={chartConfig.height} key={key}>
      <ChartComponent data={chartData} margin={{ ...chartConfig.margin, left: 60 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" label={{ value: "Year", position: "bottom", offset: 30 }} />
        <YAxis
          label={{
            value: "Emissions tCO₂e",
            angle: -90,
            position: "insideLeft",
            offset: -30,
            style: { textAnchor: "middle" },
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {tableData.map((row, index) => {
          const color = getColor(index);
          const isActualData = row.title === "Actual Data";

          if (chartType === "bar") {
            return (
              <Bar 
                key={`bar-${row.title}`} 
                dataKey={row.title} 
                fill={color} 
                opacity={chartSettings.barOptions.barOpacity} 
                barSize={chartSettings.barOptions.barWidth} 
                isAnimationActive={false} 
              />
            );
          }
          
          if (chartType === "line") {
            return (
              <Line
                key={`line-${row.title}`}
                type={chartSettings.lineOptions.type}
                dataKey={row.title}
                stroke={color}
                strokeWidth={chartSettings.lineOptions.strokeWidth}
                activeDot={{ r: chartSettings.lineOptions.activeDotRadius }}
                dot={<CustomizedDot />}
                connectNulls={true}
                isAnimationActive={false}
              />
            );
          }

          // For "both" type - Actual Data as bar, others as lines
          if (chartType === "both") {
            if (isActualData) {
              return (
                <Bar
                  key={`bar-${row.title}`}
                  dataKey={row.title}
                  fill={color}
                  opacity={chartSettings.barOptions.barOpacity}
                  barSize={chartSettings.barOptions.barWidth}
                  isAnimationActive={false}
                />
              );
            }
            return (
              <Line
                key={`line-${row.title}`}
                type={chartSettings.lineOptions.type}
                dataKey={row.title}
                stroke={color}
                strokeWidth={chartSettings.lineOptions.strokeWidth}
                activeDot={{ r: chartSettings.lineOptions.activeDotRadius }}
                dot={<CustomizedDot />}
                connectNulls={true}
                isAnimationActive={false}
              />
            );
          }
        })}
      </ChartComponent>
    </ResponsiveContainer>
  );

  const renderCharts = () => {
    const chartData = prepareChartData();
    if (!chartData || chartData.length === 0) {
      return <Empty description="No data available for charts" />;
    }

    console.log("Chart Data:", chartData);

    return (
      <div>
        {chartType === "both" && renderChartComponent(ComposedChart, "both", `chart-both-${valueScope}-${valueType}`, chartData)}

        {chartType === "line" && renderChartComponent(LineChart, "line", `chart-line-${valueScope}-${valueType}`, chartData)}

        {chartType === "bar" && renderChartComponent(BarChart, "bar", `chart-bar-${valueScope}-${valueType}`, chartData)}
      </div>
    );
  };

  // Add this new function to handle column clearing
  const handleClearColumn = (yearKey) => {
    const newData = [...tableData];

    newData.forEach((row, index) => {
      // Skip the "Actual Data" row
      if (!isActualDataRow(row)) {
        // Create empty cell data with all possible keys
        const emptyCellData = generateAllPossibleKeys();
        if (row) row[yearKey] = emptyCellData;
      }
    });

    updateTableData(newData, "save");
    message.success(`Column cleared successfully`);
  };

  // Add these state variables after other useState declarations
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [snapshotName, setSnapshotName] = useState("");
  const [isLoadModalVisible, setIsLoadModalVisible] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  // Add these functions after other function declarations
  const handleSaveTable = () => {
    setIsSaveModalVisible(true);
  };

  const handleLoadTable = () => {
    setIsLoadModalVisible(true);
  };

  const saveSnapshot = async () => {
    if (!snapshotName.trim()) {
      message.error("Please enter a valid name for the snapshot");
      return;
    }

    const newSnapshot = {
      id: Date.now().toString(), // Unique ID for the snapshot
      name: snapshotName.trim(),
      data: tableData,
      savedAt: new Date().toISOString(),
      baselineYear,
      valueType,
      valueScope,
      notes,
    };

    // Check if snapshot with same name exists
    const existingIndex = availableSnapshots.findIndex((s) => s.name === newSnapshot.name);

    if (existingIndex !== -1) {
      Modal.confirm({
        title: "Snapshot Already Exists",
        icon: <WarningOutlined style={{ color: "#faad14" }} />,
        content: "A snapshot with this name already exists. Do you want to overwrite it?",
        okText: "Yes, Overwrite",
        okType: "danger",
        cancelText: "No",
        onOk() {
          performSave(newSnapshot, existingIndex);
        },
      });
    } else {
      performSave(newSnapshot);
    }
  };

  const performSave = async (newSnapshot, existingIndex = -1) => {
    try {
      let updatedSnapshots;
      if (existingIndex !== -1) {
        // Replace existing snapshot
        updatedSnapshots = [...availableSnapshots];
        updatedSnapshots[existingIndex] = newSnapshot;
      } else {
        // Add new snapshot
        updatedSnapshots = [...availableSnapshots, newSnapshot];
      }

      // Update company data
      await updateCompany(firstCompany().id, {
        target_snapshots: updatedSnapshots,
      });

      setAvailableSnapshots(updatedSnapshots);
      setIsSaveModalVisible(false);
      setSnapshotName("");
      message.success("Table snapshot saved successfully");
    } catch (error) {
      console.error("Error saving snapshot:", error);
      message.error("Failed to save snapshot");
    }
  };

  const loadSnapshot = (snapshotId) => {
    const snapshot = availableSnapshots.find((s) => s.id === snapshotId);

    if (!snapshot) {
      message.error("Snapshot not found");
      return;
    }

    Modal.confirm({
      title: "Load Snapshot",
      icon: <WarningOutlined style={{ color: "#faad14" }} />,
      content: "Loading a snapshot will overwrite your current table data. Are you sure you want to proceed?",
      okText: "Yes, Load",
      okType: "primary",
      cancelText: "No",
      onOk() {
        try {
          updateTableData(snapshot.data);
          setBaselineYear(snapshot.baselineYear);
          setValueType(snapshot.valueType);
          setScope(snapshot.valueScope);
          setNotes(snapshot.notes);
          setIsLoadModalVisible(false);
          message.success("Snapshot loaded successfully");
        } catch (error) {
          console.error("Error loading snapshot:", error);
          message.error("Error loading snapshot");
        }
      },
    });
  };

  const deleteSnapshot = async (snapshotId) => {
    Modal.confirm({
      title: "Delete Snapshot",
      icon: <ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />,
      content: "Are you sure you want to delete this snapshot? This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const updatedSnapshots = availableSnapshots.filter((s) => s.id !== snapshotId);

          // Update company data
          await updateCompany(firstCompany().id, {
            target_snapshots: updatedSnapshots,
          });

          setAvailableSnapshots(updatedSnapshots);
          message.success("Snapshot deleted successfully");
        } catch (error) {
          console.error("Error deleting snapshot:", error);
          message.error("Failed to delete snapshot");
        }
      },
    });
  };

  // Add these modal renders before the return statement
  const renderSaveModal = () => (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <SaveOutlined />
          <span>Save Table</span>
        </div>
      }
      open={isSaveModalVisible}
      onOk={saveSnapshot}
      onCancel={() => {
        setIsSaveModalVisible(false);
        setSnapshotName("");
      }}
      okText="Save"
    >
      <Input placeholder="Enter save name" value={snapshotName} onChange={(e) => setSnapshotName(e.target.value)} style={{ marginTop: 16 }} />
    </Modal>
  );

  const renderLoadModal = () => (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <UploadOutlined />
          <span>Load Table</span>
        </div>
      }
      open={isLoadModalVisible}
      onCancel={() => setIsLoadModalVisible(false)}
      footer={null}
      width={600}
    >
      {availableSnapshots.length === 0 ? (
        <Empty description="No saved snapshots found" />
      ) : (
        <List
          dataSource={availableSnapshots}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => {
                    const newName = window.prompt("Enter new name for save:", item.name);
                    if (newName && newName.trim()) {
                      const updatedSnapshots = availableSnapshots.map((snapshot) => (snapshot.id === item.id ? { ...snapshot, name: newName.trim() } : snapshot));
                      updateCompany(firstCompany().id, {
                        target_snapshots: updatedSnapshots,
                      })
                        .then(() => {
                          setAvailableSnapshots(updatedSnapshots);
                          message.success("Save renamed successfully");
                        })
                        .catch((error) => {
                          console.error("Error renaming save:", error);
                          message.error("Failed to rename save");
                        });
                    }
                  }}
                >
                  Edit
                </Button>,
                <Button type="primary" onClick={() => loadSnapshot(item.id)}>
                  Load
                </Button>,
                <Button danger onClick={() => deleteSnapshot(item.id)}>
                  Delete
                </Button>,
              ]}
            >
              <List.Item.Meta title={item.name} description={`Saved on: ${new Date(item.savedAt).toLocaleString()}`} />
            </List.Item>
          )}
        />
      )}
    </Modal>
  );

  // Add new state for the Excel modal
  const [isExcelModalVisible, setIsExcelModalVisible] = useState(false);
  const [excelData, setExcelData] = useState([]);

  // Add these functions after other function declarations
  const openExcelEditor = () => {
    const headers = ["Title", ...Array.from({ length: 2050 - baselineYear + 1 }, (_, i) => (baselineYear + i).toString())];
    
    const data = [
      // Header row
      headers.map(header => ({ value: header, readOnly: true })),
      // Data rows
      ...tableData.map(row => {
        const isActualData = row.title === "Actual Data";
        const rowData = [{ value: row.title, readOnly: isActualData }];
        headers.slice(1).forEach(year => {
          const yearKey = `year_${year}`;
          const valueKey = getCurrentValueKey();
          const cellData = row[yearKey]?.[valueKey]?.value ?? "";
          rowData.push({
            value: cellData.toString(),
            readOnly: isActualData  // Make all cells in Actual Data row read-only
          });
        });
        return rowData;
      })
    ];

    setExcelData(data);
    setIsExcelModalVisible(true);
  };

  const handleExcelUpdate = (newData) => {
    const newTableData = [...tableData];

    // Skip header row (index 0)
    newData.slice(1).forEach((row, rowIndex) => {
      // Skip updates if this is the Actual Data row
      if (tableData[rowIndex].title === "Actual Data") {
        return;
      }

      // Update title (column 0)
      newTableData[rowIndex].title = row[0].value || "";

      // Update values (columns 1+)
      row.slice(1).forEach((cell, colIndex) => {
        const year = baselineYear + colIndex;
        const yearKey = `year_${year}`;
        const valueKey = getCurrentValueKey();

        // Skip if the cell is marked as read-only
        if (cell.readOnly) {
          return;
        }

        // Ensure the year key exists
        if (!newTableData[rowIndex][yearKey]) {
          newTableData[rowIndex][yearKey] = generateAllPossibleKeys();
        }

        // Update the value
        const newValue = cell.value === "" ? null : Number(cell.value);
        newTableData[rowIndex][yearKey][valueKey] = {
          ...newTableData[rowIndex][yearKey][valueKey],
          value: newValue
        };
      });
    });

    updateTableData(newTableData, "save");
  };

  // Add this modal render before the return statement
  const renderExcelModal = () => (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <EditOutlined />
          <span>Targets Spreadsheet Editor</span>
        </div>
      }
      open={isExcelModalVisible}
      onCancel={() => setIsExcelModalVisible(false)}
      width="90%"
      style={{ top: 20 }}
      footer={[
        <Button key="close" onClick={() => setIsExcelModalVisible(false)}>
          Close
        </Button>,
      ]}
    >
      <div style={{ height: "600px", overflow: "auto" }} key={JSON.stringify(excelData)}>
        <p>Any changes made here will be reflected in the actual table</p>
        <Spreadsheet
          data={excelData}
          onChange={handleExcelUpdate}
          columnLabels={false}
          rowLabels={false}
          className="targets-spreadsheet"
        />
      </div>
    </Modal>
  );

  // Add undo/redo handlers
  const handleUndo = () => {
    const previousState = StateManager.undo();
    if (previousState) {
      setTableData(previousState);
      setCanUndo(StateManager.currentIndex > 0);
      setCanRedo(true);
    }
  };

  const handleRedo = () => {
    const nextState = StateManager.redo();
    if (nextState) {
      setTableData(nextState);
      setCanUndo(true);
      setCanRedo(StateManager.currentIndex < StateManager.states.length - 1);
    }
  };

  return (
    <div className="targets-container">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card className="targets-card" bordered={false}>
            <Title level={3} style={{ marginBottom: 24, borderBottom: "1px solid #f0f0f0", paddingBottom: 16 }}>
              Sustrax Target Tracking
            </Title>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
              <Button onClick={openSettingsModal} icon={<SettingOutlined />}>
                Advanced
              </Button>
            </div>

            <Row gutter={[24, 16]} style={{ marginBottom: 24 }}>
              <Col xs={24} md={8}>
                <Card
                  size="small"
                  title={
                    <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <SettingOutlined />
                      <span>Settings</span>
                    </span>
                  }
                  bordered
                  style={{ height: "100%" }}
                >
                  <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                    {/* Data Settings Section */}
                    <div>
                      <Row align="middle" gutter={[8, 16]}>
                        <Col span={10}>
                          <Text>Electricity Reporting:</Text>
                        </Col>
                        <Col span={14}>
                          <Select value={valueType} onChange={setValueType} style={{ width: "100%" }} options={valueTypeOptions} />
                        </Col>

                        <Col span={10}>
                          <Text>Scope:</Text>
                        </Col>
                        <Col span={14}>
                          <Select value={valueScope} onChange={setScope} style={{ width: "100%" }} options={scopeOptions} />
                        </Col>

                        {/* Display Options Section */}
                        <Col span={10}>
                          <Text>Display Mode:</Text>
                        </Col>
                        <Col span={14}>
                          <Switch checked={displayMode === "percent"} onChange={handleDisplayModeChange} checkedChildren="Percent" unCheckedChildren="Value" />
                        </Col>

                        <Col span={10}>
                          <Text>Chart Settings:</Text>
                        </Col>
                        <Col span={14}>
                          <Button type="default" icon={<SettingOutlined />} onClick={openChartSettings} style={{ width: "100%" }}>
                            Customize
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={24} md={16}>
                <Card
                  size="small"
                  title={
                    <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <AntdTooltip title="Detail any assumptions, estimations and/or notes">
                        <EditOutlined />
                        <span>Target Details</span>
                      </AntdTooltip>
                    </span>
                  }
                  bordered
                  style={{ height: "100%" }}
                >
                  {renderNotesSection()}
                </Card>
              </Col>
            </Row>

            <div
              className="table-container"
              style={{
                overflowX: "auto",
                marginBottom: 24,
                borderRadius: "4px",
                border: "1px solid #f0f0f0",
              }}
            >
              <Table
                title={() => {
                  return (
                    <Space style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                      <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                          {scopeOptions.find((option) => option.value === valueScope)?.label || "Data"} table
                        </Typography.Title>
                        <Button type="primary" onClick={handleAddRow} icon={<PlusOutlined />}>
                          Add Target
                        </Button>
                        <Button icon={<EditOutlined />} onClick={openExcelEditor}>
                          Edit as spreadsheet
                        </Button>
                      </Space>
                      <Space>
                        <Button icon={<LuUndo2 />} onClick={handleUndo} disabled={!canUndo}></Button>
                        <Button icon={<LuRedo2 />} onClick={handleRedo} disabled={!canRedo}></Button>
                        <Button icon={<SaveOutlined />} onClick={handleSaveTable}>
                          Save Table
                        </Button>
                        <Button icon={<UploadOutlined />} onClick={handleLoadTable}>
                          Load Table
                        </Button>
                      </Space>
                    </Space>
                  );
                }}
                dataSource={tableData}
                columns={columns}
                pagination={false}
                scroll={{ x: "max-content" }}
                bordered
                size="middle"
                rowClassName={(record) => (isActualDataRow(record) ? "actual-data-row" : "")}
                style={{ borderRadius: "4px" }}
              />
            </div>

            <div
              className="chart-section"
              style={{
                padding: "24px",
                borderRadius: "4px",
                border: "1px solid #f0f0f0",
                background: "#fafafa",
                marginTop: "24px",
                minHeight: "600px",
              }}
            >
              <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 24 }}>
                {scopeOptions.find((option) => option.value === valueScope)?.label || "Data"} Targets Forecasting Chart
              </Typography.Title>

              {renderCharts()}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Add the chart settings modal */}
      {renderChartSettingsModal()}
      {renderSettingsModal()}
      {renderSaveModal()}
      {renderLoadModal()}
      {renderExcelModal()}
    </div>
  );
};

export default Targets;
