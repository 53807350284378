import { db } from "../setup";
import { firestoreStatic } from "../../static/firestore.static";
import { addDoc, collection } from "firebase/firestore";
import { getCurrentTime } from "../../helper/time";

export const CreateNotification = async (notification_content,target_user_id,type) => {
   const data = {
    content: notification_content,
    userId: target_user_id,
    timestamp: getCurrentTime(),
    isRead: false,
    type
   }
   const { id } = await addDoc(collection(db, firestoreStatic.notification), data)
   return id
};
