import React, { useState, useEffect } from "react";
import { getAuth, unlink, OAuthProvider } from "firebase/auth";

const AccountLinking = () => {
  const [user, setUser] = useState(null);
  const [linkedProviders, setLinkedProviders] = useState([]);
  const auth = getAuth();

  useEffect(() => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      setUser(currentUser);
      const providers = currentUser.providerData.map((provider) => provider.providerId);
      setLinkedProviders(providers);
    }
  }, [auth]);

  const handleUnlink = async (providerId) => {
    if (!user) return;

    try {
      await unlink(user, providerId);
      const updatedProviders = linkedProviders.filter((id) => id !== providerId);
      setLinkedProviders(updatedProviders);

      console.log(`Successfully unlinked: ${providerId}`);
    } catch (error) {
      console.error("Error unlinking provider:", error);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2>Account Linking</h2>
      {user ? (
        <div>
          <p>
            Signed in as: <strong>{user.email}</strong>
          </p>
          <h3>Linked Providers:</h3>
          {linkedProviders.length > 0 ? (
            false && (
              <ul>
                {linkedProviders.map((providerId) => (
                  <li key={providerId}>
                    {providerId}{" "}
                    <button
                      onClick={() => handleUnlink(providerId)}
                      style={{
                        marginLeft: "10px",
                        background: "red",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      Unlink
                    </button>
                  </li>
                ))}
              </ul>
            )
          ) : (
            <p>No linked providers found.</p>
          )}
        </div>
      ) : (
        <p>No user signed in.</p>
      )}
    </div>
  );
};

export default AccountLinking;
