import React, { useState } from "react";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import { selectResults } from "../../../redux/features/appSlice";
import { downloadXLSX } from "../../../helper/wodash";
import { selectUserDoc } from "../../../redux/features/userSlice";
import { Button, Divider, Card, Space } from "antd";
import { BeautifyData } from "../../../components/text/BeautifyData";
import styled from "styled-components";
import { DownloadOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import CombinedTable from "../../results/CombinedTable";
import { LuLoader } from "react-icons/lu";

const StyledCard = styled(Card)`
  margin: 20px;
  max-width: 800px;
  margin: 20px auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const LargeButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  width: 200px;
`;

export default function DownloadRawUserInformation() {
  const results = useSelector(selectResults);
  const userdoc = useSelector(selectUserDoc);
  const [viewResults, setViewResults] = useState(false);
  const [viewUserInfo, setViewUserInfo] = useState(false);
  const [showCombinedTable, setShowCombinedTable] = useState(false);

  const handleTableDownload = () => {
    downloadXLSX(`${userdoc?.firstName} ${userdoc?.lastName} - Raw Results Data.xlsx`, results);
  };

  const handleInfoDownload = () => {
    downloadXLSX(`${userdoc?.firstName} ${userdoc?.lastName} - User Information.xlsx`, [store.getState().user.doc]);
  };

  const canViewResults = results?.length <= 1000;

  return (
    <StyledCard title="Raw User Information">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {/* Results Section */}
        <Card
          title={
            <span>
              Raw Results Data
              {!canViewResults && results?.length > 0 && <span style={{ color: "#ff4d4f", marginLeft: "8px" }}>(Online view disabled - {results.length} rows exceed 1000 row limit)</span>}
            </span>
          }
        >
          <ButtonContainer>
            <LargeButton type="primary" icon={<DownloadOutlined />} onClick={handleTableDownload}>
              Download Raw Data
            </LargeButton>
            <LargeButton icon={<EyeOutlined />} onClick={() => setViewResults(!viewResults)} disabled={!canViewResults}>
              View Online
            </LargeButton>
          </ButtonContainer>
          {viewResults && canViewResults && <BeautifyData data={results} />}
        </Card>

        {/* User Info Section */}
        <Card title="User Information">
          <ButtonContainer>
            <LargeButton type="primary" icon={<DownloadOutlined />} onClick={handleInfoDownload}>
              Download User Info
            </LargeButton>
            <LargeButton icon={<EyeOutlined />} onClick={() => setViewUserInfo(!viewUserInfo)}>
              View Online
            </LargeButton>
          </ButtonContainer>
          {viewUserInfo && <BeautifyData data={store.getState().user.doc} />}
        </Card>
        <Card title="Combined results table">
            <LargeButton 
              type="primary" 
              onClick={() => setShowCombinedTable(true)}
            >
              Load
            </LargeButton>
            <Divider></Divider>
            {showCombinedTable && <CombinedTable onlyDisplayExportButton />}
        </Card>
      </Space>
    </StyledCard>
  );
}
