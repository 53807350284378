import { SendOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  message,
  Space,
  Typography,
  Select,
} from "antd";
import React, { useState } from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import { CreateTask } from "../../../firebase/tasks/create";
import { useSelector } from "react-redux";
import { firstCompany } from "../../../helper/attribute";
import { selectUsers } from "../../../redux/features/appSlice";

function AddTaskSidebar({ visible, setVisible, user, setTasks }) {
  const company = firstCompany()
  const allUsers = useSelector(selectUsers).filter(c_user => c_user.id !== user.id)
  const [data, setData] = useState({"created":{"by":user.id},"companyId":company.id})
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!data.description?.trim()) {
      return message.error("Please enter a description");
    }
    
    try {
      setLoading(true);
      const res = await CreateTask(data, true)
      setVisible(false);
      setTasks(res)
      message.success("Post created successfully");
    } catch (error) {
      message.error("Failed to create post");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Input.TextArea
        placeholder="What's on your mind?"
        rows={4}
        onChange={(e) => setData({...data, description: e.target.value})}
        style={{ fontSize: '15px' }}
      />

      <div>
        <Typography.Text type="secondary" style={{ marginBottom: '8px', display: 'block' }}>
          Assign to (Optional)
        </Typography.Text>
        <Select
          style={{ width: '100%' }}
          placeholder="Select a person"
          onChange={(value) => setData({...data, assignee: value})}
          value={data.assignee}
          options={allUsers.map(user => ({
            label: user.firstName + " " + user.lastName || user.email,
            value: user.id
          }))}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '16px' }}>
        <Button onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button 
          type="primary"
          loading={loading}
          onClick={handleSubmit}
          icon={<SendOutlined />}
        >
          Add
        </Button>
      </div>
    </Space>
  );
}

export default function AddTaskSidebarBox(props) {
  return (
    <ErrorBoundary>
      <AddTaskSidebar {...props} />
    </ErrorBoundary>
  );
}
