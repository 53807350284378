import { Button, Modal, Space, Table, Typography, Checkbox, Skeleton, Tooltip, Input, message } from "antd";
import {
  EditOutlined,
  MessageOutlined,
  ClockCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  UserOutlined,
  ArrowRightOutlined,
  SendOutlined,
  CommentOutlined,
  RightOutlined,
  XOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import AddTaskSidebarBox from "./add";
import { fetchTasks } from "../../../firebase/tasks/get";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../../redux/features/userSlice";
import { updateTask } from "../../../firebase/tasks/update";
import DeleteTask from "./delete";
import { createComment, getComments } from "../../../firebase/tasks/comment";
import { getCurrentTime, timeFormat } from "../../../helper/time";
import { designations, findUserById, getDesignation } from "../../../helper/attribute";
import dayjs from "dayjs";
import moment from "moment";

const { Text, Paragraph } = Typography;
const db = getFirestore();

const formatDate = (dateString) => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format("HH:mm, DD MMM YY") : moment(dateString, timeFormat).format("HH:mm, DD MMM YY");
};

export default function Tasks() {
  const user = useSelector(selectUserDoc);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modals, setModals] = useState({ edit: false, comments: false, add: false });
  const [editingId, setEditingId] = useState(null);
  const [editText, setEditText] = useState("");
  const [updatingTaskId, setUpdatingTaskId] = useState(null);
  const [movingTaskId, setMovingTaskId] = useState(null);
  const [commentInputs, setCommentInputs] = useState({});
  const [commentLoading, setCommentLoading] = useState({});
  const [taskComments, setTaskComments] = useState({});
  const [commentsVisible, setCommentsVisible] = useState({});
  const [editLoadingState, setEditLoadingState] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const tasksData = await fetchTasks();
      setTasks(tasksData);
      setLoading(false);
    };
    fetchData();
  }, []);

  const setModal = (type, value) => {
    setModals((prevModals) => ({ ...prevModals, [type]: value }));
  };

  const handleEdit = async (taskId) => {
    if (editingId === taskId) {
      try {
        setEditLoadingState(true);
        const res = await updateTask(taskId, { description: editText }, true);
        setTasks(res);
        setEditingId(null);
        setEditText("");
        message.success("Task updated successfully");
      } catch (error) {
        message.error("Failed to update task");
      } finally {
        setEditLoadingState(false);
      }
    } else {
      const task = tasks.find((t) => t.id === taskId);
      setEditText(task.description);
      setEditingId(taskId);
    }
  };

  const handleEditCancel = () => {
    setEditingId(null);
    setEditText("");
  };

  const updateComments = async (taskId) => {
    const comments = await getComments(taskId);
    setTaskComments((prev) => ({ ...prev, [taskId]: comments }));
  };

  const toggleCommentsVisibility = async (task) => {
    if (!commentsVisible[task.id]) {
      try {
        setCommentsLoading((prev) => ({ ...prev, [task.id]: true }));
        await updateComments(task.id);
      } catch (error) {
        message.error("Failed to load comments");
      } finally {
        setCommentsLoading((prev) => ({ ...prev, [task.id]: false }));
      }
    }
    setCommentsVisible((prev) => ({
      ...prev,
      [task.id]: !prev[task.id],
    }));
  };

  const handleCheckboxClick = (task) => {
    if (!task.checked?.at) {
      setMovingTaskId(task.id);
      setUpdatingTaskId(task.id);
      updateTask(task.id, { checked: { value: true, at: getCurrentTime(), by: user?.id } }, true)
        .then((updatedTasks) => {
          setTimeout(() => {
            const sortedTasks = updatedTasks.sort((a, b) => {
              return a.checked?.at === b.checked?.at ? 0 : a.checked?.at ? 1 : -1;
            });
            setTasks(sortedTasks);
            setMovingTaskId(null);
            setUpdatingTaskId(null);
          }, 500);
        })
        .catch(() => {
          message.error("Failed to update task status");
          setUpdatingTaskId(null);
          setMovingTaskId(null);
        });
    }
  };

  const handleCommentInputChange = (taskId, value) => {
    setCommentInputs((prev) => ({ ...prev, [taskId]: value }));
  };

  const isUserAllowedToEdit = (task, user) => {
    return task?.created?.by === user?.id || [designations.super_admin, designations.company_admin, designations.group_admin].includes(getDesignation(user));
  };

  const handleAddComment = async (taskId) => {
    try {
      setCommentLoading((prev) => ({ ...prev, [taskId]: true }));
      if (commentInputs[taskId] === undefined) return message.error("Comment cannot be empty");
      const commentData = {
        by: user.id, // Assuming user.id is the correct user ID
        content: commentInputs[taskId] || "",
      };
      const newTasks = await createComment(
        taskId,
        commentData,
        tasks.find((t) => t.id === taskId)
      );
      const sortedTasks = newTasks.sort((a, b) => {
        return a.checked?.at === b.checked?.at ? 0 : a.checked?.at ? 1 : -1;
      });
      await updateComments(taskId);
      setTasks(sortedTasks);
      setCommentInputs((prev) => ({ ...prev, [taskId]: "" })); // Clear the input for this task
      message.success("Comment added successfully");
    } catch (error) {
      message.error("Failed to add comment");
    } finally {
      setCommentLoading((prev) => ({ ...prev, [taskId]: false }));
    }
  };

  const columns = [
    {
      title: (
        <Space
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 0",
            width: "100%",
          }}
        >
          <Typography.Text strong style={{ fontSize: "24px" }}>
            Tasks
          </Typography.Text>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setModal("add", true)} size="large" style={{ borderRadius: "8px", height: "40px" }}>
            Add Task
          </Button>
        </Space>
      ),
      render: (task) => (
        <div
          style={{
            padding: "16px",
            marginBottom: "12px",
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            border: "1px solid #f0f0f0",
          }}
        >
          {/* Task Header */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginBottom: "8px" }}>
            <Space size={12} align="start">
              <Checkbox
                onClick={(e) => {
                  e.preventDefault();
                  handleCheckboxClick(task);
                }}
                checked={task?.checked?.at}
                disabled={loading || updatingTaskId !== null}
              />
              <div>
                {editingId === task.id ? (
                  <Space.Compact style={{ width: "100%", marginBottom: "8px" }}>
                    <Input
                      value={editText}
                      onChange={(e) => setEditText(e.target.value)}
                      style={{ width: "300px" }}
                    />
                    <Button 
                      type="primary" 
                      onClick={() => handleEdit(task.id)} 
                      loading={editLoadingState}
                      icon={<CheckOutlined />}
                    />
                    <Button 
                      onClick={handleEditCancel}
                      icon={"X"}
                    />
                  </Space.Compact>
                ) : (
                  <Typography.Text
                    style={{
                      fontSize: "15px",
                      display: "block",
                      fontWeight: 500,
                      marginBottom: "4px",
                      color: task?.checked?.at ? "#8c8c8c" : "inherit",
                      textDecoration: task?.checked?.at ? "line-through" : "none",
                    }}
                  >
                    {task?.description}
                  </Typography.Text>
                )}
                <Space size={4} direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                    Added by{" "}
                    {(() => {
                      const user = findUserById(task?.created?.by);
                      return `${user?.firstName} ${user?.lastName}`;
                    })()}{" "}
                    • {formatDate(task?.created?.at)}
                  </Typography.Text>
                  {task?.checked?.at && (
                    <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                      Completed by{" "}
                      {(() => {
                        const user = findUserById(task?.checked?.by);
                        return `${user?.firstName} ${user?.lastName}`;
                      })()}{" "}
                      • {formatDate(task?.checked?.at)}
                    </Typography.Text>
                  )}
                </Space>
              </div>
            </Space>

            <Space>
              {isUserAllowedToEdit(task, user) && (
                <>
                  <Button type="text" icon={<EditOutlined />} onClick={() => handleEdit(task.id)} />
                  <DeleteTask setTasks={setTasks} _={task} />
                </>
              )}
            </Space>
          </div>

          {/* Task Footer */}
          <div style={{ marginLeft: "28px" }}>
            <Button type="text" icon={<MessageOutlined />} onClick={() => toggleCommentsVisibility(task)} size="small">
              <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                {task?.comment || 0} Comments
              </Typography.Text>
            </Button>

            {/* Last Comment Preview */}
            {!commentsVisible[task.id] && taskComments[task.id]?.length > 0 && (
              <div
                style={{
                  marginTop: "8px",
                  display: "none",// more work needed
                  padding: "8px",
                  background: "#fafafa",
                  borderRadius: "4px",
                  border: "1px solid #f0f0f0",
                }}
              >
                <Space direction="vertical" size={2} style={{ width: "100%" }}>
                  <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                    Latest comment by{" "}
                    <Typography.Text strong style={{ fontSize: "13px" }}>
                      {(() => {
                        const lastComment = taskComments[task.id][taskComments[task.id].length - 1];
                        const user_n = findUserById(lastComment?.by);
                        return `${user_n?.firstName} ${user_n?.lastName}`;
                      })()}
                    </Typography.Text>
                    : {taskComments[task.id][taskComments[task.id].length - 1]?.content}
                  </Typography.Text>
                  <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                    {formatDate(taskComments[task.id][taskComments[task.id].length - 1]?.at)}
                  </Typography.Text>
                </Space>
              </div>
            )}

            {/* Comments Section */}
            {commentsVisible[task.id] && (
              <div style={{ marginTop: "8px" }}>
                <Space.Compact style={{ width: "100%", marginBottom: "8px" }}>
                  <Input
                    placeholder="Add a comment..."
                    size="middle"
                    style={{ fontSize: "13px" }}
                    onChange={(e) => handleCommentInputChange(task.id, e.target.value)}
                    value={commentInputs[task.id] || ""}
                  />
                  <Button type="primary" icon={<SendOutlined />} onClick={() => handleAddComment(task.id)} loading={commentLoading[task.id]} disabled={commentLoading[task.id]} />
                </Space.Compact>

                {commentsLoading[task.id] ? (
                  <Skeleton active avatar={false} paragraph={{ rows: 1 }} />
                ) : (
                  <div style={{ marginTop: "8px" }}>
                    {taskComments[task.id]?.map((comment, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "8px",
                          background: "#fafafa",
                          borderRadius: "4px",
                          marginBottom: "4px",
                        }}
                      >
                        <Space direction="vertical" size={2} style={{ width: "100%" }}>
                          <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                            <Typography.Text strong style={{ fontSize: "13px" }}>
                              {comment.content}
                            </Typography.Text>{" "}
                            added by{" "}
                            {(() => {
                              const user_n = findUserById(comment?.by);
                              return (
                                <b>
                                  {user_n?.firstName} {user_n?.lastName}
                                </b>
                              );
                            })()}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                            {formatDate(comment?.at)}
                          </Typography.Text>
                        </Space>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  if (tasks === null) return <Loading />;

  return (
    <div
      style={{
        padding: "24px",
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Table pagination={false} loading={loading} dataSource={tasks} columns={columns} rowKey={"id"} showHeader={true} style={{ background: "transparent" }} />

      <Modal title="Add New Task" open={modals.add} onCancel={() => setModal("add", false)} footer={null} width={600}>
        <AddTaskSidebarBox key={modals.add} user={user} visible={modals.add} setTasks={setTasks} setVisible={(v) => setModal("add", v)} />
      </Modal>
    </div>
  );
}
