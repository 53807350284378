import React from 'react';
import Draggable from 'react-draggable';
import { atom, useAtom } from 'jotai';
import { BeautifyData } from './components/text/BeautifyData';

// Create a global atom for controlling sidebar visibility
export const devSidebarAtom = atom(false);

const DevSidebar = () => {
  const [data, setData] = useAtom(devSidebarAtom);

  if (!data) return null;

  return (
    <Draggable handle=".handle">
      <div style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        width: '400px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        borderRadius: '8px',
        zIndex: 1000,
      }}>
        <div className="handle" style={{
          padding: '10px',
          background: '#f0f0f0',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          cursor: 'move',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <span>Developer Sidebar</span>
          <button 
            onClick={() => setData(false)}
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            ×
          </button>
        </div>
        <div style={{ padding: '16px', maxHeight: '80vh', overflowY: 'auto' }}>
          <div>DevSidebar</div>
          <BeautifyData data={data} />
        </div>
      </div>
    </Draggable>
  );
};

export default DevSidebar;