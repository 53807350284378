import { Alert, Button, Divider, Modal, Space } from "antd";
import React, { useState, useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import Select1 from "../../../components/select/select1";
import FrieghtCategory from "../../../components/input/forms/frieght";
import { AirportsDistance, FlightType } from "../../../helper/airport";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";
import { findFormText } from "../../../helper/attribute";
import { genPut } from "../helper/create";
import { AirportSelection } from "./flight";
import { FaPlane } from "react-icons/fa";

export const INPUTS = (data, setData, formname, modals, setModals) => [
  {
    key: "date",
    elem: DateInput,
    title: findFormText(formname, "date"),
    description: findFormText(formname, "date", "desc"),
  },
  {
    key: "category",
    elem: FrieghtCategory,
    options: filterFactors({ "Level 1": "Freighting goods" }, "Level 2"),
    title: findFormText(formname, "category"),
    description: findFormText(formname, "category", "desc"),
    setValue: (category) => {
      setData({
        ...data,
        category,
        type: null,
        uom: null,
        columntext: category === "Freight flights" ? "With RF" : null,
        level4: null,
        wholev: null,
      });
    },
  },
  {
    key: "calculate using airports",
    UIonly: true,
    elem: data.category === "Freight flights" && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          icon={<FaPlane />}
          className="blink-1"
          type="primary"
          style={{
            color: "darkgreen",
            border: "1px solid darkgreen",
            fontWeight: "700",
            background: "white",
            width: "100%",
            margin: "10px",
          }}
          onClick={() => setModals({ ...modals, airportselect: true })}
        >
          Calculate Using Airports
        </Button>
      </div>
    ),
  },
  {
    key: "type",
    elem: SelectSearch,
    hidden: !data.category,
    title: findFormText(formname, "type"),
    description: findFormText(formname, "type", "desc"),
    options: filterFactors({ "Level 1": "Freighting goods", "Level 2": data.category }, "Level 3"),
    set: {
      uom: null,
      wholev: null,
      columntext: null,
      level4: null,
      columntext: data?.category?.includes("HGV") ? "Average laden" : data.columntext,
    },
  },
  {
    key: "columntext",
    elem: SelectSearch,
    hidden: !data.category,
    title: findFormText(formname, "columntext"),
    description: findFormText(formname, "columntext", "desc"),
    options: filterFactors(
      {
        "Level 1": "Freighting goods",
        "Level 2": data.category,
        "Level 3": data.type,
      },
      "Column Text"
    ),
    set: { uom: null, wholev: null },
    hidden: !data.category || data.category === "Freight flights",
  },
  {
    key: "wholev",
    elem: SelectSearch,
    title: findFormText(formname, "wholev"),
    description: findFormText(formname, "wholev", "desc"),
    options: ["Yes", "No"],
    hidden: !data.category || !["Vans", "HGV (all diesel)", "HGV refrigerated (all diesel)"].includes(data.category),
    setValue: (val) => {
      setData({
        ...data,
        wholev: val,
        uom: "km",
        mass: null,
        mass_unit: null,
      });
    },
  },
  {
    key: "level4",
    elem: SelectSearch,
    title: findFormText(formname, "level4"),
    description: findFormText(formname, "level4", "desc"),
    options: filterFactors(
      {
        "Level 1": "Freighting goods",
        "Level 2": data.category,
        "Level 3": data.type,
      },
      "Level 4"
    ),
    set: { uom: null },
  },

  {
    key: "mass",
    elem: NumberInput,
    title: findFormText(formname, "mass"),
    description: findFormText(formname, "mass", "desc"),
    hidden: data.wholev === "Yes",
  },
  {
    key: "mass_unit",
    elem: SelectSearch,
    title: findFormText(formname, "mass_unit"),
    description: findFormText(formname, "mass_unit", "desc"),
    // nolabel: true,
    options: ["tonnes", "kg"],
    hidden: data.wholev === "Yes" || !data.mass,
  },

  {
    key: "distance",
    elem: NumberInput,
    title: findFormText(formname, "distance"),
    description: findFormText(formname, "distance", "desc"),
  },
  {
    key: "uom",
    elem: SelectSearch,
    title: findFormText(formname, "uom"),
    description: findFormText(formname, "uom", "desc"),
    hidden: !data.distance,
    options: ["km", "miles"],
  },
];

export default function Frieghting({ data, setData }) {
  const formname = "freighting";
  const [modals, setModals] = useState({ airportselect: false });
  const form = findForm(data.name);
  const { OWNERSHIP_TYPES, OUTSOURCED_STREAMS } = form;
  useEffect(() => {
    setData({
      ...data,
      mass_unit: data.mass_unit ? data.mass_unit : "tonnes",
      streamtype: data.streamtype ? data.streamtype : OUTSOURCED_STREAMS.Upstream,
    });
  }, []);

  return (
    <>
      {INPUTS(data, setData, formname, modals, setModals).map((input) => genPut(input, formname, data, setData))}

      <Modal
        open={modals.airportselect}
        title={findFormText(formname, "Calculate distance")}
        width={1000}
        onCancel={() => setModals({ ...modals, airportselect: false })}
        onOk={() => {
          setData({
            ...data,
            distance: AirportsDistance(data).toFixed(2),
            type: FlightType(data),
            uom: "km",
          });
          setModals({ ...modals, airportselect: false });
        }}
      >
        <AirportSelection data={data} setData={setData} formname={formname} />
      </Modal>

      <Divider />

      {genPut(
        {
          key: "ownership",
          elem: Select1,
          title: findFormText(formname, "ownership"),
          description: findFormText(formname, "ownership", "desc"),
          options: Object.values(OWNERSHIP_TYPES),
        },
        formname,
        data,
        setData
      )}

      {data.ownership === OWNERSHIP_TYPES.Outsourced && (
        <>
          {genPut(
            {
              key: "streamtype",
              elem: Select1,
              title: findFormText(formname, "streamtype"),
              description: findFormText(formname, "streamtype", "desc"),
              options: Object.values(OUTSOURCED_STREAMS),
            },
            formname,
            data,
            setData
          )}
        </>
      )}
    </>
  );
}
