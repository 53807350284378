import { ConfigProvider, theme } from "antd";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import checksBeforeLogin from "./Checks/BeforeLogin";
import ErrorBoundary from "./ErrorBoundary";
import Login from "./auth/login";
import AllApp from "./components/AllApp/AllApp";
import Loading from "./components/loading";
import { fetchAppConfig } from "./firebase/appconfig/get";
import { fetchCompanies } from "./firebase/company/get";
import { fetchResults } from "./firebase/data/get";
import { fetchGroups } from "./firebase/group/get";
import { fetchSites } from "./firebase/site/get";
import { fetchEmissionFactors } from "./firebase/storage/assets";
import { fetchUsers, getUserDoc } from "./firebase/user/get";
import { fetchReportBugs } from "./firebase/userhelp/reportbugs/get";
import { designations, getCurrentUserDesignation, isConsultant, isSuperAdmin } from "./helper/attribute";
import { selectCompanies, selectSites, selectState, selectTheme, selectUsers, setCompanies, setGroups, setResults, setSites, setUsers } from "./redux/features/appSlice";
import { selectUserDoc, setUserDoc } from "./redux/features/userSlice";
import routes from "./routes";
import "./styles/animations.css";
import "./styles/antd.css";
import store from "./redux/store";
import { checksAfterLogin } from "./Checks/AfterLogin";
import affiliateChecks from "./affiliates/checkAfterLogin";
import { catcher } from "./firebase/util";
import DevSidebar from "./DevSidebar";

const App = () => {
  const content = useRoutes(routes());
  const state = useSelector(selectState);
  const userDoc = useSelector(selectUserDoc);
  const users = useSelector(selectUsers);
  const companies = useSelector(selectCompanies);
  const sites = useSelector(selectSites);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Before Login
    checksBeforeLogin();
  }, []);
  useEffect(() => {
    catcher(
      async () => {
        await affiliateChecks();
      },
      { setLoading, success_msg: "" }
    );
  }, []);
  useEffect(() => {
    if (state === "trial") {
      dispatch(
        setUserDoc({
          firstName: "trial",
          lastName: "trial",
          id: "trial",
          phone: "trial",
          admin_of_sites: [{ id: "trial" }],
        })
      );
      return fetchData();
    }
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      dispatch(setUserDoc(undefined));
      console.timeEnd("Loggin Time");
      try {
        if (user === null) throw "Relogin Please";
        const token = await getIdToken(user);
        const userDoc = await getUserDoc();

        dispatch(setUserDoc({ ...userDoc, token }));
        await fetchData();
        if (getCurrentUserDesignation() === designations.super_admin) {
          fetchSuperAdminData();
        }
      } catch (err) {
        console.log(err);
        // if the error is user does not exist, means that the provider is different and we need to create registeration for that user.
        if (err === "not-fully-registered") {
          dispatch(setUserDoc(null));
          navigate("/login?not-fully-registered=true");
        } else {
          dispatch(setUserDoc(null));
          navigate("/login");
        }
      }
    });
  }, []);
  const fetchData = async (filterCompanies = false) => {
    await fetchAppConfig();
    if (isSuperAdmin()) {
      //  && window.confirm("Fetch companies and sites along with admin config?") === false
      // imp: question moved to super admin section.
      store.dispatch(setCompanies([]));
      store.dispatch(setSites([]));
      store.dispatch(setUsers([]));
      store.dispatch(setGroups([]));
    } else {
      if (state === "trial") {
        store.dispatch(
          setCompanies([
            {
              id: "trial",
              title: "trial",
              plan: "free",
              createdAt: "12/12/2022",
              reporting_year_starting_month: "January",
            },
          ])
        );
        store.dispatch(
          setSites([
            {
              id: "trial",
              title: "trial",
              companyId: "trial",
              country: "United Kingdom",
              createdAt: "12/12/2022",
              index: 1,
            },
          ])
        );
        store.dispatch(setUsers([]));
      } else {
        await fetchGroups();
        await fetchCompanies(filterCompanies);
        await fetchSites();
        await fetchUsers();
      }
    }
    await checksAfterLogin();

    if (location.pathname === "/" || location.pathname === "") navigate("/dashboard");
    fetchEmissionFactors();
    if (isSuperAdmin() || isConsultant()) {
      store.dispatch(setResults([]));
    } else {
      fetchResults();
    }
  };

  const fetchSuperAdminData = () => {
    fetchReportBugs();
  };
  if (loading) return <Loading title="Loading Sustrax" />;
  if (userDoc?.requireTwofa && userDoc?.authCode) {
    return <Login type={"auth"} />;
  }
  if (userDoc === null) return <Login />;
  if (users === undefined) {
    return <Loading title="Loading Users" type="skeleton" />;
  } else if (companies === undefined) {
    return <Loading title="Loading Companies" type="skeleton" />;
  } else if (sites === undefined) {
    return <Loading title="Loading Sites" type="skeleton" />;
  }
  return (
    <div
      style={{
        minHeight: "100vh",
      }}
    >
      {content}
      <AllApp refetchData={fetchData} />
    </div>
  );
};

export default function AppWrap() {
  const { isDarkMode } = useSelector(selectTheme);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#298f05",
        },
        algorithm: !isDarkMode ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      <ErrorBoundary>
        <App />
        <DevSidebar />
      </ErrorBoundary>
    </ConfigProvider>
  );
}
