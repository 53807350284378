import { doc, updateDoc } from "firebase/firestore";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";

export const updateNotification = async (id, data) => {

  if (!id || !typeof data === "object") return "no";
  await updateDoc(doc(db, firestoreStatic.notification, id), data);
  
 }

 export const setReadNotification = async (id) => {
  await updateNotification(id, {
    isRead: true
  })
 }