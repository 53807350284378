import React, { useEffect, useState } from "react";
import { Badge, Dropdown, List, Avatar } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { notificationIcons, notificationRedirects, notificationTitles } from "../../static/notification.static";
import { fetchNotifications } from "../../firebase/notifications/get";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../redux/features/userSlice";
import { setReadNotification } from "../../firebase/notifications/update";
import { useNavigate, useLocation } from "react-router-dom";


const formatTimestamp = (timestamp) => {
  const now = new Date();
  const notificationDate = new Date(timestamp);
  
  // Reset time portions to compare just the dates
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const notifDay = new Date(notificationDate.getFullYear(), notificationDate.getMonth(), notificationDate.getDate());
  
  // Get difference in days
  const diffInDays = Math.floor((today - notifDay) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    // Today - show time only
    return notificationDate.toLocaleTimeString([], { 
      hour: '2-digit',
      minute: '2-digit'
    });
  } else if (diffInDays === 1) {
    // Yesterday
    return 'Yesterday';
  } else {
    // More than 1 day ago - show date
    return notificationDate.toLocaleDateString([], {
      month: 'short',
      day: 'numeric'
    });
  }
};

const NotificationBell = () => {
   const [notificationsData, setNotificationsData] = useState(null);
   const currentUser = useSelector(selectUserDoc)
   const [unreadCount, setUnreadCount] = useState(null);
   const navigate = useNavigate();
   const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchNotifications(currentUser.id);
      
      const sortedNotifications = res?.sort((a, b) => {
        // Convert timestamps to numbers for comparison
        const timeA = new Date(a.timestamp).getTime();
        const timeB = new Date(b.timestamp).getTime();
        return timeB - timeA;  // Descending order (newest first)
      });
      
      setNotificationsData(sortedNotifications);
      setUnreadCount(res?.filter(n => !n.isRead).length);
    };
    
    fetchData();
  }, []);

  const handleClearNotifications = async () => {
    // Update all notifications in Firebase
    const unreadNotifications = notificationsData?.filter(n => !n.isRead) || [];
    await Promise.all(unreadNotifications.map(n => setReadNotification(n.id,false)));
    
    // Update local state
    setNotificationsData(notificationsData?.map(n => ({ ...n, isRead: true })));
    setUnreadCount(0);
  };

  const handleNotificationClick = async (item,redirect=true) => {
    if (!item.isRead) {
      await setReadNotification(item.id);
      setNotificationsData(notificationsData?.map(n => 
        n.id === item.id ? { ...n, isRead: true } : n
      ));
      setUnreadCount(prev => Math.max(0, prev - 1));
    }
    
    // Use the found notification's type for redirect
    if (item && redirect) {
      const redirectPath = notificationRedirects[item.type];
      if (location.pathname !== redirectPath) {
        navigate(redirectPath);
      }
    }
  };

  const notificationList = (
    <div style={{ 
      width: 380,
      maxHeight: 400, 
      overflowY: "auto", 
      background: "white",
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12)"
    }}>
      {notificationsData?.length > 0 ? (
        <>
          <List
            itemLayout="horizontal"
            dataSource={notificationsData}
            renderItem={(item) => (
              <List.Item
                onClick={() => handleNotificationClick(item)}
                style={{
                  padding: "12px 8px",
                  transition: "background-color 0.3s",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: item.isRead ? "transparent" : "rgba(24, 144, 255, 0.06)",
                }}
                className="notification-item"
              >
                <List.Item.Meta
                  avatar={
                    <Badge dot={!item.isRead}>
                      <Avatar icon={notificationIcons[item.type] || notificationIcons.default} />
                    </Badge>
                  }
                  title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ 
                        fontWeight: item.isRead ? 400 : 600,
                        color: item.isRead ? '#666' : '#000'
                      }}>
                        {notificationTitles[item.type]}
                      </span>
                      <span style={{ 
                        fontSize: '12px',
                        color: '#8c8c8c'
                      }}>
                        {formatTimestamp(item.timestamp)}
                      </span>
                    </div>
                  }
                  description={
                    <span style={{ 
                      color: item.isRead ? "#8c8c8c" : "#666"
                    }}>
                      {item.content}
                    </span>
                  }
                />
              </List.Item>
            )}
          />
          <div style={{ 
            textAlign: "center", 
            marginTop: 16,
            borderTop: "1px solid #f0f0f0",
            paddingTop: 12
          }}>
            <a 
              onClick={handleClearNotifications}
              style={{
                color: "#1890ff",
                cursor: "pointer",
                fontSize: "14px"
              }}
            >
              Mark all as read
            </a>
          </div>
        </>
      ) : (
        <p style={{ 
          textAlign: "center",
          color: "#999",
          margin: "16px 0"
        }}>
          No new notifications
        </p>
      )}
    </div>
  );

  return (
    <Dropdown 
      overlay={notificationList} 
      trigger={["click"]}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Badge 
        dot={unreadCount > 0}
        offset={[-3, 3]}
      >
        <BellOutlined 
          style={{ 
            fontSize: 20, 
            cursor: 'pointer',
            color: '#595959'
          }} 
        />
      </Badge>
    </Dropdown>
  );
};

export default NotificationBell;