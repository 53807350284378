import { collection, getDocs, query, where } from "firebase/firestore";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";


export const fetchNotifications = async (user_id) => {
  const collectionRef = collection(db, firestoreStatic.notification);
  let notifications = [];

  const q = query(
    collectionRef,
    where(
      "userId",
      "==",
      user_id
    )
  );

  const docs = await getDocs(q);
  const batchNotifications = docsWithIds(docs);
  notifications.push(...batchNotifications);
  console.log(notifications)
  const sortedNotifications = notifications.sort((a, b) => {
    return a.timestamp === b.timestamp ? 0 : a.timestamp ? 1 : -1;
  }); 
  return sortedNotifications;
}