import React, { useEffect } from "react";
import paper_weights from "../../../assets/paper_weights.json";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";

import computingData from "../../../assets/computingData.json";
import { Select } from "antd";
import { removeDuplicatesFast } from "../../../helper/wodash";

export const filterFactorsComputing = (filters, requiredColumn) => {
  return removeDuplicatesFast(
    computingData
      .filter((row) => {
        return Object.keys(filters).every((key) => {
          return row[key] === filters[key];
        });
      })
      .map((row) => {
        return row[requiredColumn];
      })
  );
};

export const INPUTS = (data, setData) => {
  const form = findForm(data.name);
  console.log(data, "computing data");
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: SelectSearch,
      key: "type",
      setValue: (type) => {
        setData({
          ...data,
          type,
          manufacturer: null,
          model: null,
        });
      },
      options: filterFactorsComputing({}, "Level 1"),
    },
    {
      elem: SelectSearch,
      key: "manufacturer",
      hidden: !data.type,
      setValue: (manufacturer) => {
        setData({
          ...data,
          manufacturer,
          model: null,
        });
      },
      options: filterFactorsComputing({ "Level 1": data.type }, "Level 2"),
      excel_options:filterFactorsComputing({}, "Level 2")
    },
    {
      elem: SelectSearch,
      key: "model",
      hidden: !data.type || !data.manufacturer,
      options: filterFactorsComputing({ "Level 1": data.type, "Level 2": data.manufacturer }, "Level 3"),
      excel_options: filterFactorsComputing({}, "Level 3"),
    },
    {
      elem: NumberInput,
      key: "amount",
    },

  ];
};

export default function Computing({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    setData({ ...data });
  }, []);

  return <div>{INPUTS(data, setData).map((input) => genPut(input, formname, data, setData))}</div>;
}
