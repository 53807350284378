import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { selectUserDoc } from "../redux/features/userSlice";
import { isSuperAdmin } from "../helper/attribute";
import { allRoutes } from "../routes";
import { routePath } from "../static/routes.static";
import Loading from "../components/loading";
import axios from "axios";
import { useAtom } from "jotai";
import { consultantAtom } from "../pages/consultants/atom/atoms";
import { message, Modal, Form, Input, Button } from "antd";
import { catcher } from "../firebase/util";

function AuthGuard({ children }) {
  const user = useSelector(selectUserDoc);
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasAccess, setHasAccess] = React.useState(false);
  const [, setConsultant] = useAtom(consultantAtom);
  const [loginModalVisible, setLoginModalVisible] = React.useState(false);
  const [loginForm] = Form.useForm();
  const [loginLoading, setLoginLoading] = React.useState(false);

  const handleGetConsultant = async (values) => {
    setLoginLoading(true);
    await catcher(
      async () => {
        const { email, password } = values;
        const { data } = await axios.post(`${process.env.REACT_APP_BE}/api/user/login/consultant`, { username: email, password });
        if (!data.consultant) {
          message.error("Invalid credentials");
          return;
        }
        setConsultant(data);
        localStorage.setItem("consultant_token", data.token);
        setHasAccess(true);
        setIsLoading(false);
        setLoginModalVisible(false);
      },
      { loading_msg: "Starting secure consultant session..." }
    );
    setLoginLoading(false);
  };

  console.log({ user, isLoading, hasAccess, p: location.pathname });

  React.useEffect(() => {
    const checkAccess = async () => {
      if (!user) {
        return;
      }

      // Find current route by matching base path
      const currentPath = location.pathname.split("/")[1];
      const currentRoute = allRoutes.find((route) => {
        const routeBasePath = route.path.split("/")[1] || route.path.split("/")[0];
        return routeBasePath === currentPath;
      });

      console.log(currentRoute, "current route");

      if (!currentRoute || (currentRoute.super_admin && !isSuperAdmin(user))) {
        setHasAccess(false);
        setIsLoading(false);
        return;
      }

      // Check consultant permission if needed
      if (currentRoute.consultant) {
        try {
          const consultant_token = localStorage.getItem("consultant_token");
          if (!consultant_token) {
            setHasAccess(false);
            setIsLoading(false);
            setLoginModalVisible(true);
            throw new Error("Consultant token not found");
          }
          const { data } = await axios.post(`${process.env.REACT_APP_BE}/api/user/get_by_token`, { token: consultant_token });

          if (data.consultant) {
            setConsultant(data);
            setHasAccess(true);
            setIsLoading(false);
          } else {
            setHasAccess(false);
            setIsLoading(false);
            setLoginModalVisible(true);
            return;
          }
        } catch (error) {
          console.error("Error verifying consultant token:", error);
          message.error("Session expired. Please login again.");
          setHasAccess(false);
          setIsLoading(false);
          setLoginModalVisible(true);
          return;
        }
      } else {
        setHasAccess(true);
        setIsLoading(false);
      }
    };

    checkAccess();
  }, [user, location.pathname, setConsultant]);

  if (isLoading) return <Loading />;

  if (loginModalVisible) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: "#f0f2f5" }}>
        <Modal title="Consultant Login" open={loginModalVisible} footer={null} closable={false} maskClosable={false}>
          <p>This is a temporary login and won't be saved after session is terminated. In order to save your login for multiple sessions, please logout and login to this account from the consultant account.</p>
          <Form form={loginForm} layout="vertical" onFinish={handleGetConsultant}>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter your email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please enter your password" }]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loginLoading} block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  if (!hasAccess) return <Navigate to={routePath.notFound} replace />;
  return <>{children}</>;
}

export default AuthGuard;
