import { Modal, Checkbox, Input, Tag, theme, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import FakeLabel from "./select";

export default function SelectMultipleCheckboxModal({ title, placeholder = "Select items", value = [], handleChange, options = [], nolabel = false, ...more }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState(value);

  const { token } = theme.useToken();

  // Filter options based on search text
  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  // Handle checkbox changes
  const onCheckboxChange = (checkedValue) => {
    setSelectedItems(checkedValue);
  };

  // Handle modal OK button
  const handleOk = () => {
    handleChange(selectedItems);
    setIsModalOpen(false);
  };

  const selectedLabels = options
    .filter((opt) => value.includes(opt.value))
    .map((opt) => opt.label)
    .join(", ");

  const elem = (
    <div>
      <div
        style={{
          border: "1px solid #d9d9d9",
          padding: "4px 11px",
          borderRadius: "0px",
          cursor: "pointer",
          borderColor: token.colorPrimary,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => setIsModalOpen(true)}
      >
        <Typography.Text type={value.length ? undefined : "secondary"}>
          {value.length ? selectedLabels : placeholder}
        </Typography.Text>
        <EditOutlined style={{ color: token.colorPrimary }} />
      </div>

      <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)} width={600}>
        <Input.Search placeholder="Search options..." onChange={(e) => setSearchText(e.target.value)} style={{ marginBottom: 16 }} />
        <Checkbox.Group options={filteredOptions} value={selectedItems} onChange={onCheckboxChange} style={{ display: "flex", flexDirection: "column", gap: "8px" }} />
      </Modal>
    </div>
  );

  if (nolabel) return elem;
  return <FakeLabel {...more} title={title} elem={elem}></FakeLabel>;
}
