import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { T1 } from "../../../../appconfig/texts";
import currencies from "../../../../assets/currencies.json";
import NumberInput from "../../../../components/input/number";
import Loading from "../../../../components/loading";
import antdPrompt from "../../../../components/prompts/antdPrompt";
import SelectSearch from "../../../../components/select/SearchSelect";
import SimpleSelect from "../../../../components/select/simpleselect";
import { updateCompany } from "../../../../firebase/company/update";
import { catcher } from "../../../../firebase/util";
import { findCompanies, firstCompany } from "../../../../helper/attribute";
import { emissionFactorsYears } from "../../../../helper/emissionfactors";
import { getAllParamsFromLocation } from "../../../../helper/loc";
import { getCurrentYear } from "../../../../helper/time";
import { setCompanies } from "../../../../redux/features/appSlice";
import { genPut } from "../../../form/helper/create";
import { useMediaQuery } from "react-responsive";
import useSize from "../../../../hooks/useSize";
import { getCurrentPlan } from "../../../../helper/plans";
//TYPOGRAPHY
/**
 * Employees (FTE) - Number (fte)
 * Turnover (Millions) - Number (to)
 * Currency (Dropdown) - Dropdown (curr)
 * Additional Metric Name - String (aMetricName)
 * Additional Metric No. - Number (aMetricNo)
 */

function IntensityMetricYear({ year, _ }) {
  const [data, setData] = useState(undefined);
  const [oldData, setOldData] = useState(undefined);
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  let isUpdated = JSON.stringify(oldData) !== JSON.stringify(data);
  const initialMetricValue = _?.["metrics"]?.["aMetricName"] || "";
  const handleMetricUpdate = () => {
    catcher(
      async () => {
        await antdPrompt(
          "Additional Metric Name",
          "",
          "",
          async (value) => {
            handleUpdate(value);
          },
          initialMetricValue
        );
      },
      { success_msg: "", setLoading: () => {} }
    );
  };

  useEffect(() => {
    let metrics = JSON.parse(JSON.stringify(_["metrics"] || {}));
    if (!metrics[year]) metrics[year] = {};
    setOldData(JSON.parse(JSON.stringify(metrics[year])));
    setData(JSON.parse(JSON.stringify(metrics[year])));
  }, [year]);
  const handleUpdate = (aMetricName) => {
    catcher(
      async () => {
        const metrics = _["metrics"] || {};
        const updatedMetricData = {
          metrics: {
            ...metrics,
            [year]: { ...data },
          },
        };
        if (typeof aMetricName === "string") {
          updatedMetricData.metrics = { ...metrics, aMetricName };
        }

        await updateCompany(_.id, updatedMetricData);
        dispatch(setCompanies(findCompanies().map((company) => (company.id === _.id ? { ...company, ...updatedMetricData } : company))));
        setOldData(JSON.parse(JSON.stringify(data)));
      },
      { setLoading: setUpdating }
    );
  };
  if (data === undefined) return <Loading />;
  const INPUTS = [
    {
      key: "fte",
      title: <T1 pos="company.intensityMetrics.employeesFte" />,
      elem: NumberInput,
      description: " ",
    },
    {
      key: "to",
      title: <T1 pos="company.intensityMetrics.turnover" />,
      elem: NumberInput,
      description: " ",
    },
    {
      key: "curr",
      title: <T1 pos="company.intensityMetrics.currency" />,
      elem: SelectSearch,
      description: " ",
      options: currencies.map((c) => c.name),
    },
    {
      key: "aMetricNo",
      title: (
        <>
          {<T1 pos="company.intensityMetrics.additionalMetricNo" />} {initialMetricValue}
        </>
      ),
      elem: NumberInput,
      description: " ",
    },
  ];
  return (
    <div>
      {INPUTS.map((input) => genPut(input, "", data, setData))}
      <div onClick={handleMetricUpdate}>
        {<T1 pos="company.intensityMetrics.editMetricName" />} <EditOutlined />
      </div>
      <Divider />
      <Button loading={updating} type="primary" disabled={!isUpdated} onClick={handleUpdate}>
        Update
      </Button>
      {isUpdated && (
        <Button type="ghost" onClick={() => setData({ ...oldData })}>
          Cancel
        </Button>
      )}
    </div>
  );
}

export default function IntensityMetrics({ _ = firstCompany(), allowChangeYear = true, ...more }) {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();

  // const years = emissionFactorsYears();
  const years = [2019, 2020, 2021, 2022, 2023, 2024, 2025];
  const [year, setYear] = useState(getAllParamsFromLocation().year || getCurrentYear());
  return (
    <Card>
      <Typography.Title style={{ display: getCurrentPlan() === "free" ? "none" : "", fontSize: isExtraSmall ? "18px" : "24px" }}>
        {getCurrentPlan() === "DC" ? (
          ""
        ) : (
          <>
        {<T1 pos="company.intensityMetrics.text" />}
            {" "}for{""}
            <span style={{ color: "green" }}>
              {allowChangeYear ? (
                <span className="selector1">
                  <SimpleSelect
                    elemOnly
                    value={year}
                    setValue={setYear}
                    options={years.map((value) => ({
                      label: value,
                      value,
                    }))}
                  />
                </span>
              ) : (
                year
              )}
            </span>
          </>
        )}
      </Typography.Title>
      <IntensityMetricYear key={year} year={year} _={_} />
    </Card>
  );
}
