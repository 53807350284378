import { doc, updateDoc } from "firebase/firestore";
import { getCurrentDate } from "../../helper/time";
import { objDontHave, valuesNotNull } from "../../helper/wodash";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import store from "../../redux/store";
import { setUsers } from "../../redux/features/appSlice";
import { notifyIfOffline } from "../../offline";
import { fetchTasks } from "./get";


const beforeTaskUpdate = (data) => {
  notifyIfOffline();
  const dontHv = valuesNotNull(data, ["description"]);
  if (dontHv) throw "Please add " + dontHv;
  data.updatedAt = getCurrentDate();
  return data;
};

export const updateTask = async (id, data,shouldFetch = true) => {

  if (!id || !typeof data === "object") return "no";
  data = beforeTaskUpdate(data);
  await updateDoc(doc(db, firestoreStatic.tasks, id), data);
  if (shouldFetch){
   const res = await fetchTasks()
   return res
 }
};