import { isOfflineUser } from "../offline";
import store from "../redux/store";
import { isGroupAdmin, isSuperAdmin } from "./attribute";

export const plans = {
  free: {
    canBulkUploadForms: 0,
    canAddFileToForm: 0,
    canViewResults: 0,
    canViewYearsAnalysis: 1,
    canViewMonthsAnalysis: 0,
    canViewSitesAnalysis: 0,
    canViewGHGAnalysis: 0,
    canViewTagsAnalysis: 0,
    canSelectCustomDate: 0,
    canViewCompanyDetails: 0,
    canViewSiteDetails: 0,
    canViewCompanies: 0,
    canViewSites: 0,
    canViewUsers: 0,
    canViewCombinedResults: 1,
    canViewUpgradePage: 1,
    canViewAnalysisOffset: 1,
    canExportCombinedResults: 0,
    form_fuel: 2,
    form_electricity: 1,
    form_cars: 5,
    form_freighting: 0,
    form_waste: 0,
    form_bulk_materials: 0,
    form_flight: 5,
    form_other_travels: 4,
    form_commuting: 0,
    form_hotel: 0,
    form_refrigerator: 1,
    form_water: 0,
    form_paper: 0,
    form_home_workers: 1,
    form_spending: 0,
    form_product: 0,
    form_additional: 0,
  },
  pro: {
    canBulkUploadForms: 1,
    canAddFileToForm: 1,
    canViewResults: 1,
    canViewYearsAnalysis: 1,
    canViewMonthsAnalysis: 1,
    canViewSitesAnalysis: 1,
    canViewGHGAnalysis: 1,
    canViewTagsAnalysis: 1,
    canSelectCustomDate: 1,
    canViewCompanyDetails: 1,
    canViewSiteDetails: 1,
    canViewCompanies: 1,
    canViewSites: 1,
    canViewUsers: 1,
    canViewCombinedResults: 1,
    canViewUpgradePage: 0,
    canViewAnalysisOffset: 0,
    canExportCombinedResults: 0,
    form_fuel: -1,
    form_electricity: -1,
    form_cars: -1,
    form_freighting: -1,
    form_waste: -1,
    form_bulk_materials: -1,
    form_flight: -1,
    form_other_travels: -1,
    form_commuting: -1,
    form_hotel: -1,
    form_refrigerator: -1,
    form_water: -1,
    form_paper: -1,
    form_home_workers: -1,
    form_spending: -1,
    form_product: -1,
    form_additional: -1,
  },
  basic: {
    canBulkUploadForms: 0,
    canAddFileToForm: 0,
    canViewResults: 0,
    canViewYearsAnalysis: 1,
    canViewMonthsAnalysis: 0,
    canViewSitesAnalysis: 0,
    canViewGHGAnalysis: 0,
    canViewTagsAnalysis: 0,
    canSelectCustomDate: 0,
    canViewCompanyDetails: 0,
    canViewSiteDetails: 0,
    canViewCompanies: 0,
    canViewSites: 0,
    canViewUsers: 0,
    canViewCombinedResults: 1,
    canViewUpgradePage: 1,
    canViewAnalysisOffset: 1,
    canExportCombinedResults: 0,
    form_fuel: 1,
    form_electricity: 1,
    form_cars: 1,
    form_freighting: 1,
    form_waste: 1,
    form_bulk_materials: 1,
    form_flight: 1,
    form_other_travels: 1,
    form_commuting: 1,
    form_hotel: 1,
    form_refrigerator: 1,
    form_water: 1,
    form_paper: 1,
    form_home_workers: 1,
    form_spending: 1,
    form_product: 1,
    form_additional: 0,
  },
  premium: {
    canBulkUploadForms: 0,
    canAddFileToForm: 0,
    canViewResults: 0,
    canViewYearsAnalysis: 1,
    canViewMonthsAnalysis: 0,
    canViewSitesAnalysis: 0,
    canViewGHGAnalysis: 0,
    canViewTagsAnalysis: 0,
    canSelectCustomDate: 0,
    canViewCompanyDetails: 0,
    canViewSiteDetails: 0,
    canViewCompanies: 0,
    canViewSites: 0,
    canViewUsers: 0,
    canViewCombinedResults: 1,
    canViewUpgradePage: 1,
    canViewAnalysisOffset: 1,
    canExportCombinedResults: 0,
    form_fuel: 1,
    form_electricity: 1,
    form_cars: 1,
    form_freighting: 1,
    form_waste: 1,
    form_bulk_materials: 1,
    form_flight: 1,
    form_other_travels: 1,
    form_commuting: 1,
    form_hotel: 1,
    form_refrigerator: 1,
    form_water: 1,
    form_paper: 1,
    form_home_workers: 1,
    form_spending: 1,
    form_product: 1,
    form_additional: 1,
  },
};
export const getCurrentPlan = () => {
  if (isOfflineUser()) return "offline";
  if (isGroupAdmin()) {
    const groups = store?.getState()?.app?.groups;
    if (groups?.length >= 1) {
      return groups[0]?.plan || "pro";
    }
    return "pro";
  }
  const companies = store.getState().app.companies;
  //current methodology: if more than one companies its actually a super admin
  if (companies?.length === 1) {
    return companies[0]?.plan || "pro";
  }
  return "pro";
};
export const getCurrentPlanExpiry = () => {
  const companies = store.getState().app.companies;
  //current methodology: if more than one companies its actually a super admin
  if (companies?.length === 1) {
    return companies[0]?.plan_expiry;
  }
  return "";
};

export const planValue = (key, plans = store.getState()?.config?.plans) => {
  if (!plans) return 1;
  let value = plans[getCurrentPlan()]?.[key];
  if (value === undefined) value = 1;
  //
  return value;
};

export const getPlanTitles = () => {
  return Object.keys(store.getState().config.plans);
};

export const isSustraxLite = (arr = ["free", "basic", "premium"]) => {
  return arr.includes(getCurrentPlan());
};

export const isSustraxAnnual = (arr = ["free", "basic", "premium", "ma", "DC"]) => {
  return arr.includes(getCurrentPlan());
};

export const isSustrax = (whatArr = []) => {
  return whatArr.includes(getCurrentPlan());
};
