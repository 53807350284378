import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography, message, Space } from "antd";
import React, { useState } from "react";
import { deleteTask } from "../../../firebase/tasks/delete";

export default function DeleteTask({ _: task, setTasks }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteTask(task.id, true);
      setTasks(res);
      message.success("task deleted successfully");
      setVisible(false);
    } catch (error) {
      message.error("Failed to delete task");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button 
        type="text"
        icon={<DeleteOutlined />}
        onClick={() => setVisible(true)}
        style={{ color: '#666' }}
      />
      
      <Modal
        title={
          <Space>
            <ExclamationCircleOutlined style={{ color: '#faad14' }} />
            <Typography.Text strong>Delete task</Typography.Text>
          </Space>
        }
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button 
            key="delete" 
            danger 
            type="primary"
            loading={loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        ]}
      >
        <Typography.Text>
          Are you sure you want to delete this task? This action cannot be undone.
        </Typography.Text>
      </Modal>
    </>
  );
}
