import { Select } from "antd";
import React from "react";
import FakeLabel from "./select";

export default function SelectMultiple({
  title,
  placeholder = "",
  value,
  handleChange,
  options = [],
  nolabel = false,
  allowClear = false,
  ...more
}) {
  const elem = (
    <Select
      allowClear={allowClear}
      mode="multiple"
      placeholder="Enter tags separated by comma"
      options={options}
      value={value}
      onChange={handleChange}
      style={{
        width: "100%",
      }}
      tokenSeparators={[","]}
    />
  );
  if (nolabel) return elem;
  return <FakeLabel {...more} title={title} elem={elem}></FakeLabel>;
}
