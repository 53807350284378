import React, { useState } from "react";
import { Button, Typography, Tabs, Modal, Select, message, Divider } from "antd";
import { fetchResults } from "../../../firebase/data/get";
import { catcher } from "../../../firebase/util";
import { allRoutes } from "../../../routes";
import { useSelector } from "react-redux";
import { selectConfigData } from "../../../redux/features/configSlice";
import { getForms } from "../../../static/formRoutes";
import SelectInlineTags from "../../../components/input/selectinlinetags";
import { fetchCompanies, fetchCompaniesByTime, fetchCompaniesByTitles } from "../../../firebase/company/get";
import { fetchSites } from "../../../firebase/site/get";
import { fetchUsers } from "../../../firebase/user/get";
import Loading from "../../../components/loading";

const { confirm } = Modal;
const { Option } = Select;

const OtherSuperAdminSettings = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(undefined);
  const [plan, setPlan] = useState(undefined);

  const plans = useSelector(selectConfigData)?.plans;
  const forms = getForms() || [];

  // Function to handle loading data with confirmation modal
  const handleLoadAllData = async (formKey, planKey) => {
    if (!formKey || !planKey) {
      Modal.warning({ title: "Warning", content: "Please select both a form and a plan before loading data." });
      return;
    }

    confirm({
      title: `⚠️ Load all user data for the selected plan: ${planKey}?`,
      content: "This may take some time. Are you sure you want to proceed?",
      async onOk() {
        await catcher(
          async () => {
            await fetchResults(formKey, 30, planKey);
          },
          { setLoading }
        );
      },
    });
  };

  const LoadCompaniesData = () => {
    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const handleCompaniesChange = (newCompanies) => {
      setCompanies(newCompanies);
      console.log("Updated Companies List:", newCompanies); // You can process this further
    };

    const handleLoadCompanies = async () => {
      catcher(
        async () => {
          if (window.confirm("Load these companies, their sites and users")) {
            await fetchCompaniesByTitles(companies);
            message.info("companies loaded");
            await fetchSites();
            message.info("sites loaded");
            await fetchUsers();
            message.info("users loaded");
          }
        },
        { setLoading: setLoadingCompanies }
      );
    };
    const fetchAllCompanies = async () => {
      catcher(
        async () => {
          if (window.confirm("Load all companies, their sites and users? This is resource intensive Task!!!")) {
            await fetchCompanies(false);
            message.info("companies loaded");
            await fetchSites();
            message.info("sites loaded");
            await fetchUsers();
            message.info("users loaded");
          }
        },
        { setLoading: setLoadingCompanies }
      );
    };
    if (loadingCompanies) return <Loading />;
    return (
      <div>
        <p>Write titles of companies in the input box and click on Add to save them.</p>
        <SelectInlineTags placeholder="Enter company name" onChange={handleCompaniesChange} />
        {companies.length > 0 && (
          <div>
            <hr />
            <Button type="primary" onClick={handleLoadCompanies}>
              Load ({companies.length}) companies
            </Button>
          </div>
        )}
        <Divider >other</Divider>
        <Button onClick={fetchAllCompanies}>Fetch All Companies</Button>
      </div>
    );
  };

  const LoadRecentCompaniesData = () => {
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [hours, setHours] = useState(24);

    const handleLoadRecentCompanies = async () => {
      catcher(
        async () => {
          if (window.confirm(`Load companies from the last ${hours} hours, their sites and users?`)) {
            const recentCompanies = await fetchCompaniesByTime(hours);
            message.info(`${recentCompanies.length} companies loaded`);
            await fetchSites();
            message.info("sites loaded");
            await fetchUsers();
            message.info("users loaded");
          }
        },
        { setLoading: setLoadingCompanies }
      );
    };

    if (loadingCompanies) return <Loading />;
    return (
      <div>
        <p>Load companies that were created within a specific time range</p>
        <div style={{ marginBottom: 16 }}>
          <Select
            style={{ width: 120, marginRight: 16 }}
            value={hours}
            onChange={setHours}
          >
            <Option value={3}>3 hours</Option>
            <Option value={24}>24 hours</Option>
            <Option value={48}>48 hours</Option>
            <Option value={72}>72 hours</Option>
          </Select>
          <Button onClick={handleLoadRecentCompanies}>
            Load Recent Companies
          </Button>
        </div>
      </div>
    );
  };

  // Options tab component
  const OptionsTab = () => {
    return (
      <div>
        <Typography.Title level={5}>Select Form and Plan</Typography.Title>
        <Select style={{ width: "100%", marginBottom: "16px" }} placeholder="Select Form" value={form} onChange={setForm}>
          {forms.map(({ name }) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>

        <Select style={{ width: "100%", marginBottom: "16px" }} placeholder="Select Plan" value={plan} onChange={setPlan}>
          {Object.keys(plans || {}).map((planKey) => (
            <Option key={planKey} value={planKey}>
              {planKey}
            </Option>
          ))}
        </Select>

        <Button loading={loading} type="primary" onClick={() => handleLoadAllData(form, plan)} disabled={!form || !plan}>
          Load Results
        </Button>
      </div>
    );
  };

  // Hidden Links tab component
  const HiddenLinksTab = () => {
    const hiddenLinks = ["download_raw_user_info: Displays information for downloading user data", "consultant_user_verification: Verifies consultant users"];

    return (
      <div>
        <Typography.Title level={5}>Hidden Links</Typography.Title>
        <ul>
          {hiddenLinks.map((link, index) => (
            <li key={index}>{link}</li>
          ))}
        </ul>
      </div>
    );
  };

  // All Links tab component
  const AllLinksTab = () => (
    <div>
      <Typography.Title level={5}>All Links</Typography.Title>
      <ul>
        {allRoutes.map(({ path }) => (
          <li key={path}>
            <a href={path} target="_blank" rel="noopener noreferrer">
              {path}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  // Tab configuration
  const tabs = [
    { key: "0", label: "Load Companies", component: <LoadCompaniesData /> },
    { key: "1", label: "Load Recent Companies", component: <LoadRecentCompaniesData /> },
    { key: "2", label: "Fetch Results", component: <OptionsTab /> },
    { key: "3", label: "Hidden Links", component: <HiddenLinksTab /> },
    { key: "4", label: "All Links", component: <AllLinksTab /> },
  ];

  return (
    <div>
      <Typography.Title level={4}>Load Data and View Links</Typography.Title>
      <Tabs defaultActiveKey="0">
        {tabs.map(({ key, label, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default OtherSuperAdminSettings;
