import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../../../redux/features/userSlice";
import Loading from "../../../../components/loading";
import { updateUserClicks } from "../../../../firebase/user/update";
import { Button, Card, Typography } from "antd";
import { TbTargetArrow } from "react-icons/tb";
import { catcher } from "../../../../firebase/util";
import  TargetsDisplay  from "./targets";
import ErrorBoundary from "../../../../ErrorBoundary";

const aboutText = `Sustrax Target Tracking allows you to track your emissions against your company's reduction targets.`;

// Modularized Header Section
const HeaderSection = ({ aboutText, onStart }) => (
  <div style={{ textAlign: "center", padding: "20px" }}>
    <img 
      src="https://www.carbonfootprint.com/images/greenicontarget.png" 
      alt="Green Target Icon" 
      style={{ 
        height: 84, 
        width: 84, 
        marginBottom: "20px" 
      }} 
    />
    <Typography.Title level={3}>Sustrax Target Tracking</Typography.Title>
    <p>{aboutText}</p>
    <Button type="primary" size="large" onClick={onStart}>
      Turn on Targets
    </Button>
  </div>
);



const Targets = ({ _ }) => {
  let userDoc = useSelector(selectUserDoc);
  const [loading, setLoading] = useState(false);

  if (!userDoc) return <Loading />;

  const showTargets = userDoc["count_targets_" + _.id];
  const handleStart = async () => {
    catcher(
      async () => {
        await updateUserClicks("targets_" + _.id);
      },
      { setLoading }
    );
  };
  if (loading) return <Loading />;
  return <div>{showTargets ? <ErrorBoundary><TargetsDisplay _={_} /></ErrorBoundary> : <HeaderSection aboutText={aboutText} onStart={handleStart} />}</div>;
};

export default Targets;
