import { ArrowLeftOutlined, MailOutlined, LockOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message, Modal, Select, Typography, Tooltip, App, ConfigProvider, theme } from "antd";
import axios from "axios";
import { getAuth, GoogleAuthProvider, signInWithCustomToken, signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries } from "../assets/countries";
import { logout } from "../components/Siderbar";
import { auth } from "../firebase/setup";
import { signInNewUser } from "../firebase/user/create";
import { catcher } from "../firebase/util";
import { getAllParamsFromLocation } from "../helper/loc";
import { selectUserDoc, setUserDoc } from "../redux/features/userSlice";
import { adminApi } from "../static/adminApi";
import "./style.css";
import { selectAffiliate, setTheme } from "../redux/features/appSlice";
import { LoginContent, Logo } from "../styles/core-components";
import store from "../redux/store";
import { MicrosoftSignInButton, signInWithMicrosoft, SSO } from "./SSO";
import { routePath } from "../static/routes.static";
import isMobile from "is-mobile";
const gprovider = new GoogleAuthProvider();

export async function handleSignInWithCustomToken(customToken, href = "/dashboard") {
  // Show full page loading
  const loadingMessage = document.createElement("div");
  loadingMessage.className = "full-page-loading";
  loadingMessage.innerHTML = `
    <div class="loading-content">
      <div class="ant-spin ant-spin-lg ant-spin-spinning">
        <span class="ant-spin-dot ant-spin-dot-spin">
          <i class="ant-spin-dot-item"></i>
          <i class="ant-spin-dot-item"></i>
          <i class="ant-spin-dot-item"></i>
          <i class="ant-spin-dot-item"></i>
        </span>
      </div>
      <div class="loading-text">Signing in...</div>
    </div>
  `;
  document.body.appendChild(loadingMessage);

  try {
    const userCredential = await signInWithCustomToken(auth, customToken);
    var user = userCredential.user;
    window.location.href = href;
  } catch (error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;

    // Remove loading screen
    document.body.removeChild(loadingMessage);

    // Show error message using Ant Design message
    const { message } = require("antd");
    message.error(`Authentication failed: ${errorMessage}`);

    // Throw the error for further handling if needed
    throw error;
  }
}

const Login = ({ type = "simple" }) => {
  const [mode, setMode] = useState(type);
  const navigate = useNavigate();
  const [email, setName] = useState("");
  const [password, setPassword] = useState("");
  const userDoc = useSelector(selectUserDoc);
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const af = useSelector(selectAffiliate);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const params = getAllParamsFromLocation();
    const notFullyRegistered = params["not-fully-registered"] === "true";

    // Check for current user
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log(user, "user");
      if (notFullyRegistered) {
        if (user) {
          // User is logged in and needs to complete registration
          setCurrentUser(user);
          setMode("complete-registration");
        } else {
          // No user is logged in, redirect to regular login
          const newUrl = window.location.pathname; // removes all params
          window.history.replaceState(null, "", newUrl);
          setMode("simple");
        }
      } else if (userDoc && type === "simple") {
        navigate("/dashboard");
      }
    });

    if (params["type"] === "register") setMode("register");

    return () => unsubscribe();
  }, []);

  const onFinish = async () => {
    const auth = getAuth();
    dispatch(setUserDoc(undefined));
    try {
      setLoading(true);
      const doc = await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
      console.time("Loggin Time");
    } catch (err) {
      dispatch(setUserDoc(null));
      message.error("Error Logging In");
    } finally {
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    toast("Error Logging In", { type: "error" });
  };
  const completeSSORegisteration = async ({ firstName, lastName, phone, companyName, country }) => {
    catcher(
      async () => {
        const af = store.getState().app.affiliate;
        await signInNewUser({ firstName, lastName, email: currentUser?.email, phone, password: "NO_ACTUAL_NEED_123" }, companyName, country, af?.id, true);
        window.location.href = routePath.Dashboard;
      },
      { setLoading }
    );
  };
  const onFinishSignUp = ({ firstName, lastName, phone, email, password, companyName, country }) => {
    catcher(
      async () => {
        const af = store.getState().app.affiliate;
        const data = { firstName, lastName, email: email.toLowerCase(), password, phone };
        await signInNewUser(data, companyName, country, af?.id);
        setName(email.toLowerCase());
        setPassword(password);
        setMode("simple");
      },
      { setLoading }
    );
  };
  //AUTH CODE FINISH/ FAIL
  const onFinishAuthCode = () => {
    catcher(
      async () => {
        const resp = await axios.post(adminApi.twofa, {
          code: authCode,
          userId: userDoc.id,
        });

        dispatch(setUserDoc({ ...userDoc, authCode: false }));
      },
      { setLoading }
    );
  };
  const onFinishFailedAuthCode = () => {};
  const handleForgotPassword = () => {
    Modal.confirm({
      title: "Reset Password",
      content: (
        <div>
          <p>Please enter your email.</p>
          <Input id="password-reset" placeholder="someone@example.com" />
        </div>
      ),
      onOk: async () => {
        try {
          // clearing cookies as well
          const email = document.getElementById("password-reset").value;
          await axios.post(adminApi.sendPasswordResetMail, {
            email,
          });
          message.info("A password reset mail has been sent to " + email);
        } catch (err) {
          message.error("Unable to send password to this email.");
        }
      },
      okText: "Send Reset Link",
    });
  };
  const handleBack = () => {
    logout();
    setMode("simple");
  };

  const loginForm = (
    <div className="login-form-wrapper">
      {isMobile() && <Logo className={"login-logo"}/>}
      <Typography.Title level={2} className="login-title">
        Welcome back!
      </Typography.Title>
      <Typography.Text className="login-subtitle">Enter your credentials to access your account</Typography.Text>
      <Form name="login-form" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical" size="large" className="auth-form">
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
          <Input placeholder="Enter your email" value={email} onInput={(e) => setName(e.target.value)} className="auth-input rectangular" />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
          <Input.Password placeholder="Enter your password" value={password} onInput={(e) => setPassword(e.target.value)} className="auth-input rectangular" />
        </Form.Item>

        <div className="auth-form-footer">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Typography.Link onClick={handleForgotPassword}>Forgot Password?</Typography.Link>
        </div>

        <Form.Item label="">
          <Button type="primary" htmlType="submit" className="auth-button rectangular" loading={userDoc === undefined}>
            Sign In
          </Button>
        </Form.Item>
      </Form>

      <div className="auth-divider">
        <span>or continue with</span>
      </div>

      <SSO />

      <Typography.Text className="signup-link">
        Don't have an account?{" "}
        <Typography.Link underline strong onClick={() => setMode("register")}>
          Sign up now
        </Typography.Link>
      </Typography.Text>
    </div>
  );
  const authForm = (
    <Form name="login-form" style={{ padding: "20px" }} onFinish={onFinishAuthCode} onFinishFailed={onFinishFailedAuthCode}>
      <Button className="small-back-link" style={{ marginBottom: 20 }} type="text" size="small" icon={<ArrowLeftOutlined />} onClick={handleBack} loading={loading}>
        Back
      </Button>
      <Form.Item name="authCode" rules={[{ required: true, message: "Please input your Authentication Code!" }]}>
        <Input value={authCode} onChange={(e) => setAuthCode(e.target.value)} type="number" placeholder="Authentication Code" />
      </Form.Item>

      <Form.Item style={{ display: "flex", justifyContent: "right" }}>
        <div
          style={{
            color: "#0A8FDC",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={() => {
            Modal.info({
              title: "Cannot Login?",
              content: (
                <>
                  Please click the back button and relogin. A code will be sent to you again. If you still cannot access it please mail to{" "}
                  <a href="mailto:sustrax@carbonfootprint.com?subject=&body=">
                    <b>sustrax@carbonfootprint.com</b>
                  </a>
                </>
              ),
            });
          }}
        >
          Didn't get an email?
        </div>
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
          CONFIRM
        </Button>
      </Form.Item>
    </Form>
  );
  const signUpForm = (
    <div className="login-form-wrapper">
      <Typography.Title level={2} className="login-title">
        Create your account
      </Typography.Title>
      <Typography.Text className="login-subtitle">Join Sustrax to start managing your organization's environmental impact</Typography.Text>
      <Form name="registration-form" initialValues={{ remember: true }} onFinish={onFinishSignUp} layout="vertical" size="large" className="auth-form">
        <div className="form-section">
          <Form.Item label="Company Name" name="companyName" rules={[{ required: true, message: "Please input your Company Name!" }]}>
            <Input placeholder="Enter company name" className="auth-input" />
          </Form.Item>

          <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please select your Country!" }]}>
            <Select showSearch placeholder="Select your country" className="auth-input">
              {getCountries().map((country) => (
                <Select.Option key={country} value={country}>
                  {country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="name-fields">
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please input your First Name!" }]}>
              <Input placeholder="Enter first name" className="auth-input" />
            </Form.Item>

            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please input your Last Name!" }]}>
              <Input placeholder="Enter last name" className="auth-input" />
            </Form.Item>
          </div>

          <Form.Item label="Phone Number" name="phone">
            <Input placeholder="Enter phone number" className="auth-input" />
          </Form.Item>

          <Form.Item
            label="Business Email"
            name="email"
            rules={[
              { required: true, message: "Please input your Email!" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input placeholder="Enter business email" className="auth-input" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { min: 8, message: "Password must be at least 8 characters long" },
            ]}
          >
            <Input.Password placeholder="Create password" className="auth-input" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="auth-button rectangular" loading={loading}>
            Create Account
          </Button>
        </Form.Item>

        <div className="auth-divider">
          <span>or sign up with</span>
        </div>

        <SSO />

        <Typography.Text>
          Already have an account?{" "}
          <Typography.Link underline strong onClick={() => setMode("simple")}>
            Sign in instead
          </Typography.Link>
        </Typography.Text>
      </Form>
    </div>
  );

  console.log({
    firstName: currentUser?.displayName?.split(" ")[0] || "",
    lastName: currentUser?.displayName?.split(" ")[1] || "",
    email: currentUser?.email || "",
  });
  const completeRegistrationForm = (
    <div className="login-form-wrapper">
      <Typography.Title level={2} className="login-title">
        One last step!
      </Typography.Title>
      <Typography.Text className="login-subtitle">Tell us about your company to personalize your experience</Typography.Text>
      <Form
        name="registration-form"
        initialValues={{
          firstName: currentUser?.displayName?.split(" ")[0] || "",
          lastName: currentUser?.displayName?.split(" ")[1] || "",
          email: currentUser?.email || "",
        }}
        fields={[
          {
            name: ["firstName"],
            value: currentUser?.displayName?.split(" ")[0] || "",
          },
          {
            name: ["lastName"],
            value: currentUser?.displayName?.split(" ")[1] || "",
          },
          {
            name: ["email"],
            value: currentUser?.email || "",
          },
        ]}
        onFinish={completeSSORegisteration}
        layout="vertical"
        size="large"
        className="auth-form"
      >
        <Typography.Title level={4} style={{ marginBottom: "20px" }}>
          Personal Information
        </Typography.Title>

        <div className="name-fields">
          <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please input your First Name!" }]}>
            <Input disabled className="auth-input" />
          </Form.Item>

          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please input your Last Name!" }]}>
            <Input disabled className="auth-input" />
          </Form.Item>
        </div>

        <Form.Item label="Email" name="email">
          <Input disabled className="auth-input" />
        </Form.Item>

        <div className="auth-divider" style={{ margin: "32px 0 24px" }}>
          <span>Company Details</span>
        </div>

        <Form.Item label="Company Name" name="companyName" rules={[{ required: true, message: "Please input your Company Name!" }]}>
          <Input placeholder="Enter company name" className="auth-input" />
        </Form.Item>

        <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please select your Country!" }]}>
          <Select showSearch placeholder="Select your country" className="auth-input">
            {getCountries().map((country) => (
              <Select.Option key={country} value={country}>
                {country}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Phone Number" name="phone">
          <Input placeholder="Enter phone number" className="auth-input" />
        </Form.Item>

        <Form.Item label="" style={{ marginTop: "24px" }}>
          <Button type="primary" htmlType="submit" className="auth-button rectangular" loading={loading}>
            Complete Registration
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
      <div className="login-container auth">
        <div className="login-form">
          <div className="login-form-inner">{mode === "simple" ? loginForm : mode === "register" ? signUpForm : mode === "complete-registration" ? completeRegistrationForm : authForm}</div>
        </div>
        <div className="login-content" style={{ position: "relative" }}>
          <img src="https://demo.tailadmin.com/src/images/shape/grid-01.svg" style={{ position: "absolute", scale: "1.4", top: 0, right: 0, transform: "rotate(0deg)" }}></img>
          <img src="https://demo.tailadmin.com/src/images/shape/grid-01.svg" style={{ position: "absolute", scale: "1.4", bottom: 0, left: 0, transform: "rotate(180deg)" }}></img>
          <div className="login-content-inner">
            <div className="logo-container">
              <Logo className="login-logo" />
              <span className="logo-text">Sustrax</span>
            </div>
            <p>Sustrax enables you to measure, manage and report your organisation's environmental data and carbon emissions from anywhere in the world.</p>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};
export default Login;
