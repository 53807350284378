// Options Component for Analysis tabs

import { useState } from "react";

import { CaretDownFilled, CaretUpFilled, LineOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Select, Tooltip } from "antd";
import DateInput from "../../../../components/input/date";
import Select1 from "../../../../components/select/select1";
import SiteMultipleChecker from "../../../../components/select/sitemultiplechecker";
import Boxed from "./boxed";
import { T1 } from "../../../../appconfig/texts";
import { isSustraxLite, planValue } from "../../../../helper/plans";
import { firstCompany } from "../../../../helper/attribute";
import FakeLabel from "../../../../components/input/select";
import FormContext from "../../../../contexts/FormContext";
import useSize from "../../../../hooks/useSize";
import { useEffect } from "react";
import store from "../../../../redux/store";
import { extractYears } from "../../../results/resultsCalcs";
import SelectMultipleCheckboxModal from "../../../../components/input/SelectMultipleCheckboxModal";

const labels = {
  reportType: "This will display the results in either the emissions tabs entered or in the GHG Protocol Categories (For more info please see the FAQ).",
};

export const monthsArray = [
  { label: "All Year", value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
  { label: "Half Year 1", value: [1, 2, 3, 4, 5, 6] },
  { label: "Half Year 2", value: [7, 8, 9, 10, 11, 12] },
  { label: "Quarter 1", value: [1, 2, 3] },
  { label: "Quarter 2", value: [4, 5, 6] },
  { label: "Quarter 3", value: [7, 8, 9] },
  { label: "Quarter 4", value: [10, 11, 12] },
];

export default function Options({ options, setOptions, more_options = {} }) {
  const [years, setYears] = useState([2021, 2022, 2023, 2024, 2025]);
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [filters, setFilters] = useState({
    ...options,
  });
  const [more, setMore] = useState(false);
  const filterDirty = JSON.stringify(options) !== JSON.stringify(filters);

  useEffect(() => {
    const not_include_me = ["sites"];
    const user_saved_options = localStorage.getItem("user-saved-options:" + window.location.href);
    if (user_saved_options) {
      const options_saved = JSON.parse(user_saved_options);
      not_include_me.forEach((key) => {
        options_saved[key] = options[key];
      });
      setOptions(options_saved);
      setFilters(options_saved);
    }
  }, []);

  const saveSettingsAsDefault = () => {
    localStorage.setItem("user-saved-options:" + window.location.href, JSON.stringify(filters));
    message.success("Settings saved as default");
  };

  useEffect(() => {
    try {
      if (!window.location.href.includes("name=Years")) {
        const years = extractYears(store.getState().app.results);
        if (years?.length) {
          setYears(years);
          setFilters({ ...options, year: years[years.length - 1] });
          setOptions({ ...options, year: years[years.length - 1] });
        }
      }
    } catch (err) {}
  }, []);

  const moreSettingsData = (filters.monthRange || filters.showMonthNames) && (
    <>
      {more && filters.monthRange && (
        <Select1
          title={<T1 pos={"analysis.options.timeline"} />}
          minWidthOption="100px"
          minWidth={"100px"}
          value={filters.monthRange}
          options={monthsArray.map((r) => r.label)}
          setValue={(monthRange) => {
            setFilters({ ...filters, monthRange });
          }}
        ></Select1>
      )}
      {more && filters.showMonthNames && (
        <Select1
          title={<T1 pos={"analysis.options.monthNames"} />}
          minWidthOption="100px"
          minWidth={"100px"}
          value={filters.showMonthNames}
          options={["Yes", "No"]}
          setValue={(showMonthNames) => {
            setFilters({ ...filters, showMonthNames });
          }}
        ></Select1>
      )}
    </>
  );
  console.log("filters", filters);
  return (
    <FormContext.Provider
      value={{
        bgless: true,
        borderless: true,
      }}
    >
      <Boxed title={<T1 pos={"analysis.options.title"} />} initialDownState={true}>
        <div className={`${filterDirty && "dirty"}`}>
          <div style={{ padding: 10 }}>
            {filters.sites && !isSustraxLite() && (
              <SiteMultipleChecker
                title={<T1 pos={"analysis.options.sites"} />}
                minWidthLabel="90px"
                maxWidthElem="1300px"
                info="Green means you are seeing your full company emissions, if the above is amber you are only seeing emissions from the sites shown."
                sites={filters.sites}
                setSites={(sites) => setFilters({ ...filters, sites })}
              />
            )}

            {filters.year && (
              <div style={{ display: "flex", gap: 10 }}>
                <Select1
                  title={<T1 pos={"analysis.options.year"} />}
                  options={[...years, "custom"]}
                  value={filters.year}
                  setValue={(year) =>
                    setFilters({
                      ...filters,
                      year,
                      // startdate: moment([filters.year, 0, 1]).format(timeFormat),
                      // enddate: moment([filters.year, 11, 31]).format(timeFormat),
                    })
                  }
                  minWidthOption="100px"
                  minWidth={isExtraSmall ? "74px" : "100px"}
                ></Select1>
                {filters.year === "custom" && (
                  <>
                    <DateInput
                      title={<T1 pos={"analysis.options.startDate"} />}
                      maxWidthElem={"200px"}
                      width={"300px"}
                      setValue={(startdate) => {
                        setFilters({ ...filters, startdate });
                      }}
                      value={filters.startdate}
                    />
                    <LineOutlined />
                    <DateInput
                      title={<T1 pos={"analysis.options.endDate"} />}
                      maxWidthElem={"200px"}
                      width={"300px"}
                      setValue={(enddate) => {
                        setFilters({ ...filters, enddate });
                      }}
                      value={filters.enddate}
                    />
                  </>
                )}
              </div>
            )}
            {filters.resulttags ? (
              <div style={{ maxWidth: "300px" }}>
                <SelectMultipleCheckboxModal
                  title="Tags"
                  padding="0px"
                  placeholder="Please select tags"
                  options={firstCompany()?.resulttags?.map((r) => ({
                    label: r,
                    value: r,
                  }))}
                  value={filters.resulttags}
                  handleChange={(resulttags) => setFilters({ ...filters, resulttags })}
                  allowClear={true}
                />
              </div>
            ) : null}
            {planValue("analysisReporting") ? (
              <Select1
                title={<T1 pos={"analysis.options.reporting"} />}
                options={["All", "SECR", "PPN 06/21"]}
                value={filters.reporting}
                setValue={(reporting) =>
                  setFilters({
                    ...filters,
                    reporting,
                    wtt: reporting === "SECR" ? "Excluded" : filters.wtt,
                  })
                }
                minWidthOption="100px"
                minWidth={isExtraSmall ? "74px" : "100px"}
              ></Select1>
            ) : null}
            {/* right here */}
            <Select1
              title={<T1 pos={"analysis.options.wtt"} />}
              options={["Included", "Excluded"]}
              disabled={filters.reporting === "SECR" ? ["Included"] : []}
              value={filters.wtt}
              setValue={(wtt) => setFilters({ ...filters, wtt })}
              minWidthOption={isExtraSmall ? "30px" : "100px"}
              minWidth={isExtraSmall ? "75px" : "100px"}
            ></Select1>

            <Select1
              title={<T1 pos={"analysis.options.type"} />}
              options={["Location", "Market"]}
              value={filters.type}
              setValue={(type) => setFilters({ ...filters, type })}
              minWidthOption={isExtraSmall ? "30px" : "100px"}
              minWidth={isExtraSmall ? "75px" : "100px"}
            ></Select1>
            {filters.reportType && (
              <Select1
                title={<T1 pos={"analysis.options.reportType"} />}
                options={["Emissions", "GHG"]}
                value={filters.reportType}
                setValue={(reportType) => setFilters({ ...filters, reportType })}
                minWidthOption={isExtraSmall ? "30px" : "100px"}
                minWidth={isExtraSmall ? "75px" : "100px"}
                description={labels["reportType"]}
              ></Select1>
            )}
            {moreSettingsData && (
              <div style={{ cursor: "pointer", userSelect: "none" }} onClick={() => setMore(!more)}>
                {<T1 pos={"analysis.options.moreSettings"} />} {more ? <CaretUpFilled /> : <CaretDownFilled />}
              </div>
            )}
            {moreSettingsData}
          </div>
          {!filterDirty && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Popconfirm title="Are you sure you want to save the current settings as default?" onConfirm={() => saveSettingsAsDefault()} okText="Yes" cancelText="No">
                <Button type="link" style={{}}>
                  Save options
                </Button>
              </Popconfirm>
            </div>
          )}
          {filterDirty && (
            <div
              style={{
                borderTop: "1px solid darkgrey",
                padding: "1px",
                position: "sticky",
                top: "20px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ border: "1px solid white", background: "lightgrey" }} className="button-primary-1" onClick={() => setFilters({ ...options })}>
                  {<T1 pos={"analysis.options.cancel"} />}
                </Button>
                <Button
                  style={{
                    color: "white",
                    background: "darkgreen",
                    border: "1px solid white",
                  }}
                  className="button-primary-1"
                  onClick={() => {
                    setOptions({ ...filters });
                  }}
                >
                  {<T1 pos={"analysis.options.apply"} />}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Boxed>
    </FormContext.Provider>
  );
}
