import { FileTwoTone } from "@ant-design/icons";
import { Button, Typography, Upload } from "antd";
import React, { useState } from "react";
import { fixDecimals } from "../../helper/wodash";
import "./datafile.css";

function FileInput({ file, setFile, fileSize = 1, accept = "*", minHeight, extraWord = "file" }) {
  const [error, setError] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const beforeUpload = (file) => {
    if (fileSize !== -1 && file.size > fileSize * 1024 * 1024) {
      setError(`File size must be less than ${fileSize}MB`);
      return false;
    }
    setError("");
    setFile(file);
    return false;
  };

  const handleRemove = () => {
    setFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (beforeUpload(droppedFile)) {
      setFile(droppedFile);
    }
  };

  const props = {
    beforeUpload,
    showUploadList: false,
    accept,
  };

  const createURL = () => {
    try {
      return URL.createObjectURL(file);
    } catch (err) {
      return "#";
    }
  };

  return (
    <div
      className={`file-input ${isDragging ? "dragging" : ""}`}
      style={{ minHeight }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {file ? (
        <div className="file-preview">
          <Typography.Text>
            <p>
              <FileTwoTone twoToneColor="darkgreen" style={{ fontSize: 60 }} />
              <br />
              <div style={{ fontSize: "15px", margin: "10px" }}>
                <a href={createURL()} download={file.name}>
                  {file.name || "File Name Error"}
                </a>
                {file.size && <span> ({fixDecimals(file.size / 1000)} Kb)</span>}
              </div>
            </p>
            <Button danger type="default" onClick={handleRemove}>
              Remove File
            </Button>
          </Typography.Text>
        </div>
      ) : (
        <div className="file-dragndrop">
          <Upload {...props}>
            <FileTwoTone twoToneColor={isDragging ? "blue" : "forestgreen"} style={{ fontSize: 60 }} />
            <br />
            <span>{isDragging ? "Drop your file here!" : `Drag & drop ${extraWord} or browse`}</span>
            {error && <p className="error">{error}</p>}
            {accept !== "*" && <div style={{ color: "darkgrey", fontSize: "smaller" }}>Supported: {accept}</div>}
          </Upload>
        </div>
      )}
    </div>
  );
}

export default FileInput;
