
export const firestoreStatic = {
  groups: "Group",
  companies: "Company",
  affiliates: "Affiliate",
  sites: "Site",
  users: "User",
  results: "Result",
  config: "appconfig",
  resultshistory:"ResultHistory",
  tasks:"tasks",
  info:"MoreInfo",
  notification:"Notification"
};
