import { catcher } from "../../firebase/util";
import { findDataTags, findFormTexts, firstCompany, openSites } from "../../helper/attribute";
import Wexel from "../../helper/excel";
import { getCurrentPlan } from "../../helper/plans";
import { getNextTwelveMonths } from "../../helper/time";
import { capitalizeFirstLetter, removeDuplicates } from "../../helper/wodash";
import { decimalKeys } from "../form/reviewpage";
import { downloadBulkTemplate } from "../super-admin/SuperAdminSectionPages/BulkUploadTemplates";

export const downloadBulkUploadTemplate = async (form, configData, filename, settings = { type: "Yearly" }) => {
  catcher(async () => {
    /**
     * If settings.type==="Monthly"
     * Take monthly data input based on reporting year starting month
     */
    let headers = [];
    const excel = new Wexel();
    const file_url = await downloadBulkTemplate(form, `${form.title}-Template ${firstCompany()?.title}.xlsx`, filename);
    //IF FILE URL IS PRESENT: load the file and make changes. download on user end
    if (file_url) {
      console.log("Loading template from server");
      headers = await excel.loadDataFromUrl(file_url);
      console.log("Headers Extracted:", headers);
    } else {
      //ELSE: generate the file from the start
      const texts = findFormTexts(form.name, "forms");
      let formInputs = [];
      try {
        const module = await import("../form/dataforms/" + form.name);
        formInputs = module.INPUTS({ ...form, ...configData }, () => {}) || [];
      } catch (err) {
        try {
          const module = await import("../form/dataforms/" + capitalizeFirstLetter(form.name));
          formInputs = module.INPUTS({ ...form, ...configData }, () => {}) || [];
        } catch (err) {
          console.error(err);
        }
      }

      excel.addSheet("Inputdata");
      //FILTERS ON INPUTS
      formInputs = formInputs.filter((input) => input.excelShow !== false);
      headers = formInputs.map((obj, i) => texts.find((text) => text.name === obj.key)?.title || obj.key);
      console.log(settings);

      // Adding formulae
      formInputs.forEach((inp, index) => {
        const i = index + 1;
        let excel_options = inp.excel_options || inp.options;

        if (Array.isArray(excel_options)) {
          excel_options = excel_options.map((option) => {
            if (typeof option === "object") {
              return option.key;
            }
            return option;
          });
        }

        if (Array.isArray(excel_options)) {
          const formula = excel.addDropdownRow(excel_options);
          excel.applyColFormula("Inputdata", i, {
            type: "list",
            formulae: [formula],
            showErrorMessage: false,
          });
        } else if (decimalKeys.includes(inp.key) || inp?.elem?.name === "NumberInput")
          excel.applyColFormula("Inputdata", i, {
            type: "decimal",
            operator: "between",
            formulae: ["0", inp.max || "1000000"],
            error: "Must be a positive number less than " + (inp.max || "1000000"),
          });
        else if (inp.key === "date") {
          const obj = {
            type: "date",
            operator: "greaterThan",
            formulae: ["01/01/2019"],
            error: "Date must be greater than 01/01/2019",
          };
          if (form?.name === "additional") {
            // no time limit
            obj.formulae = ["01/01/1950"];
          }
          excel.applyColFormula("Inputdata", i, obj);
        }
        return null;
      });
    }

    {
      /**COMMON CHANGES */
    }

    if (settings.type === "Monthly") {
      const startingMonth = firstCompany()?.reporting_year_starting_month ?? "January";
      headers.push("Year");
      getNextTwelveMonths(startingMonth, 2023).forEach((obj) => {
        headers.push(obj.month);
        console.log(obj);
      });
    }

    if (configData) {
      excel.addConfig(configData, "A1");
    }
    const companyMetrics = firstCompany()?.inputMetrics?.filter((metric) => metric.enabled) ?? [];

    headers = ["Site", ...headers, "Comment", "Data Link", "Data Tag", "Cost", ...companyMetrics.map((metric) => "Metric: " + metric?.title)];

    console.log("Adding headers", headers, " of which metrics", companyMetrics);

    if (headers[1] === "Site") headers = removeDuplicates(headers);
    excel.addHeader({
      headers,
      sheet_ref: "Inputdata",
    });

    // Setting formulae for site
    console.log("ADDING SITE OPTION");
    const site_options = openSites().map((site) => site.title);
    const formula_site = excel.addDropdownRow(site_options);

    console.log("formula_site", formula_site);
    excel.applyColFormula("Inputdata", 0, {
      type: "list",
      formulae: [formula_site],
      showErrorMessage: false,
    });
    const datatag_options = findDataTags();
    console.log(datatag_options, "datatag_options");
    if (settings.type == "Monthly") {
      // excel.removeColumn(1,"Inputdata");
      const formula = excel.addDropdownRow([2019, 2020, 2021, 2022, 2023, 2024, 2025]);
      excel.applyColFormula(
        "Inputdata",
        headers.findIndex((header) => header === "Year"),
        {
          type: "list",
          formulae: [formula],
          error: "Must select a valid year after 2019",
        }
      );
    }
    if (datatag_options?.length) {
      const formula_datatag = excel.addDropdownRow(datatag_options);

      excel.applyColFormula(
        "Inputdata",
        headers.findIndex((header) => header === "Data Tag"),
        {
          type: "list",
          formulae: [formula_datatag],
          showErrorMessage: false,
        }
      );
    }

    console.log("Adding cost formula");
    excel.applyColFormula(
      "Inputdata",
      headers.findIndex((header) => header === "Cost"),
      {
        type: "decimal",
        operator: "between",
        formulae: ["0", "1000000"],
        error: "Must be a positive number less than 1000000",
      }
    );
    console.log("Adding metrics formula");
    companyMetrics.forEach((metric) => {
      if (metric.type !== "num") return;
      const formulaObj = {
        type: "decimal",
        error: "Must be a valid number.",
        // operator: "between",
        // formulae: ["0", "1000000"],
        // error: "Must be a positive number less than 1000000",
      };
      excel.applyColFormula(
        "Inputdata",
        headers.findIndex((header) => header === "Metric: " + metric?.title),
        formulaObj
      );
    });

    console.log("Removing column 2");
    if (getCurrentPlan() === "ma"|| getCurrentPlan()==="DC") {
      excel.removeColumn(2, "Inputdata");
    }

    console.log("Hiding all sheets");

    excel.hideAllSheets(["Inputdata"]);

    console.log("Downloading file");
    excel.download(`${form.title} Template ${firstCompany()?.title}`);
    // generateExcel(headers, formulas, form.name + "-bulkupload-template.xlsx");
  }, {});
};
